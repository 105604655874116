import { createSlice } from "@reduxjs/toolkit";

const companyData = createSlice({
  name: "companyData",
  initialState: [
    {
      id: 1,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
      details: [
        {
          email: "abc@adbert.com.tw",
          tel: "02-12345678",
          phone: "0912345678",
          jobTitle: "執行長",
          employeeNum: 50,
          industry: "數位媒體業",
          planAndDate: "中小型企業方案",
          additionalMem: 15,
          payment: "信用卡自動扣款",
        },
      ],
    },
    {
      id: 2,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
      details: [
        {
          email: "abc@adbert.com.tw",
          tel: "02-12345678",
          phone: "0912345678",
          jobTitle: "執行長",
          employeeNum: 50,
          industry: "數位媒體業",
          planAndDate: "中小型企業方案",
          additionalMem: 15,
          payment: "信用卡自動扣款",
        },
      ],
    },
    {
      id: 3,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
      details: [
        {
          email: "abc@adbert.com.tw",
          tel: "02-12345678",
          phone: "0912345678",
          jobTitle: "執行長",
          employeeNum: 50,
          industry: "數位媒體業",
          planAndDate: "中小型企業方案",
          additionalMem: 15,
          payment: "信用卡自動扣款",
        },
      ],
    },
    {
      id: 4,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
      details: [
        {
          email: "abc@adbert.com.tw",
          tel: "02-12345678",
          phone: "0912345678",
          jobTitle: "執行長",
          employeeNum: 50,
          industry: "數位媒體業",
          planAndDate: "中小型企業方案",
          additionalMem: 15,
          payment: "信用卡自動扣款",
        },
      ],
    },
    {
      id: 5,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
      details: [
        {
          email: "abc@adbert.com.tw",
          tel: "02-12345678",
          phone: "0912345678",
          jobTitle: "執行長",
          employeeNum: 50,
          industry: "數位媒體業",
          planAndDate: "中小型企業方案",
          additionalMem: 15,
          payment: "信用卡自動扣款",
        },
      ],
    },
    {
      id: 6,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
    {
      id: 7,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
    {
      id: 8,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
    {
      id: 9,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
    {
      id: 10,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
    {
      id: 11,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
    {
      id: 12,
      name: "艾普特媒體",
      tax: "1111111",
      createdDate: "2022/05/01",
      industry: "半導體業",
      employeeNum: 123,
    },
  ],
  reducers: {},
});

export default companyData.reducer;
export const selectCompanyData = (state) => state.companyData;
// export const { updateUser, logoutAndClearUser } = userSlice.actions;
