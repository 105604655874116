import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = (props) => {
  const {
    responsive,
    borderColor,
    data,
    background,
    borderWidth,
    legendDisplay,
    tooltipEnabled,
  } = props;

  const getValue = (item) => {
    let arr = [];
    data.forEach((target) => arr.push(target[item]));
    return arr;
  };

  //上面資料的數量
  let keys = [];
  Object.keys(data[0]).forEach((item) => {
    if (item === "dimension") return;
    keys.push(item);
  });

  //label的數量
  let labels = [];
  data.forEach((item) => {
    labels.push(item.dimension);
  });

  let datasets = [];
  keys.forEach((item) => {
    datasets.push({
      data: getValue(item),
      backgroundColor: background
        ? background
        : ["#eee", "rgba(110, 106, 106, 1)"],
      borderColor: borderColor ? borderColor : "#fff",
      borderWidth: borderWidth ? borderWidth : 0,
    });
  });

  let material = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: responsive !== null ? responsive : false,
    plugins: {
      legend: {
        display: legendDisplay,
        position: "top",
        align: "end",
        labels: {
          font: {
            size: 12,
            family: null,
            weight: null,
            lineHeight: null,
          },
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: tooltipEnabled,
        displayColors: false,
        bodyFont: { size: 12 },
        backgroundColor: "#000",
        yAlign: "bottom",
        padding: 8,

        titleFont: { size: 12 },
        titleColor: "white",

        footFont: { size: 14 },
        footerColor: "black",
        footerFont: { weight: "400" },
        footerSpacing: 10,
        caretSize: 0,
      },
    },
  };

  return <Doughnut data={material} options={options} />;
};

export default DonutChart;
