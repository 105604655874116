import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetClientRequest, sendPostClientRequest } from '../../api/helper';

export const getAuth = createAsyncThunk('permissions/getAuth', async () => {
  const response = await sendGetClientRequest(`/api/v2/center/user-profile`);
  return response.data;
});

export const getManagerPermissions = createAsyncThunk('permissions/getManagerPermissions', async (user_id) => {
  const response = await sendGetClientRequest(`/api/center_workspace/manager_permission/${user_id}`);
  return response.data;
});

export const getManagerPermissionsV2 = createAsyncThunk('permissions/getManagerPermissionsV2', async (workspace_user) => {
  const response = await sendGetClientRequest(`/api/v2/center/workspace-users/${workspace_user}/manage-permissions`);
  return response.data;
});

// post workspace user manage-permissions
export const postUserManagementSettingV2 = createAsyncThunk('user/postUserManagementSettingV2', async (parameters) => {
  const response = await sendPostClientRequest(`/api/v2/center/workspace-users/${parameters[0]?.workspace_user}/manage-permissions`, parameters);
  return response;
});

const permissionSettings = createSlice({
  name: 'permissionSettings',
  initialState: {
    auth: null,
    manager_permissions: null,
    permissions: null,
    loading: false,
    authLoading: false,
  },
  reducers: {
    removeSuccess: (state) => {
      state.success = false;
      state.isDelete = false;
      state.isPermissionSuccess = false;
    },
    getPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    // GET AUTH V2
    [getAuth.pending]: (state) => {
      state.loading = true;
      state.authLoading = true;
    },
    [getAuth.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.auth = payload;
        state.authLoading = false;
        state.loading = false;
      }
    },
    [getAuth.rejected]: (state) => {
      state.authLoading = false;
      state.loading = false;
    },

    // GET MANAGER PERMISSIONS V2 (取得 WorkspaceUser 人員管理權限)
    [getManagerPermissionsV2.pending]: (state) => {
      state.loading = true;
    },
    [getManagerPermissionsV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.manager_permissions = payload;
        state.loading = false;
      }
    },
    [getManagerPermissionsV2.rejected]: (state) => {
      state.loading = false;
    },

    [postUserManagementSettingV2.pending]: (state) => {
      state.loading = true;
    },
    [postUserManagementSettingV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.success = true;
      } else {
        state.error = true;
      }
    },
    [postUserManagementSettingV2.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default permissionSettings.reducer;
export const selectPermissionSettings = (state) => state.permissionSettings;
export const { removeSuccess, getPermissions } = permissionSettings.actions;
