import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './style/lifeCycle.module.scss';
import InnerLayout from '../Layout/InnerLayout';
import TestModal from './TestModal';

import Button from '../Utilities/Button';
import Dropdown from '../Utilities/DropdownDynamic';
import Modal from '../Utilities/Modal';
import LoadingTable from '../Utilities/LoadingTable';
import Toast from '../Utilities/Toast';
import MenuTabs from '../Utilities/MenuTabs';
import Alert from '../Utilities/Alert';

import { AiFillCheckCircle, AiOutlineFileSearch } from 'react-icons/ai';
import { MdErrorOutline, MdOutlineArticle, MdHelpOutline, MdOutlineListAlt } from 'react-icons/md';
import { RiFileEditLine } from 'react-icons/ri';
import ScoreSettingModal from './ScoreSettingModal';

import {
  getEnterpriseStrategy,
  selectLifeCycle,
  putEnterpriseStrategy,
  getStrategyScore,
  putStrategyScore,
  removeLoading,
  getQuestions,
} from '../../store/slice/lifeCycle';

const scoreArr = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const LifeCycle = () => {
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  const [literatureModalOpen, setLiteratureModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState({
    isOpen: false,
    cycle_id: null,
    title: null,
  });

  const [isToastShow, setIsToastShow] = useState({
    scoreToast: false,
    literatureToast: false,
    testToast: false,
  });
  const [isAlertShow, setIsAlertShow] = useState(false);
  const [cycleList, setCycleList] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [scoreVal, setScoreVal] = useState({});
  const [warnings, setWarnings] = useState(false);
  const [tooltipHover, setTooltipHover] = useState(false);
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [scoreBtnDisabled, setScoreBtnDisabled] = useState(true);
  const [toolTipIdx, setToolTipIdx] = useState();

  const tabItems = [
    {
      title: '總覽',
      value: '/dashboard/tdp/life-cycle',
      icon: <MdOutlineListAlt />,
    },
    {
      title: '預覽試題',
      value: '/dashboard/tdp/life-cycle/question-preview',
      icon: <AiOutlineFileSearch />,
    },
  ];

  const dispatch = useDispatch();
  const { loading, message, success, scoreData, scoreLoading, testData } = useSelector(selectLifeCycle);

  useEffect(() => {
    dispatch(getEnterpriseStrategy({}));
  }, [dispatch]);

  useEffect(() => {
    if (scoreVal.very_not === scoreVal.meets_the || scoreVal.very_not === scoreVal.very_much || scoreVal.meets_the === scoreVal.very_much) {
      setScoreBtnDisabled(true);
    } else if (scoreVal.very_much < scoreVal.meets_the || scoreVal.very_much < scoreVal.very_not || scoreVal.meets_the < scoreVal.very_not) {
      setScoreBtnDisabled(true);
    } else {
      setScoreBtnDisabled(false);
    }
  }, [scoreVal, setScoreBtnDisabled]);

  useEffect(() => {
    if (message) {
      setCycleList(message);
    }
    if (literatureModalOpen && cycleList.length > 0) {
      setFormValue({
        cycle_id: cycleList[0]?.cycle_id,
        title: cycleList[0]?.title,
        prone: cycleList[0]?.prone,
        prevent: cycleList[0]?.prevent,
        analysis_list: cycleList[0].analysis_list,
      });
    }
  }, [message, cycleList, literatureModalOpen]);

  const handleEditLiterature = (cycle_id) => {
    setLiteratureModalOpen(true);
    dispatch(getEnterpriseStrategy({ cycle_id: cycle_id }));
  };

  const handleEditTest = (id, title) => {
    setTestModalOpen({ isOpen: true, cycle_id: id, title: title });
  };

  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch]);

  const handleReduce = (target, idx) => {
    let newObj;
    newObj = { ...formValue };
    let arr = [...newObj.analysis_list];
    let val = { ...arr[idx] };
    val.reduce = target;
    arr[idx] = val;
    newObj.analysis_list = arr;
    setFormValue(newObj);
  };

  const handleStrengthen = (target, idx) => {
    let newObj;
    newObj = { ...formValue };
    let arr = [...newObj.analysis_list];
    let val = { ...arr[idx] };
    val.strengthen = target;
    arr[idx] = val;
    newObj.analysis_list = arr;
    setFormValue(newObj);
  };

  // 文獻設定 儲存
  const handleOnSubmit = () => {
    let count = [];
    if (!formValue.title) {
      count.push(1);
    }
    if (!formValue.prone) {
      count.push(2);
    }
    if (!formValue.prevent) {
      count.push(3);
    }
    formValue.analysis_list.forEach((item, idx) => {
      if (!item.strengthen || item.strengthen === ' ') {
        count.push({ err: 4, idx: idx });
      }
      if (item.reduce === ' ') {
        count.push({ err: 5, idx: idx });
      }
      if (!item.reduce) {
        count.push({ err: 5, idx: idx });
      }
    });

    if (count.length === 0) {
      dispatch(
        putEnterpriseStrategy({
          cycle_id: formValue.cycle_id,
          title: formValue.title,
          prone: formValue.prone,
          prevent: formValue.prevent,
          analysis_list: formValue.analysis_list,
        })
      );
    }
  };

  // 關閉文獻設定的modal
  useEffect(() => {
    if (success) {
      setLiteratureModalOpen(false);
      dispatch(getEnterpriseStrategy({}));
      setIsToastShow({
        scoreToast: false,
        literatureToast: true,
        testToast: false,
      });
      setTimeout(() => {
        setIsToastShow({
          scoreToast: false,
          literatureToast: false,
          testToast: false,
        });
      }, 2000);
    }
  }, [success, dispatch]);

  // Get分數設定的API
  useEffect(() => {
    dispatch(getStrategyScore());
  }, [dispatch]);

  useEffect(() => {
    if (
      (scoreData?.meets_the !== 0 && !scoreData?.meets_the) ||
      (scoreData?.very_much !== 0 && !scoreData?.very_much) ||
      (scoreData?.very_not !== 0 && !scoreData?.very_not)
    ) {
      setWarnings(true);
    } else {
      setWarnings(false);
    }
  }, [scoreData, warnings]);

  // 分數設定 in newObj
  useEffect(() => {
    if (scoreModalOpen && !loading) {
      setScoreVal(scoreData);
    }
  }, [scoreModalOpen, loading, scoreData]);

  useEffect(() => {
    if (scoreModalOpen && scoreLoading === 'done') {
      setScoreModalOpen(false);
      setIsToastShow({
        scoreToast: true,
        literatureToast: false,
        testToast: false,
      });
      dispatch(removeLoading());
      dispatch(getStrategyScore());
    }
    setTimeout(() => {
      setIsToastShow({
        scoreToast: false,
        literatureToast: false,
        testToast: false,
      });
    }, 2000);
  }, [scoreModalOpen, scoreLoading, dispatch]);

  useEffect(() => {
    let count = 0;
    if (formValue) {
      if (!formValue.title || !formValue.prone || !formValue.prevent) {
        count++;
      }
      formValue?.analysis_list?.forEach((item) => {
        if ((!item.strengthen && !item.reduce) || (item.strengthen === ' ' && item.reduce === ' ')) {
          count++;
        }
      });
      if (count === 0) {
        setIsSaveDisable(false);
      } else {
        setIsSaveDisable(true);
      }
    }
  }, [formValue, setIsSaveDisable]);

  return (
    <InnerLayout>
      <div className={styles.lifeCycleOuter}>
        <div className={styles.header}>
          <div className={styles.title}>企業生命週期</div>
          <div className={styles.scoreSetting}>
            <div className={styles.tick}>
              {warnings && (
                <>
                  <MdErrorOutline />
                  <span>編輯評測試題前請先設定全試題通用分數</span>
                </>
              )}
              {!warnings && (
                <>
                  <AiFillCheckCircle className={styles.checkCircle} />
                </>
              )}
            </div>
            <div className={styles.scoreSettingBtn}>
              <Button title='分數設定' type='tertiary' onClick={() => setScoreModalOpen(true)} />
              {warnings && <div className={styles.redDot}></div>}
            </div>
          </div>
        </div>
        <div className={styles.tabContainer}>
          <MenuTabs items={tabItems} type='link' active={0} />
        </div>

        <div className={styles.table}>
          <div className={styles.tableTitle}>週期列表</div>
          <table>
            <thead>
              <tr>
                <th width='20%'>週期名稱</th>
                <th width='65%'>適合的PAEI組合</th>
                <th>週期設定</th>
              </tr>
            </thead>

            {cycleList.length > 0 &&
              cycleList.map((item, idx) => (
                <tbody key={idx}>
                  <React.Fragment key={item.cycle_id}>
                    <tr className={styles.item} key={item.title}>
                      <td>{item.title}</td>
                      <td>{item.type}</td>
                      <td>
                        <div
                          className={styles.iconContainer}
                          onMouseOver={() => {
                            setToolTipIdx({ literature: item.cycle_id });
                          }}
                          onMouseLeave={() => setToolTipIdx()}
                        >
                          <Button icon={<MdOutlineArticle />} type='quaternary' onClick={() => handleEditLiterature(item.cycle_id)} />
                          {(!item.prevent || !item.prone || !item.title) && <div className={styles.redDot}></div>}
                          {toolTipIdx?.literature === item.cycle_id && <div className={styles.tip}>設定文獻說明</div>}
                        </div>
                        <div
                          className={styles.iconContainer}
                          onMouseOver={() => {
                            setToolTipIdx({ question: item.cycle_id });
                          }}
                          onMouseLeave={() => setToolTipIdx()}
                        >
                          <Button
                            icon={<RiFileEditLine />}
                            type='quaternary'
                            disabled={warnings}
                            onClick={() => handleEditTest(item.cycle_id, item.title)}
                          />
                          {testData[idx]?.cycle_id === item.cycle_id && !testData[idx]?.evaluation_question[0]?.question && (
                            <div className={styles.redDot}></div>
                          )}
                          {toolTipIdx?.question === item.cycle_id && <div className={styles.tip}>設定評測試題</div>}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                </tbody>
              ))}
            {loading && <LoadingTable row={10} column={3} />}
          </table>
        </div>
      </div>

      {/* Score Modal */}
      <ScoreSettingModal isOpen={scoreModalOpen}>
        <div className={styles.scoreModal}>
          <div className={styles.header}>
            <div className={styles.title}>分數設定</div>
            <div className={styles.info}>
              <MdErrorOutline className={styles.icon} />
              分數請從低至高且不可重複
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.tableHead}>
              <div className={styles.title}>指標</div>
              <div className={styles.title}>分數 (0~10分)</div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.index}>非常符合</div>
              <div className={styles.dropdown}>
                <Dropdown
                  listItems={scoreArr}
                  placeholder='請選擇'
                  value={scoreVal?.very_much === 0 ? '0' : !scoreVal?.very_much && scoreVal?.very_much !== 0 ? null : scoreVal?.very_much}
                  onChange={(e) => {
                    let newObj = { ...scoreVal };
                    newObj.very_much = e;
                    setScoreVal(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.index}>部分符合</div>
              <div className={styles.dropdown}>
                <Dropdown
                  listItems={scoreArr}
                  placeholder='請選擇'
                  value={scoreVal?.meets_the === 0 ? '0' : !scoreVal?.meets_the && scoreVal?.meets_the !== 0 ? null : scoreVal?.meets_the}
                  onChange={(e) => {
                    let newObj = { ...scoreVal };
                    newObj.meets_the = e;
                    setScoreVal(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.index}>非常不符合</div>
              <div className={styles.dropdown}>
                <Dropdown
                  listItems={scoreArr}
                  placeholder='請選擇'
                  value={scoreVal?.very_not === 0 ? '0' : !scoreVal?.very_not && scoreVal?.very_not !== 0 ? null : scoreVal?.very_not}
                  onChange={(e) => {
                    let newObj = { ...scoreVal };
                    newObj.very_not = e;
                    setScoreVal(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                title={'取消'}
                type='quaternary'
                onClick={() => {
                  setScoreModalOpen(false);
                  // setIsAlertShow(true);
                }}
              />
              <Button
                title={'儲存'}
                type='primary'
                disabled={scoreBtnDisabled}
                onClick={() => {
                  dispatch(
                    putStrategyScore({
                      very_not: scoreVal.very_not,
                      meets_the: scoreVal.meets_the,
                      very_much: scoreVal.very_much,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
      </ScoreSettingModal>

      <Modal isOpen={literatureModalOpen} size='lg'>
        <div className={styles.literatureModal}>
          <div className={styles.header}>
            <div className={styles.title}>
              <MdOutlineArticle className={styles.icon} />
              <div>設定文獻說明</div>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.bodySection}>
              <div className={styles.block}>
                <div className={styles.inputTitle}>週期名稱</div>
                <input
                  className={styles.smInput}
                  placeholder='請輸入'
                  type='text'
                  value={formValue.title ? formValue.title : ''}
                  onChange={(e) => {
                    let newObj;
                    newObj = { ...formValue };
                    newObj.title = e.target.value;
                    setFormValue(newObj);
                  }}
                />
              </div>
              <div className={styles.block}>
                <div className={styles.inputTitle}>容易出現的現象</div>
                <textarea
                  className={styles.lgInput}
                  placeholder='請輸入'
                  type='text'
                  value={formValue.prone ? formValue.prone : ''}
                  onChange={(e) => {
                    let newObj;
                    newObj = { ...formValue };
                    newObj.prone = e.target.value;
                    setFormValue(newObj);
                  }}
                />
              </div>
              <div className={styles.block}>
                <div className={styles.inputTitle}>應極力避免的異常現象（企業內部無力解決）</div>
                <textarea
                  className={styles.lgInput}
                  placeholder='請輸入'
                  type='text'
                  value={formValue.prevent ? formValue.prevent : ''}
                  onChange={(e) => {
                    let newObj;
                    newObj = { ...formValue };
                    newObj.prevent = e.target.value;
                    setFormValue(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.bodySection}>
              <div className={styles.paeiAnalysis}>
                <div className={styles.subtitle}>ASK 職能慣性類別</div>
                <div className={styles.paei}>paEI</div>
                <div className={styles.combineAnalysis}>
                  <div className={styles.combineTitle}>
                    <div className={styles.title}>組合分析</div>
                    <div className={styles.combineTips}>
                      <MdHelpOutline className={styles.icon} onMouseOver={() => setTooltipHover(true)} onMouseLeave={() => setTooltipHover(false)} />
                      {tooltipHover && <div className={styles.tooltips}>每項組合請至少填寫一項（強化/降低）建議</div>}
                    </div>
                  </div>
                  <div className={styles.combineBody}>
                    <table>
                      <thead>
                        <tr>
                          <th> 如果是....</th>
                          <th> PAEI互補建議：強化...</th>
                          <th>PAEI互補建議：降低...</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formValue?.analysis_list?.map((item, idx) => (
                          <tr key={item.in_case}>
                            {idx + 1 <= Math.floor(formValue?.analysis_list.length / 2) && (
                              <>
                                <td className={styles.if_paei}>{item.in_case}</td>
                                <td>
                                  <input
                                    className={styles.sminput}
                                    type='text'
                                    placeholder='-'
                                    value={!item.strengthen || item.strengthen === ' ' ? '' : item.strengthen}
                                    onChange={(e) => {
                                      handleStrengthen(e.target.value, idx);
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className={styles.sminput}
                                    type='text'
                                    placeholder='-'
                                    value={!item.reduce || item.reduce === ' ' ? '' : item.reduce}
                                    onChange={(e) => {
                                      handleReduce(e.target.value, idx);
                                    }}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table>
                      <thead>
                        <tr>
                          <th> 如果是....</th>
                          <th> PAEI互補建議：強化...</th>
                          <th>PAEI互補建議：降低...</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formValue?.analysis_list?.map((item, idx) => (
                          <tr key={item.in_case}>
                            {idx + 1 > Math.floor(formValue?.analysis_list.length / 2) && (
                              <>
                                <td className={styles.if_paei}>{item.in_case}</td>
                                <td>
                                  {(item.strengthen === 'Perfect Match' || item.reduce === 'Perfect Match') && (
                                    <div className={styles.perfectMatch}>Perfect Match</div>
                                  )}
                                  {item.strengthen !== 'Perfect Match' && item.reduce !== 'Perfect Match' && (
                                    <input
                                      className={styles.sminput}
                                      type='text'
                                      placeholder='-'
                                      value={!item.strengthen || item.strengthen === ' ' ? '' : item.strengthen}
                                      onChange={(e) => {
                                        handleStrengthen(e.target.value, idx);
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {(item.strengthen === 'Perfect Match' || item.reduce === 'Perfect Match') && <div></div>}
                                  {item.strengthen !== 'Perfect Match' && item.reduce !== 'Perfect Match' && (
                                    <input
                                      className={styles.sminput}
                                      type='text'
                                      placeholder='-'
                                      value={!item.reduce || item.reduce === ' ' ? '' : item.reduce}
                                      onChange={(e) => {
                                        handleReduce(e.target.value, idx);
                                      }}
                                    />
                                  )}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              title={'取消'}
              type='quaternary'
              onClick={() => {
                setIsAlertShow(true);
              }}
            />
            <Button
              title={'儲存'}
              type='primary'
              disabled={isSaveDisable}
              onClick={() => {
                handleOnSubmit();
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal isOpen={testModalOpen.isOpen} size={'lg'}>
        <TestModal testModalOpen={testModalOpen} setTestModalOpen={setTestModalOpen} setIsToastShow={setIsToastShow} />
      </Modal>
      <Alert
        isOpen={isAlertShow}
        mainTitle='跳過目前正在編輯的項目嗎？'
        subTitle='系統可能不會儲存你所做的變更'
        leftButtonText='繼續編輯'
        rightButtonText='跳過'
        onConfirm={() => {
          if (scoreModalOpen) {
            setScoreModalOpen(false);
            setIsAlertShow(false);
          } else if (literatureModalOpen) {
            setLiteratureModalOpen(false);
            dispatch(getEnterpriseStrategy({}));
            setIsAlertShow(false);
          }
        }}
        onClose={() => setIsAlertShow(false)}
      />
      <Toast
        title={
          (isToastShow.literatureToast && '文獻說明已儲存') ||
          (isToastShow.scoreToast && '分數設定完成') ||
          (isToastShow.testToast && '評測試題已儲存')
        }
        show={isToastShow.literatureToast || isToastShow.scoreToast || isToastShow.testToast}
        subtitle={isToastShow.literatureToast && formValue.title}
      />
    </InnerLayout>
  );
};

export default LifeCycle;
