import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
  const {
    responsive,
    backgroundColor,
    borderColor,
    legendDisplay,
    data,
    setClickEvent, //(影響右邊顯示內容)
    tableData,
    setChartColor, //點選改變透明度之參數
    dropdownVal,
  } = props;

  useEffect(() => {
    setClickEvent("");
    let oldBgColor = [
      "rgba(142, 225, 231, 1)",
      "rgba(207, 242, 173, 1)",
      "rgba(140, 157, 202, 1)",
      "rgba(207, 163, 241, 1)",
      "rgba(246, 227, 156, 1)",
      "rgba(226, 139, 133, 1)",
    ];
    setChartColor(oldBgColor);
    setLabelOpacity([1, 1, 1, 1, 1, 1]);
  }, [dropdownVal, setChartColor, setClickEvent]);

  const [labelOpacity, setLabelOpacity] = useState([1, 1, 1, 1, 1, 1]);

  const getValue = (item) => {
    let arr = [];
    data.forEach((target) => arr.push(`${target[item]}`));
    return arr;
  };

  //上面資料的數量
  let keys = [];
  Object.keys(data[0]).forEach((item) => {
    if (item === "dimension") return;
    keys.push(item);
  });

  //label的數量
  let labels = [];
  data.forEach((item) => {
    labels.push(item.dimension);
  });

  let datasets = [];
  keys.forEach((item) => {
    datasets.push({
      data: getValue(item),
      backgroundColor: backgroundColor
        ? backgroundColor
        : ["#eee", "rgba(110, 106, 106, 1)"],
      borderColor: borderColor ? borderColor : "#fff",
      borderWidth: 1,
    });
  });

  let material = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    //test
    // hover: {
    //   mode: "datasets",
    // },
    maintainAspectRatio: false,
    responsive: responsive !== null ? responsive : false,
    hoverOffset: 20,
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
        display: legendDisplay ? legendDisplay : false,
        position: "top",
        align: "end",
        labels: {
          font: {
            size: 12,
            family: null,
            weight: null,
            lineHeight: null,
          },
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style = "z-index:999999";
            tooltipEl.innerHTML = `<table style="background-color:rgba(0, 0, 0, 0.7);border-radius:4px;color:white;font-size:12px;font-family:NotoSansTC;height:80px;padding:8px;box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(10px);"></table>`;
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          if (tooltipModel.body) {
            let innerHtml = ``;

            innerHtml += "</div><div>";
            const label = context.tooltip.dataPoints[0].label;
            const formattedValue = context.tooltip.dataPoints[0].formattedValue;
            const outer = `<div>
            <div style='font-family:NotoSansTC;font-weight:700;font-size:13px;line-height: 15px;margin-bottom:5px'>${label}</div> 
            <div style='border:1px solid #D9D9D9;margin-bottom:5px;opacity:0.2'></div> 
              <div style='margin-bottom:8px;cursor:pointer;'>
                <span style='font-family:NotoSansTC;font-weight:400;font-size:12px;margin-right:8px;'>數量</span>  
                <span style='font-family:Montserrat;font-weight:500;font-size:12px;'>${
                  tableData?.rows[context.tooltip.dataPoints[0].dataIndex].total
                }  (${Math.round(formattedValue)}%)
                </span>
              </div> 
            </div>
            <div style='font-family:NotoSansTC;font-weight:500;font-size:12px;color:#34C759;font-size:12px;'>點擊圓餅圖查看更多</div>
            `;
            innerHtml += `${outer}`;

            let root = tooltipEl.querySelector("table");
            root.innerHTML = innerHtml;
            const position = context.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left +
              window.pageXOffset +
              tooltipModel.caretX -
              38 +
              "px";
            tooltipEl.style.top =
              position.top +
              window.pageYOffset +
              tooltipModel.caretY -
              70 +
              "px";
            tooltipEl.style.pointerEvents = "none";
          }
        },
      },
      datalabels: {
        formatter: function (value) {
          return Math.round(value) + "%";
        },
        color: "#333333",
        opacity: labelOpacity,
        anchor: "center",
        align: "end",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 13,
              family: "Montserrat",
            },
          },
        },
      },
    },
    onClick: (e, elements) => {
      if (elements?.length > 0) {
        setClickEvent(tableData?.rows[elements[0].index].services_name);
        let oldBgColor = [
          "rgba(142, 225, 231, 1)",
          "rgba(207, 242, 173, 1)",
          "rgba(140, 157, 202, 1)",
          "rgba(207, 163, 241, 1)",
          "rgba(246, 227, 156, 1)",
          "rgba(226, 139, 133, 1)",
        ];
        let newBgColor = [
          "rgba(142, 225, 231, 0.2)",
          "rgba(207, 242, 173, 0.2)",
          "rgba(140, 157, 202, 0.2)",
          "rgba(207, 163, 241, 0.2)",
          "rgba(246, 227, 156, 0.2)",
          "rgba(226, 139, 133, 0.2)",
        ];
        newBgColor.forEach((col, idx) => {
          if (idx !== elements[0].index) {
            oldBgColor[idx] = col;
          }
        });
        let opacity = [0.3, 0.3, 0.3, 0.3, 0.3, 0.3];
        opacity.forEach((label, idx) => {
          if (idx === elements[0].index) {
            opacity[idx] = 1;
          }
        });
        setChartColor(oldBgColor);
        setLabelOpacity(opacity);
      } else {
        setClickEvent("");
        setChartColor([
          "rgba(142, 225, 231, 1)",
          "rgba(207, 242, 173, 1)",
          "rgba(140, 157, 202, 1)",
          "rgba(207, 163, 241, 1)",
          "rgba(246, 227, 156, 1)",
          "rgba(226, 139, 133, 1)",
        ]);
        setLabelOpacity(1);
      }
    },
  };

  return <Pie data={material} options={options} plugins={[ChartDataLabels]} />;
};

export default PieChart;
