import styles from './style/Pagination.module.scss';
import ReactPaginate from 'react-paginate';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

const Pagination = ({ onPageChange, pageCount, totalData, totalPages, limit }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        顯示
        <span>
          {pageCount === 1 ? pageCount : (pageCount - 1) * limit + 1} - {pageCount === totalPages ? totalData : limit * pageCount}
        </span>
        項，總共<span>{totalData}</span>項
      </div>

      <div className={styles.pagination}>
        <ReactPaginate
          previousLabel={<HiChevronLeft className={styles.navigator} />}
          nextLabel={<HiChevronRight className={styles.navigator} />}
          disabledClassName={styles.disabled}
          containerClassName={styles.pagination}
          activeClassName={styles.active}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          pageCount={totalPages}
          onPageChange={onPageChange}
          forcePage={parseInt(pageCount - 1)}
          page={pageCount}
          disableInitialCallback={true}
        />
      </div>
    </div>
  );
};

export default Pagination;
