import styles from "./style/TextField.module.scss";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";

const TextField = (props) => {
  const {
    value,
    width,
    height,
    placeholder,
    isDisabled,
    error,
    search,
    onChange,
    deleteSearchValue,
    padding,
    numeric,
    fullWidth,
    min,
    max,
  } = props;

  const [isOnInput, setIsOnInput] = useState(false);

  const handleInputClassName = () => {
    let className = `${styles.input}`;
    if (isOnInput) className += ` ${styles.onInput}`;
    if (error) className += ` ${styles.inputError}`;
    return className;
  };

  const handleAddStyle = () => {
    let styleObj = {};
    if (width) {
      styleObj["width"] = `${width}`;
    }
    if (height) {
      styleObj["height"] = `${height}`;
    }
    if (fullWidth) {
      styleObj["width"] = `100%`;
    }
    return styleObj;
  };
  return (
    <>
      <div className={styles.inputOuter} style={handleAddStyle()}>
        <input
          type={numeric ? "number" : "text"}
          className={handleInputClassName()}
          placeholder={placeholder ? placeholder : "請輸入"}
          disabled={isDisabled}
          value={
            numeric
              ? isNaN(parseFloat(value))
                ? ""
                : parseFloat(value)
              : value
          }
          onChange={(e) => {
            onChange(e.target.value);
            setIsOnInput(true);
          }}
          onBlur={() => setIsOnInput(false)}
          style={{ paddingRight: padding ? padding : "" }}
          min={min}
          max={max}
        />
        {search && value && (
          <TiDelete
            className={styles.deleteIcon}
            onClick={() => deleteSearchValue()}
          />
        )}
        {search && <AiOutlineSearch className={styles.searchIcon} />}
      </div>
    </>
  );
};

export default TextField;
