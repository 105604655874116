import styles from "./style/Drawer.module.scss";

const Drawer = ({ isOpen, children, size }) => {
  return (
    <div className={isOpen ? styles.modalOpen : styles.modal}>
      <div
        className={
          size === "lg" ? styles.modalContentLg : styles.modalContentMd
        }>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Drawer;
