import styles from "./style/testModal.module.scss";
import { RiFileEditLine } from "react-icons/ri";

import Button from "../Utilities/Button";
import Alert from "../Utilities/Alert";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getQuestions,
  putQuestions,
  selectLifeCycle,
  removeLoading,
} from "../../store/slice/lifeCycle";

const TestModal = ({ testModalOpen, setTestModalOpen, setIsToastShow }) => {
  const dispatch = useDispatch();
  const { testData, testLoading } = useSelector(selectLifeCycle);
  const [quizList, setQuizList] = useState({});
  const [isAlertShow, setIsAlertShow] = useState(false);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(true);
  let count = 1;

  useEffect(() => {
    if (testModalOpen.isOpen) {
      dispatch(getQuestions());
    }
  }, [testModalOpen, dispatch]);

  useEffect(() => {
    if (testModalOpen.isOpen && testData.length > 0) {
      testData?.forEach((item) => {
        if (item.cycle_id === testModalOpen.cycle_id) {
          setQuizList(item);
        }
      });
    }
  }, [testModalOpen, testData, setQuizList]);

  const handleInputQues = (value, idx) => {
    let newObj = { ...quizList };
    let newArr = [...newObj.evaluation_question];
    let newVal = { ...newArr[idx] };
    newVal.question = value;
    newArr[idx] = newVal;
    newObj.evaluation_question = newArr;
    setQuizList(newObj);
  };

  const handleSubmit = () => {
    dispatch(
      putQuestions({
        cycle_id: testModalOpen.cycle_id,
        questions: quizList.evaluation_question,
      })
    );
  };

  useEffect(() => {
    if (testLoading === "done") {
      setTestModalOpen({
        isOpen: false,
        cycle_id: null,
        title: null,
      });

      setIsToastShow({
        scoreToast: false,
        literatureToast: false,
        testToast: true,
      });

      setTestModalOpen({
        isOpen: false,
        cycle_id: null,
        title: null,
      });

      setQuizList({});

      setTimeout(() => {
        setIsToastShow({
          scoreToast: false,
          literatureToast: false,
          testToast: false,
        });
        dispatch(removeLoading());
      }, 2000);
    }
  }, [testLoading, setIsToastShow, dispatch, setTestModalOpen]);

  useEffect(() => {
    let newCount = 0;
    if (quizList.evaluation_question) {
      quizList?.evaluation_question?.forEach((item) => {
        if (!item.question) {
          newCount++;
        }
      });
      if (newCount === 0) {
        setIsSaveBtnDisable(false);
      } else {
        setIsSaveBtnDisable(true);
      }
    }
  }, [quizList.evaluation_question]);

  return (
    <div className={styles.testModalContainer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <RiFileEditLine className={styles.icon} />
          <div>設定評測試題</div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.title}>週期名稱</div>
        <div className={styles.name}>{testModalOpen.title}</div>
        <div className={styles.testList}>
          <div className={styles.testListTitle}>試題列表</div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th width='6%'>項次</th>
                <th width='90%'>企業狀態描述</th>
              </tr>
            </thead>
            <tbody>
              {quizList?.evaluation_question?.map((quiz, idx) => (
                <tr key={idx}>
                  <td>{`0${count++}.`}</td>
                  <td>
                    <input
                      type='text'
                      placeholder='-'
                      value={quiz.question}
                      onChange={(e) => handleInputQues(e.target.value, idx)}
                      className={styles.input}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          title={"取消"}
          type='quaternary'
          onClick={() => {
            setIsAlertShow(true);
          }}
        />
        <Button
          title={"儲存"}
          type='primary'
          disabled={isSaveBtnDisable}
          onClick={() => handleSubmit()}
        />
      </div>
      <Alert
        isOpen={isAlertShow}
        mainTitle='跳過目前正在編輯的項目嗎？'
        subTitle='系統可能不會儲存你所做的變更'
        leftButtonText='繼續編輯'
        rightButtonText='跳過'
        onConfirm={() => {
          setTestModalOpen({
            isOpen: false,
            cycle_id: null,
            title: null,
          });
          setQuizList({});
          setIsAlertShow(false);
        }}
        onClose={() => setIsAlertShow(false)}
      />
    </div>
  );
};

export default TestModal;
