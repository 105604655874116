import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './style/practical.module.scss';
import EmptyBox from '../../Utilities/EmptyBox';
import { MdOutlineArticle } from 'react-icons/md';
import { BsFillPencilFill } from 'react-icons/bs';
import React from 'react';
import Button from '../../Utilities/Button';
import Modal from '../../Utilities/Modal';

// import API
import { getPaeiReference, selectLifeCycle, putPaeiReference, getPaeiAptitude, putPaeiAptitude, removeLoading } from '../../../store/slice/lifeCycle';

// Left Navigate API
import { editModeHandler, editModeToggleHandler } from '../../../store/slice/navigate';

const Practical = ({
  setIsToastShow,
  setIsEditMode,
  isEditMode,
  setIsSnackBarOpen,
  isAptitdeModalOpen,
  setIsAptitdeModalOpen,
  paeiArr,
  setPaeiArr,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { PaeiData, success, loading, aptitudeData } = useSelector(selectLifeCycle);

  const [paeiObj, setPaeiObj] = useState({});

  const [aptitudeBtnDisable, setAptitudeBtnDisable] = useState();
  const [paeiBtnDisabled, setPaeiBtnDisabled] = useState(true);
  const [tooltipShow, setTooltipShow] = useState({});

  useEffect(() => {
    if (params.reference === 'paei') {
      dispatch(getPaeiReference({ type: params.reference }));
    } else {
      dispatch(getPaeiReference({ type: params.reference.substring(0, 1).toUpperCase() }));
    }
  }, [dispatch, params.reference]);

  useEffect(() => {
    if (PaeiData) {
      setPaeiObj(PaeiData);
    }
  }, [PaeiData, setPaeiObj]);

  useEffect(() => {
    let count = 0;
    if (!paeiObj.advantage || !paeiObj.avoided || !paeiObj.factor || !paeiObj.avoided || !paeiObj.features || !paeiObj.shortcoming) {
      count++;
    }
    if (count === 0) {
      setPaeiBtnDisabled(false);
    } else {
      setPaeiBtnDisabled(true);
    }
  }, [paeiObj, setPaeiBtnDisabled]);

  useEffect(() => {
    if (success) {
      setIsToastShow({ paeiSetting: true });
      setIsEditMode(false);
      dispatch(editModeHandler(false));
      if (params.reference === 'paei') {
        dispatch(
          getPaeiReference({
            type: params.reference,
          })
        );
      } else {
        dispatch(
          getPaeiReference({
            type: params.reference.substring(0, 1).toUpperCase(),
          })
        );
      }

      dispatch(editModeHandler(false));
      dispatch(editModeToggleHandler(false));
    }
    setTimeout(() => {
      setIsToastShow({ paeiSetting: false });
    }, 2000);
  }, [success, dispatch, params.reference, setIsEditMode, setIsToastShow]);

  const handleTextOnChange = (e, target) => {
    const newObj = { ...paeiObj };
    if (target === 'advantage') {
      newObj.advantage = e.target.value;
    }
    if (target === 'features') {
      newObj.features = e.target.value;
    }
    if (target === 'shortcoming') {
      newObj.shortcoming = e.target.value;
    }
    if (target === 'factor') {
      newObj.factor = e.target.value;
    }
    if (target === 'relate_to') {
      newObj.relate_to = e.target.value;
    }
    if (target === 'avoided') {
      newObj.avoided = e.target.value;
    }
    setPaeiObj(newObj);
  };

  const handleUpdatePAEI = () => {
    if (params.reference === 'paei') {
      dispatch(
        putPaeiReference({
          type: params.reference,
          references: [paeiObj],
        })
      );
    } else {
      dispatch(
        putPaeiReference({
          type: params.reference.substring(0, 1).toUpperCase(),
          references: [paeiObj],
        })
      );
    }
  };

  // 設定Aptitude
  useEffect(() => {
    dispatch(getPaeiAptitude());
  }, [dispatch]);

  useEffect(() => {
    if (isAptitdeModalOpen && !loading) {
      let newArr = [];
      const newObj = aptitudeData.type;
      switch (params.reference.substring(0, 1).toUpperCase()) {
        case 'P':
          newArr = newObj?.P;
          break;
        case 'A':
          newArr = newObj?.A;
          break;
        case 'E':
          newArr = newObj?.E;
          break;
        case 'I':
          newArr = newObj?.I;
          break;
        case 'paei':
          newArr = newObj?.paei;
          break;
        default:
          break;
      }
      setPaeiArr(newArr);
    }
  }, [loading, isAptitdeModalOpen, params.reference, aptitudeData, setPaeiArr]);

  useEffect(() => {
    if (paeiArr.length > 0) {
      paeiArr.forEach((item) => {
        if (!item.chinese || !item.english) {
          setAptitudeBtnDisable(true);
        }
      });
    }
  }, [paeiArr, setAptitudeBtnDisable]);

  const handleInputAptitude = (value, id, key) => {
    let count = 0;
    let newArr = [...paeiArr];
    let Index = newArr.findIndex((item) => item.item_id === id);
    let val = { ...newArr[Index] };
    if (key === 'chinese') {
      val.chinese = value;
    } else if (key === 'chinese') {
      val.english = value;
    } else {
      val.description = value;
    }
    newArr[Index] = val;
    setPaeiArr(newArr);

    count++;

    if (count !== 0) {
      paeiArr.forEach((item) => {
        if (!item.chinese || !item.english) {
          setAptitudeBtnDisable(true);
        } else {
          setAptitudeBtnDisable(false);
        }
      });
    }
  };

  const handleSaveAptitude = () => {
    dispatch(
      putPaeiAptitude({
        type: params.reference.substring(0, 1).toUpperCase(),
        aptitude: paeiArr,
      })
    );
  };

  const handleClearInput = () => {
    let newArr = [...paeiArr];
    newArr.forEach((item, idx) => {
      let val = { ...item };
      val.chinese = '';
      val.english = '';
      val.description = '';
      newArr[idx] = val;
    });
    setPaeiArr(newArr);
  };

  useEffect(() => {
    if (isAptitdeModalOpen && success) {
      setIsAptitdeModalOpen(false);
      setIsToastShow({
        paeiSetting: false,
        aptitudeSetting: true,
      });
      setTimeout(() => {
        setIsToastShow({
          paeiSetting: false,
          aptitudeSetting: false,
        });
        dispatch(getPaeiAptitude());
        dispatch(removeLoading());
      }, 2000);
    }
  }, [isAptitdeModalOpen, success, setIsAptitdeModalOpen, setIsToastShow, dispatch]);

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.iconOuter}>
          <div className={styles.title}>
            {params.reference === ':practical' && 'Practical 目標導向'}
            {params.reference === ':administrative' && 'Administrative/analyze 管理分析導向'}
            {params.reference === ':entrepreneurial' && 'Entrepreneurial 創新創業導向'}
            {params.reference === ':integrative' && 'Integrative 整合導向'}
            {params.reference === ':paei' && 'paei 整合導向'}
          </div>
          {isEditMode ? (
            <div className={styles.iconOuter}>
              <div className={styles.iconFlex}>
                <Button
                  title='取消'
                  type='subsidiary'
                  onClick={() => {
                    setIsSnackBarOpen(true);
                  }}
                />
              </div>
              <div className={styles.iconFlex}>
                <Button title='完成編輯' type='primary' disabled={paeiBtnDisabled} onClick={handleUpdatePAEI} />
              </div>
            </div>
          ) : (
            <div className={styles.iconOuter}>
              <div
                className={styles.iconFlex}
                onMouseOver={() => setTooltipShow({ article: true })}
                onMouseLeave={() => setTooltipShow({ article: false })}
              >
                {params.reference !== 'paei' && (
                  <>
                    <Button icon={<MdOutlineArticle />} type='quaternary' onClick={() => setIsAptitdeModalOpen(true)} />
                    {tooltipShow?.article && (
                      <div className={styles.tip} style={{ width: '60px' }}>
                        性向設定
                      </div>
                    )}
                  </>
                )}

                {/* P */}
                {params.reference.substring(0, 1).toUpperCase() === 'P' && !aptitudeData?.type?.P[0]?.chinese && (
                  <div className={styles.redDot}></div>
                )}
                {/* A */}
                {params.reference.substring(0, 1).toUpperCase() === 'A' && !aptitudeData?.type?.A[0]?.chinese && (
                  <div className={styles.redDot}></div>
                )}
                {/* E */}
                {params.reference.substring(0, 1).toUpperCase() === 'E' && !aptitudeData?.type?.E[0]?.chinese && (
                  <div className={styles.redDot}></div>
                )}
                {/* I */}
                {params.reference.substring(0, 1).toUpperCase() === 'I' && !aptitudeData?.type?.I[0]?.chinese && (
                  <div className={styles.redDot}></div>
                )}
              </div>
              <div
                className={styles.iconFlex}
                onMouseOver={() => setTooltipShow({ edit: true })}
                j
                onMouseLeave={() => setTooltipShow({ edit: false })}
              >
                <Button
                  icon={<BsFillPencilFill />}
                  type='quaternary'
                  onClick={() => {
                    setIsEditMode(true);
                    dispatch(editModeHandler(true));
                    setTooltipShow({ edit: false });
                  }}
                />
                {!paeiObj.advantage && <div className={styles.redDot}></div>}
                {tooltipShow?.edit && (
                  <div className={styles.tip} style={{ width: '40px' }}>
                    編輯
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th>特點</th>
              <th>優勢</th>
              <th>盲點</th>
              <th>情緒失控觸發因素</th>
            </tr>
          </thead>
          {isEditMode && (
            <tbody>
              <tr>
                <td className={styles.editModeTd}>
                  <div className={styles.textAreaOuter}>
                    <textarea
                      className={styles.editMode}
                      placeholder='例：有果斷的判斷力...'
                      onChange={(e) => handleTextOnChange(e, 'features')}
                      value={paeiObj?.features}
                    />
                  </div>
                  <BsFillPencilFill className={styles.textAeraIcon} />
                </td>
                <td className={styles.editModeTd}>
                  <div className={styles.textAreaOuter}>
                    <textarea
                      className={styles.editMode}
                      placeholder='例：擅於運用矛盾...'
                      onChange={(e) => handleTextOnChange(e, 'advantage')}
                      value={paeiObj?.advantage}
                    />
                  </div>
                  <BsFillPencilFill className={styles.textAeraIcon} />
                </td>
                <td className={styles.editModeTd}>
                  <div className={styles.textAreaOuter}>
                    <textarea
                      className={styles.editMode}
                      placeholder='例：不擅傾聽...'
                      onChange={(e) => handleTextOnChange(e, 'shortcoming')}
                      value={paeiObj?.shortcoming}
                    />
                  </div>
                  <BsFillPencilFill className={styles.textAeraIcon} />
                </td>
                <td className={styles.editModeTd}>
                  <div className={styles.textAreaOuter}>
                    <textarea
                      className={styles.editMode}
                      placeholder='例：無法掌握（控制)...'
                      onChange={(e) => handleTextOnChange(e, 'factor')}
                      value={paeiObj?.factor}
                    />
                  </div>
                  <BsFillPencilFill className={styles.textAeraIcon} />
                </td>
              </tr>
            </tbody>
          )}
          {!isEditMode && !PaeiData?.features && (
            <tbody>
              <tr>
                <td colSpan='4'>
                  <EmptyBox caption='點擊右上「編輯」開始編輯文獻說明' />
                </td>
              </tr>
            </tbody>
          )}
          {!isEditMode && PaeiData?.features && (
            <tbody>
              <tr>
                <td className={styles.nonEditdTd}>
                  <pre className={styles.nonEditMode}>{PaeiData?.features}</pre>
                </td>
                <td className={styles.nonEditdTd}>
                  <pre className={styles.nonEditMode}>{PaeiData?.advantage}</pre>
                </td>
                <td className={styles.nonEditdTd}>
                  <pre className={styles.nonEditMode}>{PaeiData?.shortcoming}</pre>
                </td>
                <td className={styles.nonEditdTd}>
                  <pre className={styles.nonEditMode}>{PaeiData?.factor}</pre>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <table>
          <thead>
            <tr>
              <th>跟他們相處最好「要...」</th>
              <th>跟他們相處最好「不要...」</th>
            </tr>
          </thead>
          {isEditMode && (
            <tbody>
              <tr>
                <td className={styles.editModeTd}>
                  <div className={styles.textAreaOuter}>
                    <textarea
                      className={styles.editMode}
                      placeholder='例：開門見山、直接了當...'
                      onChange={(e) => handleTextOnChange(e, 'relate_to')}
                      value={paeiObj?.relate_to}
                    />
                  </div>
                  <BsFillPencilFill className={styles.textAeraIcon} />
                </td>
                <td className={styles.editModeTd}>
                  <div className={styles.textAreaOuter}>
                    <textarea
                      className={styles.editMode}
                      placeholder='例：一開始就跟他們稱兄道弟...'
                      onChange={(e) => handleTextOnChange(e, 'avoided')}
                      value={paeiObj?.avoided}
                    />
                  </div>
                  <BsFillPencilFill className={styles.textAeraIcon} />
                </td>
              </tr>
            </tbody>
          )}
          {!isEditMode && !PaeiData?.features && (
            <tbody>
              <tr>
                <td colSpan='4'>
                  <EmptyBox caption='點擊右上「編輯」開始編輯文獻說明' />
                </td>
              </tr>
            </tbody>
          )}
          {!isEditMode && PaeiData?.features && (
            <tbody>
              <tr>
                <td className={styles.nonEditdTd}>
                  <pre className={styles.nonEditMode}>{PaeiData?.relate_to}</pre>
                </td>
                <td className={styles.nonEditdTd}>
                  <pre className={styles.nonEditMode}>{PaeiData?.avoided}</pre>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <Modal size='lg' isOpen={isAptitdeModalOpen}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            <div className={styles.title}>
              <MdOutlineArticle className={styles.icon} />
              <div>性向設定</div>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.cycleTitle}>週期名稱</div>
            <div className={styles.cycleName}>創業期</div>
            <div className={styles.attributeName}>屬性性向</div>
          </div>
          <div className={styles.modalTableContainer}>
            <table>
              <thead>
                <tr>
                  <th width='8%'>項次</th>
                  <th width='20%'>中文敘述</th>
                  <th width='20%'>英文敘述</th>
                  <th>描述</th>
                </tr>
              </thead>
              {paeiArr?.map((item, idx) => (
                <tbody key={item.item_id}>
                  {/* {idx < paeiArr.length / 2 && ( */}
                  <tr>
                    <td className={styles.tableIndex}>
                      <div className={styles.index}>{idx < 9 ? `0${idx + 1}.` : `${idx + 1}.`}</div>
                    </td>
                    <td className={styles.tableIndex}>
                      <input
                        value={item.chinese ? item.chinese : ''}
                        placeholder='-'
                        onChange={(e) => handleInputAptitude(e.target.value, item.item_id, 'chinese')}
                      />
                    </td>
                    <td className={styles.tableIndex}>
                      <input
                        value={item.english ? item.english : ''}
                        placeholder='-'
                        onChange={(e) => handleInputAptitude(e.target.value, item.item_id, 'english')}
                      />
                    </td>
                    <td className={styles.tableIndex}>
                      <input
                        value={item.description ? item.description : ''}
                        placeholder='-'
                        onChange={(e) => handleInputAptitude(e.target.value, item.item_id, 'description')}
                      />
                    </td>
                  </tr>
                  {/* )} */}
                </tbody>
              ))}
            </table>

            <table style={{ display: 'none' }}>
              <thead>
                <tr>
                  <th width='40px'>項次</th>
                  <th>中文敘述</th>
                  <th>英文敘述</th>
                </tr>
              </thead>
              {paeiArr?.map((item, idx) => (
                <tbody key={item.item_id}>
                  {idx >= paeiArr.length / 2 && (
                    <tr>
                      <td className={styles.tableIndex}>
                        <div className={styles.index}>{idx < 9 ? `0${idx + 1}.` : `${idx + 1}.`}</div>
                      </td>
                      <td className={styles.tableIndex}>
                        <input
                          value={item.chinese ? item.chinese : ''}
                          placeholder='-'
                          onChange={(e) => handleInputAptitude(e.target.value, item.item_id, 'chinese')}
                        />
                      </td>
                      <td className={styles.tableIndex}>
                        <input
                          value={item.english ? item.english : ''}
                          placeholder='-'
                          onChange={(e) => handleInputAptitude(e.target.value, item.item_id, 'english')}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              ))}
            </table>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.footerContainer}>
            <div className={styles.btnGroup}>
              <Button title='全部清除' type='subsidiary' onClick={handleClearInput} disabled={aptitudeBtnDisable} />
            </div>
            <div className={styles.btnGroup}>
              <Button title='取消' type='subsidiary' onClick={() => setIsSnackBarOpen(true)} />
              <Button title='儲存' type='primary' onClick={handleSaveAptitude} disabled={aptitudeBtnDisable} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Practical;
