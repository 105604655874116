import ICON_IMG_LIST from "../../../constant/ICON_IMG_LIST";
import styles from "./style/Radio.module.scss";

const Radio = ({ onChange, value, children, disable }) => {
  return (
    <div className={styles.radioBox}>
      <div className={styles.option}>
        <img
          className={disable ? styles.disable : null}
          onClick={() => !disable && onChange(value)}
          src={
            value === true
              ? ICON_IMG_LIST.radioChecked
              : ICON_IMG_LIST.radioUnchecked
          }
          alt='radio'
        />
        {children}
      </div>
    </div>
  );
};

export default Radio;
