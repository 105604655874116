import styles from './style/Dashboard.module.scss';
import Modal from '../Utilities/Modal';
import Button from '../Utilities/Button';
import TextField from '../Utilities/TextField';
import { MdClear } from 'react-icons/md';
import { useState } from 'react';
import { format } from 'date-fns';
import AlertSwitch from '../Utilities/AlertSwitch';
import Datepicker from '../Utilities/Datepicker/Datepicker';
import Dropdown from '../Utilities/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { openTrialPlan } from '../../store/slice/manager/enterpriseCompany';
import { selectMenu } from '../../store/slice/manager/menu';

const TrialPlanModal = ({ openTrialPlanModal, setOpenTrialPlanModal, restart }) => {
  const dispatch = useDispatch();
  const { menu } = useSelector(selectMenu);
  const [trialEnable, setTrialEnable] = useState(openTrialPlanModal.activate);
  const [dateError, setDateError] = useState(false);
  const [planData] = useState(menu.find((e) => e.name === 'Keystone Core').avaliable_plans);
  const [plan, setPlan] = useState(openTrialPlanModal?.plan?.name || 'Basic');
  const [maxUsers, setMaxUsers] = useState(openTrialPlanModal.users_count_upper_limit || '');
  const [planDate, setPlanDate] = useState({
    startDay: openTrialPlanModal.start_date,
    endDay: openTrialPlanModal.expiration_date,
  });
  const [error, setError] = useState(false);

  const handleStartDay = (date) => {
    const startDay = new Date(date);
    return format(startDay.setDate(startDay.getDate() - 1), 'yyyy-MM-dd');
  };
  const handleDay = () => {
    const startDay = new Date(planDate.startDay);
    const endDay = new Date(planDate.endDay);
    const daysDiffA = Math.floor((+endDay - +startDay) / (1000 * 60 * 60 * 24)) + 1;
    return daysDiffA > 0 ? daysDiffA : '-';
  };

  const handleDate = () => {
    const startDay = new Date(planDate.startDay);
    const endDay = new Date(planDate.endDay);
    endDay.setHours(0, 0, 0, 0);
    startDay.setHours(0, 0, 0, 0);
    return startDay.getTime() < endDay.getTime();
  };

  return (
    <Modal isOpen={openTrialPlanModal} size='sm' height={`366px`} width={`550px`}>
      <div className={styles.trialPlanModal}>
        <div className={styles.trialPlanModalTop}>
          試用方案
          <Button type='tertiary' icon={<MdClear />} onClick={() => setOpenTrialPlanModal('')} />
        </div>
        <div className={styles.divider} />
        <div className={styles.trialPlanModalMid}>
          <div className={styles.trialPlanModalMidTop}>
            <div className={styles.leftTitle}>試用方案</div>
            <Dropdown
              placeholder='請選擇方案'
              value={plan}
              borderColor={'#ffffff'}
              listItems={planData.map((e) => e.name)}
              onChange={(e) => {
                setPlan(e);
              }}
            />
            <div className={styles.rightTitle}>試用人數上限</div>
            <TextField
              placeholder='請輸入'
              width='100%'
              numeric={true}
              value={maxUsers ? maxUsers : ''}
              onChange={(e) => setMaxUsers(e)}
              error={(!maxUsers || maxUsers <= 0) && error}
              min={1}
            />
          </div>

          {console.log('planDate', planDate)}

          <div className={styles.trialPlanModalMidMid}>
            <div className={styles.leftTitle}>試用時間</div>
            <Datepicker
              selectDate={planDate?.startDay}
              setSelectDate={(e) => {
                setDateError(false);
                setPlanDate({ ...planDate, startDay: e });
              }}
              disableEvents={[
                {
                  start: `${handleStartDay(new Date())}`,
                  end: `${
                    planDate?.endDay !== '' && planDate?.endDate !== undefined && planDate?.endDate !== null
                      ? handleDate()
                        ? planDate?.endDay
                        : '2100-12-31'
                      : '2100-12-31'
                  }`,
                },
              ]}
              error={(error && !planDate?.startDay) || dateError}
            />
            至
            <Datepicker
              selectDate={planDate?.endDay}
              setSelectDate={(e) => {
                setDateError(false);
                setPlanDate({ ...planDate, endDay: e });
              }}
              disableEvents={[
                {
                  start: `${planDate?.startDay !== '' ? handleStartDay(planDate?.startDay) : handleStartDay(new Date())}`,
                  end: `2100-12-31`,
                },
              ]}
              error={(error && !planDate?.endDay) || dateError}
            />
          </div>
          <div className={styles.trialPlanModalMidMidTip}>試用天數為 {handleDay()}</div>
          <div className={styles.trialPlanModalMidBottom}>
            <AlertSwitch onChange={() => setTrialEnable(!trialEnable)} color={{ on: '#5856D6', off: '#5856D64D' }} isChecked={trialEnable} />
            開通試用
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.trialPlanModalBottom}>
          <Button title='取消' type='secondary' onClick={() => setOpenTrialPlanModal('')} />
          <Button
            title='完成'
            type='primary'
            onClick={() => {
              if (maxUsers > 0 && planDate.startDay !== '' && planDate.endDay !== '' && handleDay !== '') {
                const toDay = new Date();
                const startDay = new Date(planDate.startDay);
                const endDay = new Date(planDate.endDay);
                toDay.setHours(0, 0, 0, 0);
                startDay.setHours(0, 0, 0, 0);
                endDay.setHours(0, 0, 0, 0);
                if (startDay.getTime() < toDay.getTime() || endDay.getTime() < toDay.getTime()) {
                  setDateError(true);
                  return;
                }
                let newPlanDate = planDate;
                let data = {
                  enterprise_id: openTrialPlanModal.enterprise_id,
                  plan_id: planData.find((e) => e.name === plan).id,
                  start_date:
                    openTrialPlanModal.start_date === newPlanDate.startDay ? newPlanDate.startDay : format(newPlanDate.startDay, 'yyyy-MM-dd'),
                  expiration_date:
                    openTrialPlanModal.expiration_date === newPlanDate.endDay ? newPlanDate.endDay : format(newPlanDate.endDay, 'yyyy-MM-dd'),
                  users_count_upper_limit: +maxUsers,
                  activate: trialEnable,
                };
                dispatch(openTrialPlan(data)).then((e) => {
                  if (e.payload.msg === 'success') {
                    restart();
                  }
                });
                setError(false);
                setOpenTrialPlanModal('');
              } else {
                setError(true);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
export default TrialPlanModal;
