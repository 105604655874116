import Skeleton from 'react-loading-skeleton';


const LoadingTable = ({ row, column }) => {
  return (
    <tbody>
      {Array.apply(null, Array(row)).map((data, index) => (
        <tr key={index}>
          {Array.apply(null, Array(column)).map((data, idx) => (
            <td key={idx} width={`${100 / column}%`}>
              <div style={{ width: `${100}%` }}>
                <Skeleton height={20} />
              </div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default LoadingTable;
