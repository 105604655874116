import { useRef, useEffect } from 'react';
import styles from './style/MoreButton.module.scss';
import { BsThreeDots } from 'react-icons/bs';
import Tips from '../Tips';

const MoreButton = (props) => {
  const wrapperRef = useRef();
  const { onClick, children, open } = props;

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (open) onClick(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, open, onClick]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <Tips detail={'更多'} topDiff={3}>
        <button onClick={onClick} className={styles.more}>
          <BsThreeDots />
        </button>
      </Tips>
      {open && <div className={styles.children}>{children}</div>}
    </div>
  );
};

export default MoreButton;
