import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetClientRequest } from '../../../api/helper';

// DASHBOARD 列表
export const getDashboardFilter = createAsyncThunk('dashboard/getDashboardFilter', async (parameters) => {
  let url = `/api/dashboard_filter`;
  const response = await sendGetClientRequest(url);
  return response.data;
});

export const getEnterpriseList = createAsyncThunk('dashboard/getEnterpriseList', async (parameters) => {
  let url = `/api/dashboard/enterprise`;
  if (parameters?.limit) url += `?limit=${parameters.limit}`;
  if (parameters?.page) url += `&page=${parameters.page}`;
  if (parameters?.search) url += `&search=${parameters.search}`;
  if (parameters?.activate) url += `&activate=${parameters.activate === 'true' ? 1 : 0}`;

  const response = await sendGetClientRequest(url);
  return response.data;
});

export const getDashboardAnalysis = createAsyncThunk('dashboard/getDashboardAnalysis', async (parameters) => {
  let url = `/api/dashboard_analysis`;
  if (parameters?.activate) url += `?activate=${parameters.activate === 'true' ? 1 : 0}`;
  const response = await sendGetClientRequest(url);
  return response.data;
});

export const getDashboardEnterprise = createAsyncThunk('dashboard/getDashboardEnterprise', async (parameters) => {
  let url = `/api/dashboard/enterprise/${parameters.enterprise_id}?period_id=${parameters.period_id}`;
  const response = await sendGetClientRequest(url);
  return response.data;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    filter: '',
    enterprise: '',
    analysis: '',
    detail: '',
    loading: true,
    success: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.success = false;
      state.loading = false;
    },
  },
  extraReducers: {
    // DASHBOARD FILTER
    [getDashboardFilter.pending]: (state) => {
      state.success = false;
    },
    [getDashboardFilter.fulfilled]: (state, { payload }) => {
      if (payload) state.filter = payload;
    },
    [getDashboardFilter.rejected]: (state) => {
      state.loading = false;
    },

    // DASHBOARD LISTS
    [getEnterpriseList.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getEnterpriseList.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.enterprise = payload;
        state.loading = false;
        state.success = true;
      } else {
        state.loading = false;
        state.success = false;
      }
    },
    [getEnterpriseList.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    },

    // DASHBOARD ANALYSIS
    [getDashboardAnalysis.pending]: (state) => {
      state.loaded = true;
    },
    [getDashboardAnalysis.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.analysis = payload;
        state.loaded = false;
      } else {
        state.loaded = false;
      }
    },
    [getDashboardAnalysis.rejected]: (state) => {
      state.loaded = false;
    },

    // DASHBOARD DETAIL
    [getDashboardEnterprise.pending]: (state) => {
      state.loaded = true;
    },
    [getDashboardEnterprise.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.detail = payload;
        state.loaded = false;
      } else {
        state.loaded = false;
      }
    },
    [getDashboardEnterprise.rejected]: (state) => {
      state.loaded = false;
    },
  },
});

export default dashboardSlice.reducer;
export const selectDashboard = (state) => state.dashboard;
export const { resetSuccess } = dashboardSlice.actions;
