import styles from "./style/scoreSettingModal.module.scss";

const ScoreSettingModal = ({ isOpen, children, size }) => {
  return (
    <div id='myModal' className={isOpen ? styles.modalOpen : styles.modal}>
      <div className={styles.modalContent}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ScoreSettingModal;
