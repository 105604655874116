import styles from "./style/Checkbox.module.scss";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const Checkbox = ({ value, onClick, isDisable, color }) => {
  function handleCheckboxClassName(isDisable) {
    let className = `${styles["baseCheckbox"]}`;
    if (isDisable) className += ` ${styles["isDisable"]}`;
    return className;
  }

  return (
    <label>
      <input
        className={styles.checkboxInput}
        type='checkbox'
        onChange={() => {
          if (!isDisable) {
            onClick();
          }
        }}
      />
      {value ? (
        <MdCheckBox
          color={color}
          className={handleCheckboxClassName(isDisable)}
        />
      ) : (
        <MdCheckBoxOutlineBlank
          className={handleCheckboxClassName(isDisable)}
        />
      )}
    </label>
  );
};

export default Checkbox;
