import styles from "./style/Dropdown.module.scss";
import { useState, useEffect, useRef } from "react";
import { IoChevronDown } from "react-icons/io5";

const Dropdown = (props) => {
  const {
    value,
    caption,
    tooltips,
    onChange,
    listItems,
    placeholder,
    error,
    disable,
    wide,
  } = props;

  const [listOpen, setListOpen] = useState(false);
  const wrapperRef = useRef();
  const ElementRef = useRef(null);
  const UlRef = useRef(null);
  const [tipPosition, setTipPosition] = useState(0);
  const [displayTip, setDisplayTip] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [tipsContent, setTipsContent] = useState(null);

  const dropdownPadding = 4;
  const listItemHeight = 36;
  //拿到這個dropdown的螢幕位置
  const domPositionY = wrapperRef?.current?.getBoundingClientRect()?.y;
  const domPositionX = wrapperRef?.current?.getBoundingClientRect()?.x;
  const domWidth = wrapperRef?.current?.getBoundingClientRect()?.width;

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (listOpen) setListOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, listOpen, setListOpen]);

  useEffect(() => {
    if (ElementRef.current && displayTip) {
      const dom = ElementRef.current.getBoundingClientRect();
      setTipPosition(dom.height);
    }
  }, [ElementRef, displayTip]);

  function handleBtnImgClassName(listOpen) {
    let className = `${styles["btn-img"]}`;
    if (listOpen) className += ` ${styles["rotate180"]}`;
    return className;
  }

  function handleListClassName(listOpen) {
    let className = `${styles["dropDownList"]}`;
    if (!listOpen) className += ` ${styles["hidden"]}`;
    return className;
  }

  function handleDropdownErrorClassName(error) {
    let className = `${styles["dropDown"]}`;
    if (disable) className += ` ${styles["disabled"]}`;
    if (wide) className += ` ${styles["wide"]}`;
    if (error) className += ` ${styles["dropDownError"]}`;
    return className;
  }

  return (
    <div
      ref={wrapperRef}
      className={handleDropdownErrorClassName(error)}
      onClick={() => !disable && setListOpen(!listOpen)}>
      <span
        className={value === "" ? styles.nonValueSpan : ""}
        style={{
          whiteSpace: "noWrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: value ? "#5856d6" : "#999",
        }}>
        {value || placeholder}
      </span>
      <div className={handleBtnImgClassName(listOpen)}>
        <IoChevronDown />
      </div>

      {tooltips && displayTip && tipsContent && hoverIndex !== null && (
        <div
          className={styles.tooltips}
          style={{
            top: `${
              domPositionY +
              dropdownPadding +
              tipPosition +
              (hoverIndex + 1) * listItemHeight -
              UlRef.current.scrollTop
            }px`,
            left: domPositionX + domWidth / 2,
            transform: "translate(-50%)",
          }}>
          {tipsContent}
        </div>
      )}
      <ul
        className={handleListClassName(listOpen)}
        ref={UlRef}
        onScroll={() => {
          setDisplayTip(false);
        }}>
        {listItems?.length > 0 &&
          listItems.map((item, index) => (
            <li
              ref={ElementRef}
              key={index}
              onClick={() => {
                if (!item.disabled) {
                  onChange(item);
                  setListOpen(false);
                } else {
                  return;
                }
              }}
              onMouseLeave={() => {
                setDisplayTip(false);
                setHoverIndex(null);
                setTipsContent(null);
              }}
              onMouseOver={() => {
                setDisplayTip(true);
                setHoverIndex(index);
                setTipsContent(tooltips ? item[tooltips] : item[caption]);
              }}
              style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}>
              <input value={caption ? item[caption] : item} readOnly />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Dropdown;
