import styles from "./style/EmptyBox.module.scss";
import Button from "../Button";

import picture01 from "./img/EmptyBox/search.png";
import picture02 from "./img/EmptyBox/structure.png";
import picture03 from "./img/EmptyBox/team.png";
import picture04 from "./img/EmptyBox/analyst.png";

const EmptyBox = (props) => {
  const { caption, title, onClick, height, disable, type, icon, iconPosition } =
    props;

  return (
    <div className={styles.emptyBox} style={height && { height: height }}>
      <div className={styles.wrapper}>
        {type === 1 && <img src={picture01} alt='' />}
        {type === 2 && <img src={picture02} alt='' />}
        {type === 3 && <img src={picture03} alt='' />}
        {type === 4 && <img src={picture04} alt='' />}

        <div className={styles.text}>{caption}</div>
        {title && (
          <Button
            title={title}
            type='primary'
            onClick={onClick}
            disable={disable}
            icon={icon}
            iconPosition={iconPosition}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyBox;
