import { createSlice } from "@reduxjs/toolkit";

const workspaceStates = createSlice({
  name: "workspaceStates",
  initialState: {
    success: false,
    toastShow: false,
    deleteToastShow: false,
    isSave: false,
    isSwitch: false,
    isDelete: false,
    userToast: false,
    deleteUserToast: false,
    userName: "",
    fowardIndex: 0,
    assignModal: false,
    permissionModal: false,
  },
  reducers: {
    startService: (state) => {
      state.success = true;
    },
    closeService: (state) => {
      state.success = false;
    },
    saveWorkspace: (state) => {
      state.isSave = true;
    },
    unsaveWorkspace: (state) => {
      state.isSave = false;
    },
    switchSevice: (state) => {
      state.isSwitch = true;
    },
    unswitchSevice: (state) => {
      state.isSwitch = false;
    },
    removeService: (state) => {
      state.isDelete = true;
    },
    unRemoveService: (state) => {
      state.isDelete = false;
    },
    newUserToast: (state) => {
      state.userToast = true;
    },
    removeNewUserTaost: (state) => {
      state.userToast = false;
    },
    DelUserToast: (state) => {
      state.deleteUserToast = true;
    },
    removeDeleteUserToast: (state) => {
      state.deleteUserToast = false;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setfowardIndex: (state, action) => {
      state.fowardIndex = action.payload;
    },
    // OPEN MODAL
    assignModalOpen: (state) => {
      state.assignModal = true;
    },
    assignModalClose: (state) => {
      state.assignModal = false;
    },
    permissionModalOpen: (state) => {
      state.permissionModal = true;
    },
    permissionModalClose: (state) => {
      state.permissionModal = false;
    },
  },
});

export default workspaceStates.reducer;
export const selectWorkspaceStates = (state) => state.workspaceStates;
export const {
  startService,
  closeService,
  saveWorkspace,
  unsaveWorkspace,
  switchSevice,
  unswitchSevice,
  removeService,
  unRemoveService,
  newUserToast,
  removeNewUserTaost,
  DelUserToast,
  removeDeleteUserToast,
  setUserName,
  setfowardIndex,
  assignModalOpen,
  assignModalClose,
  permissionModalOpen,
  permissionModalClose,
} = workspaceStates.actions;
