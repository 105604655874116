import styles from './style/layout.module.scss';
import React, { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import Tabs from '../Utilities/Tabs';
import AssignTableAll from './AssignTableAll';
import AssignedTable from './AssignedTable';
import UnAssignedTable from './UnAssignedTable';
import { useSelector, useDispatch } from 'react-redux';
import { getWorksapceAssignedListAll, selectWorkspaceListsSlice, getUserassignedList } from '../../store/slice/workspaceList';

const WorkspaceAssignModal = ({ isOpen, workspaceId, userAssigned, workspaceName, status }) => {
  const tabList = ['全部', '已指派', '未指派'];
  const [tabValue, setTabValue] = useState(0);
  const [assignedData, setAssignedData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();
  const workspace = useSelector(selectWorkspaceListsSlice);

  useEffect(() => {
    if (isOpen || workspace.enterpriseAssignedLoad === 'doneLoading') {
      if (!userAssigned.isUser && !searchTerm) {
        switch (tabValue) {
          case 0:
            dispatch(getWorksapceAssignedListAll({ center_workspace_id: workspaceId }));
            break;
          case 1:
            dispatch(getWorksapceAssignedListAll({ center_workspace_id: workspaceId }));
            break;
          case 2:
            dispatch(getWorksapceAssignedListAll({ center_workspace_id: workspaceId }));
            break;
          default:
        }
      } else if (!userAssigned.isUser && searchTerm) {
        switch (tabValue) {
          case 0:
            dispatch(
              getWorksapceAssignedListAll({
                center_workspace_id: workspaceId,
                keyword: searchTerm,
              })
            );
            break;
          case 1:
            dispatch(
              getWorksapceAssignedListAll({
                center_workspace_id: workspaceId,
                keyword: searchTerm,
              })
            );
            break;
          case 2:
            dispatch(
              getWorksapceAssignedListAll({
                center_workspace_id: workspaceId,
                keyword: searchTerm,
              })
            );
            break;
          default:
        }
      }
    }
  }, [dispatch, isOpen, workspaceId, tabValue, workspace.enterpriseAssignedLoad, userAssigned.isUser, searchTerm]);

  useEffect(() => {
    if (isOpen && userAssigned.isUser && !searchTerm) {
      switch (tabValue) {
        case 0:
          dispatch(getUserassignedList({ center_workspace_id: workspaceId }));
          break;
        case 1:
          dispatch(getUserassignedList({ center_workspace_id: workspaceId }));
          break;
        case 2:
          dispatch(getUserassignedList({ center_workspace_id: workspaceId }));
          break;
        default:
      }
    } else if (isOpen && userAssigned.isUser && searchTerm) {
      switch (tabValue) {
        case 0:
          dispatch(
            getUserassignedList({
              center_workspace_id: workspaceId,
              keyword: searchTerm,
            })
          );
          break;
        case 1:
          dispatch(
            getUserassignedList({
              center_workspace_id: workspaceId,
              keyword: searchTerm,
            })
          );
          break;
        case 2:
          dispatch(
            getUserassignedList({
              center_workspace_id: workspaceId,
              keyword: searchTerm,
            })
          );
          break;
        default:
      }
    }
  }, [dispatch, isOpen, workspaceId, tabValue, userAssigned.isUser, workspace.putAssignedDataLoad, searchTerm]);

  useEffect(() => {
    if (workspace.assignedListLoad === 'doneLoading' || workspace.userAssignedListDataLoad === 'doneLoading') {
      let assignedArr = [];
      let unAssignedArr = [];
      workspace.assignedListData.data.forEach((items) => {
        if (!userAssigned.isUser && items.assign.workspace !== '-') {
          assignedArr.push(items);
        } else if (!userAssigned.isUser && items.assign.workspace === '-') {
          unAssignedArr.push(items);
        } else if (userAssigned.isUser && items.assign.user !== '-') {
          assignedArr.push(items);
        } else if (userAssigned.isUser && items.assign.user === '-') {
          unAssignedArr.push(items);
        }
      });
      setAssignedData({
        assignedLength: assignedArr.length,
        assignedListData: assignedArr,
        unAssignedListData: unAssignedArr,
      });
    }
  }, [workspace.assignedListLoad, workspace.assignedListData, workspace.userAssignedListDataLoad, userAssigned]);

  return (
    <div id='myModal' className={isOpen ? styles.modalOpen : styles.modal}>
      <div className={styles.modalContentLg}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>{status ? '指派企業帳號' : '指派企業最高管理權限'}</div>
          <div className={styles.headerContent}>{workspaceName}</div>
        </div>
        <div className={styles.assignContent}>將指定的企業指派給該 Workspace 的使用者進行管理。</div>
        <div className={styles.listTitleContainer}>
          <div className={styles.listTitle}>企業帳號列表</div>
          <div className={styles.listAssigned}>
            目前已指派：
            {assignedData.assignedLength}
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.searchBar}>
            <div className={styles.searchOutline}>
              <input
                type='text'
                placeholder='搜尋公司名稱或統一編號'
                className='searchInput'
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm ? searchTerm : ''}
                // disabled={wsList.length === 0 ? "disabled" : false}
              />
              <div className={styles.BsSearch}>
                <BsSearch />
              </div>
            </div>
          </div>
          <div className={styles.tabcontainer}>
            <Tabs values={tabList} onChange={(e) => setTabValue(e.tabIndex)} selectedIndex={tabValue} />
          </div>
        </div>
        {tabValue === 0 ? (
          <AssignTableAll workspaceId={workspaceId} setTabValue={setTabValue} userAssigned={userAssigned} setSearchTerm={setSearchTerm} />
        ) : tabValue === 1 ? (
          <AssignedTable
            workspaceId={workspaceId}
            assignedData={assignedData}
            setAssignedData={setAssignedData}
            setTabValue={setTabValue}
            userAssigned={userAssigned}
            setSearchTerm={setSearchTerm}
          />
        ) : (
          <UnAssignedTable
            workspaceId={workspaceId}
            assignedData={assignedData}
            setAssignedData={setAssignedData}
            setTabValue={setTabValue}
            userAssigned={userAssigned}
            setSearchTerm={setSearchTerm}
          />
        )}
      </div>
    </div>
  );
};
export default WorkspaceAssignModal;
