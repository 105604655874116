import styles from "./style/Dashboard.module.scss";
import InnerLayout from "../Layout/InnerLayout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPayment,
  selectPayment,
  updatePayment,
} from "../../store/slice/manager/payment";
import { getDetail, selectMenu } from "../../store/slice/manager/menu";

import Tabs from "../Utilities/Tabs";
import Checkbox from "../Utilities/Checkbox";
import EmptyBox from "../Utilities/EmptyBox";

const Payment = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { payment } = useSelector(selectPayment);
  const { detail } = useSelector(selectMenu);

  const [tabValue, setTabValue] = useState(2);
  const tabList = ["企業帳號管理", "方案管理", "付費方式"];

  useEffect(() => {
    dispatch(getPayment(params.id));
    dispatch(getDetail({ service: params.id }));
  }, [dispatch, params.id]);

  const changePayment = (method) => {
    dispatch(updatePayment(method));
  };

  useEffect(() => {
    switch (tabValue) {
      case 0:
        return navigate("/dashboard/business/" + params.id);
      case 1:
        return navigate("/dashboard/plan-management/" + params.id);
      case 2:
        return navigate("/dashboard/payment/" + params.id);
      default:
        return null;
    }
  }, [tabValue, navigate, params.id]);

  return (
    <InnerLayout>
      <div className={styles.dashboard}>
        <div className={styles.dashboardPayment}>
          <div className={styles.dashboardTitle}>{detail?.name}</div>
          <div className={styles.tabsContainer}>
            <Tabs
              values={tabList}
              onChange={(e) => setTabValue(e.tabIndex)}
              selectedIndex={tabValue}
            />
          </div>
          <div className={styles.paymentList}>
            {payment?.length > 0 && (
              <div className={styles.paymentListTitle}>付費方式列表</div>
            )}

            {payment?.length > 0 ? (
              <div className={styles.paymentContainer}>
                {payment?.map((data, index) => (
                  <div key={index} className={styles.paymentListContainer}>
                    <div className={styles.paymentTitle}>{data.name}</div>
                    <Checkbox
                      value={data.enable}
                      onClick={() =>
                        !data.enable ? changePayment(data.id) : null
                      }
                    />
                  </div>
                ))}
              </div>
            ) : (
              <EmptyBox caption={"目前沒有付費方式"} />
            )}
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Payment;
