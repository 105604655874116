import { useState, useEffect, useRef } from "react";
import styles from "./style/Dropdown.module.scss";
import { IoChevronDown } from "react-icons/io5";

const Dropdown = (props) => {
  const {
    value,
    onChange,
    listItems,
    placeholder,
    error,
    noBg,
    textKey,
    borderColor,
  } = props;

  const [listOpen, setListOpen] = useState(false);

  function handleBtnImgClassName(listOpen) {
    let className = `${styles["btn-img"]}`;
    if (listOpen) className += ` ${styles["rotate180"]}`;
    return className;
  }

  function handleListClassName(listOpen) {
    let className = `${styles["dropDownList"]}`;
    if (!listOpen) className += ` ${styles["hidden"]}`;
    return className;
  }

  function handleDropdownErrorClassName(error, noBg) {
    let className = `${styles["dropDown"]}`;
    if (error) className += ` ${styles["dropDownError"]}`;
    if (noBg) className += ` ${styles["dropDownNoBg"]}`;
    return className;
  }

  function useOutsideClose(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setListOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideClose(wrapperRef);

  return (
    <div
      className={handleDropdownErrorClassName(error, noBg)}
      onClick={() => setListOpen(!listOpen)}
      ref={wrapperRef}>
      <span className={value === "" ? styles.nonValueSpan : ""}>
        {value || placeholder}
      </span>
      <div className={handleBtnImgClassName(listOpen)}>
        <IoChevronDown className={styles.dropdownIcon} />
      </div>
      <ul
        className={handleListClassName(listOpen)}
        style={{ borderColor: borderColor ? borderColor : "" }}>
        {listItems.map((item, index) => (
          <li
            key={index}
            style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}
            onClick={() => {
              onChange(item);
              setListOpen(false);
            }}>
            <span className={styles.listItems}>
              {textKey ? item[textKey] : item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
