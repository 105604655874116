import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Style
import styles from './style/DashboardOverview.module.scss';

// Component
import InnerLayout from '../Layout/InnerLayout';

// Utility
import Button from '../Utilities/Button';
import Dropdown from '../Utilities/DropdownDynamic';
import Table from '../Utilities/Table';
import DonutChart from '../Utilities/DonutChart';
import Skeleton from 'react-loading-skeleton';

// Icon
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

// Image
import noData from '../TdpDashboard/img/No Data.png';
import enterprise01 from '../TdpDashboard/img/追求期.png';
import enterprise02 from '../TdpDashboard/img/嬰兒期.png';
import enterprise03 from '../TdpDashboard/img/起步期.png';
import enterprise04 from '../TdpDashboard/img/青春發展期.png';
import enterprise05 from '../TdpDashboard/img/壯年期.png';
import enterprise06 from '../TdpDashboard/img/穩定期.png';
import enterprise07 from '../TdpDashboard/img/貴族期.png';
import enterprise08 from '../TdpDashboard/img/官僚早期.png';
import enterprise09 from '../TdpDashboard/img/官僚期.png';
import enterprise10 from '../TdpDashboard/img/死亡.png';

import { getDashboardEnterprise, selectDashboard } from '../../store/slice/tdp/dashboard';
import { filterIdProducer, getQuarter } from '../../utils';
import Tips from '../Utilities/Tips';

const DashboardOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { detail, loaded } = useSelector(selectDashboard);

  const enterprise = [
    { id: 1, title: '追求期', img: enterprise01 },
    { id: 2, title: '嬰兒期', img: enterprise02 },
    { id: 3, title: '起步期', img: enterprise03 },
    { id: 4, title: '青春發展期', img: enterprise04 },
    { id: 5, title: '壯年期', img: enterprise05 },
    { id: 6, title: '穩定期', img: enterprise06 },
    { id: 7, title: '貴族期', img: enterprise07 },
    { id: 8, title: '官僚早期', img: enterprise08 },
    { id: 9, title: '官僚期', img: enterprise09 },
    { id: 10, title: '死亡', img: enterprise10 },
  ];

  // States
  const [yearDropdownItem, setYearDropdownItem] = useState([]);
  const [seasonDropdownItem] = useState(['Q1', 'Q2', 'Q3', 'Q4']);
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [seasonValue, setSeasonValue] = useState('Q1');
  const [goalChartData, setGoalChartData] = useState([]);
  const [submitChartData, setSubmitChartData] = useState([]);
  const [collaborativeChartData, setCollaborativeChartData] = useState([]);
  const [init, setInit] = useState(false);

  //tableHeader
  const tableHeader = [
    {
      title: '項目',
      value: '',
      width: '82.5',
    },
    {
      title: '狀態',
      value: '',
      width: '17.5',
    },
  ];

  useEffect(() => {
    const year = new Date().getFullYear();
    const array = [];
    array.push(year, year - 1, year - 2);
    setYearDropdownItem(array);
    setSeasonValue(`Q${getQuarter(new Date())}`);
  }, []);

  useEffect(() => {
    if (params && !init) {
      const postObj = {
        enterprise_id: params.id,
        period_id: filterIdProducer(yearValue, seasonValue),
      };
      dispatch(getDashboardEnterprise(postObj));
      setInit(true);
    }
  }, [dispatch, params, init, yearValue, seasonValue]);

  const handleYearDropdown = (e) => {
    setInit(false);
    setYearValue(e);
  };
  const handleSeasonDropdown = (e) => {
    setInit(false);
    setSeasonValue(e);
  };

  // Doughnut Chart
  useEffect(() => {
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];

    if (detail) {
      arr1.push(
        {
          value: detail.usage_overview.complete_ratio.goal_setting.ratio,
          dimension: '',
        },
        {
          value: 100 - detail.usage_overview.complete_ratio.goal_setting.ratio,
          dimension: '',
        }
      );
      arr2.push(
        {
          value: detail.usage_overview.complete_ratio.submit.ratio,
          dimension: '',
        },
        {
          value: 100 - detail.usage_overview.complete_ratio.submit.ratio,
          dimension: '',
        }
      );
      arr3.push(
        {
          value: detail.usage_overview.complete_ratio.collaborative_score.ratio,
          dimension: '',
        },
        {
          value: 100 - detail.usage_overview.complete_ratio.collaborative_score.ratio,
          dimension: '',
        }
      );
      setGoalChartData(arr1);
      setSubmitChartData(arr2);
      setCollaborativeChartData(arr3);
    }
  }, [detail]);

  return (
    <InnerLayout>
      <div className={styles.overviewOuter}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            {loaded && (
              <div className={styles.loadingOuter}>
                <Skeleton height={40} />
              </div>
            )}
            {!loaded && detail?.enterprise_information?.activate_status === 0 && <div className={styles.statusBtn}>已停用</div>}
            {!loaded && <div className={styles.headerTitle}>{detail?.enterprise_information?.enterprise_name}</div>}
          </div>
          <Button
            title={'返回總覽'}
            icon={<MdKeyboardArrowLeft />}
            type='tertiary'
            iconPosition='before'
            onClick={() => navigate('/dashboard/tdp/menu')}
          />
        </div>
        <div className={styles.subHeader}>
          <div className={styles.drodownOuter}>
            <Dropdown listItems={yearDropdownItem} value={yearValue ? yearValue : yearDropdownItem[0]} onChange={(e) => handleYearDropdown(e)} />
          </div>
          <div className={styles.drodownOuter}>
            <Dropdown
              listItems={seasonDropdownItem}
              value={seasonValue ? seasonValue : seasonDropdownItem[0]}
              onChange={(e) => handleSeasonDropdown(e)}
            />
          </div>
        </div>

        {detail && (
          <div className={styles.body}>
            <div className={styles.bodyLeft}>
              <div className={styles.overview}>
                <div className={styles.overviewTitle}>企業概況</div>
                <div className={styles.overviewContent}>
                  <div className={styles.contentLeft}>
                    <div className={styles.lifecycle}>
                      <div className={styles.title}>企業生命週期</div>
                      <div className={styles.cycleName}>{detail?.enterprise_overview?.enterprise_life_cycle}</div>
                      <div className={styles.cycleImg}>
                        <img
                          src={
                            detail?.enterprise_overview?.enterprise_life_cycle
                              ? enterprise?.find((item) => detail?.enterprise_overview?.enterprise_life_cycle?.includes(item?.title))?.img
                              : noData
                          }
                          alt=''
                        />
                      </div>
                    </div>
                    <div className={styles.department}>
                      <div className={styles.title}>部門數</div>
                      {loaded ? (
                        <Skeleton height={30} width={70} />
                      ) : (
                        <div className={styles.count}>{detail?.enterprise_overview.departments_count}</div>
                      )}
                    </div>
                  </div>
                  <div className={styles.contentRight}>
                    <div className={styles.management}>
                      <div className={styles.title}>經營層人數</div>
                      {loaded ? <Skeleton height={30} width={70} /> : <div className={styles.count}>{detail?.enterprise_overview.management}</div>}
                    </div>
                    <div className={styles.manager}>
                      <div className={styles.title}>主管層人數</div>
                      {loaded ? <Skeleton height={30} width={70} /> : <div className={styles.count}>{detail?.enterprise_overview.supervisor}</div>}
                    </div>
                    <div className={styles.employee}>
                      <div className={styles.title}>員工層人數</div>
                      {loaded ? (
                        <div width={'70%'}>
                          <Skeleton height={30} width={70} />
                        </div>
                      ) : (
                        <div className={styles.count}>{detail?.enterprise_overview.employee}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.evaluation}>
                <div className={styles.evaluationTitle}>測評設定狀況</div>
                <Table header={tableHeader} type='condensed'>
                  <tbody>
                    <tr>
                      <td>
                        PAEI測評頻率{' '}
                        <span className={styles.numbering}>{detail?.evaluation_setting?.count ? `${detail?.evaluation_setting?.count}位` : ''}</span>
                      </td>
                      <td
                        style={{
                          fontSize: '20px',
                          color: detail.evaluation_setting?.paei_evaluation ? 'rgba(52, 199, 89, 1)' : 'rgba(255, 59, 48, 1)',
                        }}
                      >
                        {loaded ? (
                          <Skeleton height={24} width={30} />
                        ) : (
                          <Tips detail={detail.evaluation_setting?.paei_evaluation ? '已設定完成' : '尚未設定完成'} leftDiff={45} topDiff={-8}>
                            {detail.evaluation_setting?.paei_evaluation ? (
                              <BiCheckCircle className={styles.icon} />
                            ) : (
                              <BiXCircle className={styles.icon} />
                            )}
                          </Tips>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        企業生命週期測評頻率{' '}
                        <span className={styles.numbering}>{detail?.evaluation_setting?.count ? `${detail?.evaluation_setting?.count}位` : ''}</span>
                      </td>
                      <td
                        style={{
                          fontSize: '20px',
                          color: detail.evaluation_setting?.enterprise_cycle_evaluation ? 'rgba(52, 199, 89, 1)' : 'rgba(255, 59, 48, 1)',
                        }}
                      >
                        {loaded ? (
                          <Skeleton height={24} width={30} />
                        ) : (
                          <Tips
                            detail={detail.evaluation_setting?.enterprise_cycle_evaluation ? '已設定完成' : '尚未設定完成'}
                            leftDiff={45}
                            topDiff={-8}
                          >
                            {detail.evaluation_setting?.enterprise_cycle_evaluation ? (
                              <BiCheckCircle className={styles.icon} />
                            ) : (
                              <BiXCircle className={styles.icon} />
                            )}
                          </Tips>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        企業生命週期測評人員數量
                        <span className={styles.numbering}>
                          {detail?.evaluation_setting?.enterprise_cycle_evaluation_people?.count
                            ? `${detail?.evaluation_setting?.enterprise_cycle_evaluation_people?.count}位`
                            : ''}
                        </span>
                      </td>

                      <td
                        style={{
                          fontSize: '20px',
                          color: detail.evaluation_setting?.enterprise_cycle_evaluation_people?.status
                            ? 'rgba(52, 199, 89, 1)'
                            : 'rgba(255, 59, 48, 1)',
                        }}
                      >
                        {loaded ? (
                          <Skeleton height={24} width={30} />
                        ) : (
                          <Tips
                            detail={detail.evaluation_setting?.enterprise_cycle_evaluation_people?.status ? '已設定完成' : '尚未設定完成'}
                            leftDiff={45}
                            topDiff={-8}
                          >
                            {detail.evaluation_setting?.enterprise_cycle_evaluation_people?.status ? (
                              <BiCheckCircle className={styles.icon} />
                            ) : (
                              <BiXCircle className={styles.icon} />
                            )}
                          </Tips>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className={styles.bodyRight}>
              <div className={styles.usageCondition}>
                <div className={styles.usageConditionTitle}>使用狀況追蹤</div>
                <div className={styles.usageContent}>
                  <div className={styles.usageCount}>
                    <div className={styles.usageCountTitle}>各項追蹤數量</div>
                    <div className={styles.strategyOuter}>
                      <div className={styles.card}>
                        <div className={styles.strategyTtitle}>企業目標與策略數量</div>
                        {loaded ? (
                          <Skeleton height={30} width={70} />
                        ) : (
                          <div className={styles.strategyCount}>{detail?.usage_overview.strategy.enterprise}</div>
                        )}
                      </div>
                      <div className={styles.card}>
                        <div className={styles.strategyTtitle}>團隊目標數量</div>
                        {loaded ? (
                          <Skeleton height={30} width={70} />
                        ) : (
                          <div className={styles.strategyCount}>{detail?.usage_overview.strategy.department}</div>
                        )}
                      </div>
                      <div className={styles.card}>
                        <div className={styles.strategyTtitle}>行動計畫數量</div>
                        {loaded ? (
                          <Skeleton height={30} width={70} />
                        ) : (
                          <div className={styles.strategyCount}>{detail?.usage_overview.strategy.plan}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.completeRatio}>
                    <div className={styles.completeRatioTitle}>行動計畫完成比例</div>
                    <div className={styles.ratioCardOuter}>
                      <div className={styles.completeCard}>
                        <div className={styles.title}>成長目標設定完成度</div>
                        <div className={styles.donut}>
                          {goalChartData.length > 0 && (
                            <DonutChart
                              data={goalChartData}
                              background={['#8F96D5', '#DDDDDD']}
                              borderWidth={0}
                              legendDisplay={false}
                              tooltipEnabled={false}
                            />
                          )}
                          <div className={styles.percentage}>{Math.round(detail?.usage_overview.complete_ratio.goal_setting.ratio)}%</div>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th width='10%'></th>
                              <th width='50%'>項目</th>
                              <th width='40%'>數量</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className={styles.dot} style={{ background: '#8F96D5' }}></span>
                              </td>
                              <td>
                                <span>已完成</span>
                              </td>
                              <td>
                                {loaded ? (
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Skeleton height={20} width={30} />
                                  </div>
                                ) : (
                                  <span>{detail?.usage_overview.complete_ratio.goal_setting.complete}</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className={styles.dot} style={{ background: '#D9D9D9' }}></span>
                              </td>
                              <td>
                                <span>未完成</span>
                              </td>
                              <td>
                                {loaded ? (
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Skeleton height={20} width={30} />
                                  </div>
                                ) : (
                                  <span>{detail?.usage_overview.complete_ratio.goal_setting.incomplete}</span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className={styles.completeCard}>
                        <div className={styles.title}>成果繳交完成度</div>
                        <div className={styles.donut}>
                          {submitChartData.length > 0 && (
                            <DonutChart
                              data={submitChartData}
                              background={['#8F96D5', '#DDDDDD']}
                              borderWidth={0}
                              legendDisplay={false}
                              tooltipEnabled={false}
                            />
                          )}
                          <div className={styles.percentage}>{Math.round(detail?.usage_overview.complete_ratio.submit.ratio)}%</div>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th width='10%'></th>
                              <th width='50%'>項目</th>
                              <th width='40%'>數量</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <span className={styles.dot} style={{ background: '#8F96D5' }}></span>
                              </td>
                              <td>
                                <span>已完成</span>
                              </td>
                              <td>
                                {loaded ? (
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Skeleton height={20} width={30} />
                                  </div>
                                ) : (
                                  <span>{detail?.usage_overview.complete_ratio.submit.complete}</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className={styles.dot} style={{ background: '#D9D9D9' }}></span>
                              </td>
                              <td>
                                <span>未完成</span>
                              </td>
                              <td>
                                {loaded ? (
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Skeleton height={20} width={30} />
                                  </div>
                                ) : (
                                  <span>{detail?.usage_overview.complete_ratio.submit.incomplete}</span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className={styles.completeCard}>
                        <div className={styles.title}>協作評分完成度</div>
                        <div className={styles.donut}>
                          {collaborativeChartData.length > 0 && (
                            <DonutChart
                              data={collaborativeChartData}
                              background={['#8F96D5', '#DDDDDD']}
                              borderWidth={0}
                              legendDisplay={false}
                              tooltipEnabled={false}
                            />
                          )}
                          <div className={styles.percentage}>{Math.round(detail?.usage_overview.complete_ratio.collaborative_score.ratio)}%</div>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th width='10%'></th>
                              <th width='50%'>項目</th>
                              <th width='40%'>數量</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className={styles.dot} style={{ background: '#8F96D5' }}></span>
                              </td>
                              <td>
                                <span>已完成</span>
                              </td>
                              <td>
                                {loaded ? (
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Skeleton height={20} width={30} />
                                  </div>
                                ) : (
                                  <span>{detail?.usage_overview.complete_ratio.collaborative_score.complete}</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className={styles.dot} style={{ background: '#D9D9D9' }}></span>
                              </td>
                              <td>
                                <span>未完成</span>
                              </td>
                              <td>
                                {loaded ? (
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Skeleton height={20} width={30} />
                                  </div>
                                ) : (
                                  <span>{detail?.usage_overview.complete_ratio.collaborative_score.incomplete}</span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </InnerLayout>
  );
};
export default DashboardOverview;
