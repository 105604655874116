import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Link, useNavigate } from 'react-router-dom';

import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { CheckMarkCircle } from '../../icons';
import styles from './style/Auth.module.scss';

import { emailRegex } from '../../utils';

import Logo from '../../assets/Logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, selectUser } from '../../store/slice/user';
import { ClipLoader } from 'react-spinners';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const { loading, message, success } = useSelector(selectUser);
  const dispatch = useDispatch();

  const [isEmailExist, setIsEmailExist] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [stepCount, setStepCount] = useState(1);
  const [isFormFail, setIsFormFail] = useState(false);
  const [init, setInit] = useState(true);

  const onSubmit = (data) => {
    const { email } = data;
    dispatch(forgotPassword({ email: email, type: 'center' }));
  };

  useEffect(() => {
    if (loading) {
      setIsPending(true);
    }
    if (success) {
      setIsEmailExist(true);
      setStepCount(2);
    } else if (message) {
      setIsEmailExist(false);
      setIsFormFail(true);
      setStepCount(1);
    } else {
      setStepCount(1);
    }
  }, [loading, success, message, isPending]);

  useEffect(() => {
    if (init) {
      setStepCount(1);
    }
    setInit(false);
  }, [init, stepCount]);

  return (
    <div className={styles.authBox}>
      <div className={styles.logoContainer}>
        <img src={Logo} alt='Logo' styles={{ boxShadow: '0px 4px 56px #FFFFFF', background: '#FFFFFF' }} />
      </div>
      <div className={styles.formBox}>
        <div className={styles.formContainer}>
          <div>
            <div className={styles.backLinkContent}>
              <MdOutlineKeyboardArrowLeft />
              <Link to='/login'> 返回登入</Link>
            </div>
            <div className={styles.titleBottom}>忘記密碼</div>

            {/* Stepper */}
            <div className={styles.stepper}>
              <div className={styles.stepperLine} />
              <div className={stepCount === 1 ? styles.stepperActivationCircle : stepCount > 1 ? styles.stepperFinishedCircle : styles.stepperCircle}>
                1
              </div>

              <div className={stepCount === 2 ? styles.stepperActivationCircle : styles.stepperCircle}>2</div>
            </div>

            {stepCount === 1 && (
              <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formTitle}>請填寫帳戶的資訊</div>
                <input
                  placeholder='請輸入電子信箱'
                  {...register('email', {
                    onChange: () => {
                      clearErrors();
                      setIsFormFail(false);
                      setIsEmailExist(true);
                    },
                    required: '請輸入電子信箱',
                    pattern: {
                      value: emailRegex,
                      message: '請輸入正確格式',
                    },
                  })}
                  className={(errors.email && isFormFail) || !isEmailExist ? styles.errorInput : styles.nonErrorInput}
                  disabled={loading}
                />

                {/* {errors.email && isFormFail && <p>{errors.email?.message}</p>} */}
                {!isEmailExist && <p>{message && message === '重置信件已寄送，請稍候再試' ? '重置信件已寄送，請稍候再試' : '此信箱不存在'}</p>}
                <div className={styles.submitContent}>
                  {loading ? (
                    <button className={`${styles.btnDefault} ${styles.btnIcon}`}>
                      <ClipLoader color='#FFFFFF' size={15} />
                      寄出認證信
                    </button>
                  ) : (
                    <button className={styles.btnDefault} type='submit' onClick={() => setIsFormFail(true)}>
                      寄出認證信
                    </button>
                  )}
                </div>
              </form>
            )}
            {stepCount === 2 && (
              <div className={styles.confirmContent}>
                <CheckMarkCircle className={styles.checkMarkIcon} />
                <h2>
                  認證信已發送完成，
                  <br />
                  請至信箱收取信件進行後續密碼設定。
                </h2>
                <button
                  className={styles.btnDefault}
                  onClick={() => {
                    setStepCount(1);
                    navigate('/login');
                  }}
                >
                  返回登入頁
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
