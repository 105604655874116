import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useCookies } from 'react-cookie';

const OuterLayout = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['token']);

  useEffect(() => {
    const token = cookies.token;
    const userData = JSON.parse(localStorage.getItem('UserData'));

    if (!!token && !!userData) {
      if (userData?.roles?.find((item) => item === 'workspace.tdp')) {
        navigate('/dashboard/tdp/menu');
      } else {
        navigate('/admin/workspace');
      }
    }
  }, [cookies, navigate]);

  return (
    // <GoogleReCaptchaProvider reCaptchaKey={'6LdrKKgeAAAAAKl-TCWdCv2ZhbAd1b91Nu0gAWRx'}>
    <Outlet />
    // </GoogleReCaptchaProvider>
  );
};

export default OuterLayout;
