import './style/main.scss';
import { Provider } from 'react-redux';
import store from './store';
import AuthPlatform from './AuthPlatform';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

const App = () => {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <AuthPlatform />
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  );
};

export default App;
