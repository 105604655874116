import styles from "./style/Dashboard.module.scss";
import InnerLayout from "../Layout/InnerLayout";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdExplore, MdPreview, MdClear, MdHelp } from "react-icons/md";
import { format } from "date-fns";

import {
  activatePlanPackage,
  deletePlanPackage,
  getPlanPackagesNew,
  getPlanPackagesPublishing,
  publishPlanPackage,
  selectPlans,
  removeSuccess,
  removePublishError,
} from "../../store/slice/manager/plans";
import { getDetail, selectMenu } from "../../store/slice/manager/menu";
import { selectPermissionSettings } from "../../store/slice/permissionSettings";

import { BiTrashAlt } from "react-icons/bi";
import { RiCalendarEventFill } from "react-icons/ri";
import moment from "moment";

import Toast from "../Utilities/Toast";
import Tabs from "../Utilities/Tabs";
import Button from "../Utilities/Button";
import Alert from "../Utilities/Alert";
import ModalCreatePlan from "./ModalCreatePlan";
import InputSearch from "../Utilities/InputSearch";
import Pagination from "../Utilities/PaginationNew";
// import MonthlyCalendar from "../Utilities/MonthlyCalender";
import Tips from "../Utilities/Tips";
import EmptyBox from "../Utilities/EmptyBox";
import { ClipLoader } from "react-spinners";

import { toChineseNumber } from "../../utils";
import Datepicker from "../Utilities/Datepicker/Datepicker";

const PlanManagement = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { detail } = useSelector(selectMenu);
  const { auth, permissions, authLoading } = useSelector(
    selectPermissionSettings
  );
  const {
    packages,
    publish,
    loading,
    refresh,
    publishError,
    publishingPackages,
  } = useSelector(selectPlans);

  const [toastValue, setToastValue] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [fetchSearchValue, setFetchSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const pageLimit = 15;
  const [tabValue, setTabValue] = useState(1);
  const [index, setIndex] = useState(null);

  const [createPlan, setCreatePlan] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [calendarShow, setCalendarShow] = useState(false);
  const [reserveIndex, setReserveIndex] = useState(null);

  const [id, setId] = useState(null);
  const [removeId, setRemoveId] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [status, setStatus] = useState("");
  const [init, setInit] = useState(true);
  const [planDate, setPlanDate] = useState({ startDay: "", endDay: "" });
  const [errorDate, setErrorDate] = useState(false);
  const [isTipShow, setIsTipShow] = useState("");

  const tabList = ["企業帳號管理", "方案管理", "付費方式"];

  const handleStartDay = (date, startDay) => {
    const day = new Date(date);
    return format(
      day.setDate(day.getDate() - (startDay ? 1 : 0)),
      "yyyy-MM-dd"
    );
  };

  useEffect(() => {
    dispatch(getDetail({ service: params?.id, group_plans: 1 }));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      (init || refresh || publish || publishError || fetchSearchValue) &&
      permissions?.find(
        (item) =>
          item.includes(detail?.sys_alias) &&
          item.includes("package.view_package")
      )
    ) {
      dispatch(
        getPlanPackagesNew({
          service: params.id,
          publishing: 0,
          limit: pageLimit,
          page: page + 1,
          search: fetchSearchValue,
        })
      );
      dispatch(getPlanPackagesPublishing({ service: params.id }));
      setInit(false);

      if (refresh) setTimeout(() => dispatch(removeSuccess()), 1200);
      if (publish) setTimeout(() => dispatch(removeSuccess()), 1200);
      if (publishError) {
        setTimeout(() => {
          dispatch(removePublishError());
          setStatus("");
        }, 1500);
      }
    }
  }, [
    dispatch,
    page,
    params.id,
    refresh,
    publish,
    publishError,
    fetchSearchValue,
    permissions,
    detail?.sys_alias,
    init,
  ]);

  const handleChangePage = (e) => {
    setPage(e.selected);
    dispatch(
      getPlanPackagesNew({
        service: params.id,
        publishing: 0,
        limit: pageLimit,
        page: e.selected + 1,
        search: fetchSearchValue,
      })
    );
  };

  const handleSuccess = () => {
    dispatch(activatePlanPackage(id)).then((e) => {
      if (e.payload.msg === "success") {
        restart();
      }
    });
    setId(null);
  };

  const handleRemoveSuccess = () => {
    dispatch(deletePlanPackage(removeId)).then((e) => {
      if (e.payload.msg === "success") {
        restart();
      }
    });
    setRemoveId(null);
  };

  const handleCancelSuccess = () => {
    const postObj = {
      id: reserveIndex,
      publish_month: null,
    };
    dispatch(publishPlanPackage(postObj)).then((e) => {
      if (e.payload.msg === "success") {
        restart();
      }
    });

    setCancelId(null);
  };

  useEffect(() => {
    switch (tabValue) {
      case 0:
        return navigate("/dashboard/business/" + params.id);
      case 1:
        return navigate("/dashboard/plan-management/" + params.id);
      case 2:
        return navigate("/dashboard/payment/" + params.id);
      default:
        return null;
    }
  }, [tabValue, navigate, params.id]);

  const restart = () => {
    setFetchSearchValue("");
    let parameters = {
      service: params.id,
      publishing: 0,
      limit: pageLimit,
      page: page + 1,
      search: "",
    };
    dispatch(getPlanPackagesNew(parameters));
  };

  const handleConfirm = (date) => {
    setCalendarShow(false);
    const postObj = {
      id: reserveIndex,
      publish_date: handleStartDay(planDate.startDay),
      publish_end_date: null, //handleStartDay(planDate.endDay),
      // publish_month: moment(date).format("YYYY-MM"),
    };
    dispatch(publishPlanPackage(postObj)).then((e) => {
      if (e.payload.msg === "success") {
        restart();
        setStartDate(date);
      }
    });
  };

  return (
    <InnerLayout>
      <Toast
        title={
          status === "create"
            ? "新增方案組合成功"
            : status === "delete"
            ? "刪除方案組合成功"
            : status === "publish" && !publishError
            ? `預計於「${moment(startDate).format("YYYY-MM")}號啟用」`
            : status === "publishCancel"
            ? `取消預約成功`
            : status === "publish" && publishError
            ? "此月份已有其他預約方案"
            : ""
        }
        subtitle={toastValue ? toastValue : ""}
        show={refresh || publish || publishError}
        error={false}
      />
      <Alert
        isOpen={id}
        onConfirm={handleSuccess}
        onClose={() => {
          setId(null);
        }}
        rightButtonText={"啟用"}
        leftButtonText='取消'
      />
      <Alert
        mainTitle={"清空所有設定?"}
        subTitle={"系統將會清空所有設定"}
        isOpen={removeId}
        onConfirm={handleRemoveSuccess}
        onClose={() => {
          setRemoveId(null);
        }}
        rightButtonText='清空'
        leftButtonText='取消'
      />
      <Alert
        mainTitle={"取消預約?"}
        subTitle={"系統將會取消預約"}
        isOpen={cancelId}
        onConfirm={handleCancelSuccess}
        onClose={() => {
          setCancelId(null);
        }}
        rightButtonText={"確認"}
        leftButtonText='取消'
      />

      <div className={styles.dashboard}>
        <div className={styles.dashboardPlanManagement}>
          <div className={styles.dashboardTitle}>{detail?.name}</div>
          <div className={styles.tabsContainer}>
            <Tabs
              values={tabList}
              onChange={(e) => setTabValue(e.tabIndex)}
              selectedIndex={tabValue}
            />
          </div>

          {authLoading ? (
            <div className={styles.loadingWrapper}>
              <ClipLoader />
            </div>
          ) : (
            <>
              {permissions?.find((item) => item.includes("package")) ? (
                <>
                  {/* 啟用中方案組合 */}
                  {publishingPackages?.packages?.filter(
                    (item) => item.publishing
                  ).length > 0 ? (
                    <div className={styles.isEnabledPlan}>
                      <div className={styles.enabledHeader}>
                        <div className={styles.enableShow}>目前啟用中</div>
                        <div className={styles.titleBox}>
                          <div className={styles.title}>
                            {
                              publishingPackages?.packages?.find(
                                (item) => item.publishing
                              ).name
                            }
                          </div>
                          <div className={styles.date}>
                            啟用日：
                            <span>
                              {moment(
                                publishingPackages?.packages?.find(
                                  (item) => item.publishing
                                ).publish_at
                              ).format("YYYY/MM/DD")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.enableBody}>
                        <div className={styles.planOuter}>
                          {/* {detail?.avaliable_plans?.length > 0 && ( */}
                          <>
                            {publishingPackages?.packages
                              ?.find((item) => item.publishing)
                              .plans?.filter((item) => !item.is_trial)
                              .map((plan, idx) => (
                                <div
                                  className={styles.enablePlan}
                                  key={`enableplan${idx}`}>
                                  <div className={styles.planCount}>
                                    方案{toChineseNumber(idx + 1)}
                                  </div>
                                  <div className={styles.name}>{plan.name}</div>
                                  <div className={styles.bundledBox}>
                                    {
                                      <React.Fragment key={index}>
                                        <div
                                          className={`${styles.bundledOuter} ${styles.plan}`}>
                                          <div
                                            className={styles.bundledOuterTop}>
                                            <div
                                              className={styles.bundleBox}
                                              style={{ width: "110px" }}>
                                              <div
                                                className={styles.days}
                                                style={{
                                                  width: "max-content",
                                                }}>
                                                綁約 {plan.validity_days} 天
                                              </div>
                                            </div>
                                            <div
                                              className={styles.bundleBox}
                                              style={{ width: "128px" }}>
                                              <div className={styles.title}>
                                                基本人數
                                              </div>
                                              <div className={styles.fee}>
                                                {plan.users_count_upper_limit}
                                              </div>
                                            </div>
                                            <div
                                              className={styles.bundleBox}
                                              style={{ width: "145px" }}>
                                              <div className={styles.title}>
                                                增加帳號數級距
                                              </div>
                                              <div className={styles.fee}>
                                                {
                                                  plan.additional_people_per_unit
                                                }
                                              </div>
                                            </div>
                                            <div className={styles.bundleBox} />
                                          </div>
                                          <div
                                            className={
                                              styles.bundledOuterBottom
                                            }>
                                            <div style={{ width: "110px" }} />
                                            <div
                                              className={styles.bundleBox}
                                              style={{ width: "128px" }}>
                                              <div className={styles.title}>
                                                基本費用 (元)
                                              </div>
                                              <div className={styles.fee}>
                                                NT${plan.rental_fee}
                                              </div>
                                            </div>
                                            <div
                                              className={styles.bundleBox}
                                              style={{ width: "145px" }}>
                                              <div className={styles.title}>
                                                折扣 (%)
                                              </div>
                                              <div className={styles.discount}>
                                                {100 - plan.discount}%
                                              </div>
                                            </div>
                                            <div className={styles.bundleBox} />
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    }
                                  </div>
                                </div>
                              ))}
                          </>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.planEnable}>
                      <div className={styles.nonEnableContainer}>
                        <div className={styles.nonEnale}>
                          目前無啟用中的方案組合
                        </div>
                        <div className={styles.nonEnableAlert}>
                          請從未啟用方案組合列表中選擇並立即啟用
                        </div>
                      </div>
                    </div>
                  )}
                  {/* 未啟用方案組合 */}
                  <div className={styles.nonStartPlan}>
                    <div className={styles.nonStartPlanTitle}>
                      未啟用的方案組合
                    </div>
                    <div className={styles.nonStartFlex}>
                      <div className={styles.searchBar}>
                        <InputSearch
                          disabled={
                            fetchSearchValue === "" &&
                            packages?.rows?.length === 0
                          }
                          placeholder='搜尋方案組合名稱'
                          value={searchTerm}
                          onKeyPress={(e) =>
                            (e.code === "Enter" || e.charCode === 13) &&
                            !loading &&
                            setFetchSearchValue(searchTerm)
                          }
                          onChange={(e) => setSearchTerm(e.target.value)}
                          clickSearchIcon={() => {
                            if (!loading) {
                              setFetchSearchValue(searchTerm);
                              setPage(0);
                            }
                          }}
                          onClear={() => {
                            restart();
                            setSearchTerm("");
                          }}
                          width={"220px"}
                        />
                      </div>

                      <Button
                        type='tertiary'
                        title={"新增方案組合"}
                        onClick={() => {
                          setCalendarShow(false);
                          setCreatePlan(true);
                        }}
                        // 判斷是否有新增方案的權限
                        disabled={
                          auth?.workspace_permissions?.length > 0 &&
                          !permissions?.find(
                            (item) =>
                              item.includes(detail?.sys_alias) &&
                              item.includes("package.create_package")
                          )
                        }
                      />
                    </div>
                  </div>
                  {createPlan && (
                    <ModalCreatePlan
                      createPlan={createPlan}
                      setCreatePlan={setCreatePlan}
                      setStatus={setStatus}
                      setToastValue={setToastValue}
                      restart={restart}
                    />
                  )}
                  {/* 方案table */}
                  <div className={styles.tableContainer}>
                    <table>
                      <thead>
                        <tr>
                          <th className={styles.reserve}>預約啟用日期</th>
                          <th className={styles.name}>方案組合名稱</th>
                          <th className={styles.time}>建立時間</th>
                          <th className='text-left'>方案數量</th>
                          <th className={`${styles.icons}`} colSpan={2}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {packages?.packages?.filter((item) => !item.publishing)
                          .length > 0 ? (
                          packages?.packages
                            ?.filter((item) => !item.publishing)
                            ?.filter((_, index) => {
                              const packagesLength = packages?.packages?.filter(
                                (item) => !item.publishing
                              ).length;
                              if (packagesLength < pageLimit + 1) {
                                return true;
                              } else if (packagesLength > pageLimit) {
                                return index > packagesLength - pageLimit;
                              } else {
                                return false;
                              }
                            })
                            .map((data, i) => (
                              <tr key={i}>
                                <td
                                  className={`${styles.tableTd} text-center`}
                                  onClick={() => setReserveIndex(data.id)}>
                                  {data.publish_at &&
                                  moment(data.publish_at) >
                                    moment(new Date()) ? (
                                    <div className={styles.btnFlex}>
                                      <div className={styles.reserveDate}>
                                        {data.publish_at &&
                                          moment(data.publish_at).format(
                                            "YYYY-MM-DD"
                                          )}
                                      </div>
                                      {auth?.workspace_permissions?.length >
                                        0 &&
                                        permissions?.find(
                                          (item) =>
                                            item.includes(detail?.sys_alias) &&
                                            item.includes(
                                              "package.delete_package"
                                            )
                                        ) && (
                                          <Button
                                            type='tertiary'
                                            title={"取消"}
                                            disabled={loading}
                                            onClick={() => {
                                              setCancelId(data.id);
                                              setToastValue(data.name);
                                              setStatus("publishCancel");
                                            }}
                                          />
                                        )}
                                    </div>
                                  ) : (
                                    <div className={styles.reserveWrapper}>
                                      {auth?.workspace_permissions?.length >
                                        0 && (
                                        <>
                                          <Button
                                            icon={
                                              <RiCalendarEventFill
                                                className={styles.reserveDate}
                                              />
                                            }
                                            type='tertiary'
                                            title='預約'
                                            onClick={() => {
                                              setPlanDate({
                                                startDay: "",
                                                endDay: null,
                                              });
                                              setCalendarShow(!calendarShow);
                                              setStartDate("");
                                              setStatus("publish");
                                              setToastValue(data.name);
                                            }}
                                            // 判斷使用者是否有預約的權限
                                            disabled={
                                              loading ||
                                              !permissions?.find(
                                                (item) =>
                                                  item.includes(
                                                    detail?.sys_alias
                                                  ) &&
                                                  item.includes(
                                                    "package.appointment_package"
                                                  )
                                              )
                                            }
                                          />
                                          {calendarShow &&
                                            data.id === reserveIndex && (
                                              <div
                                                className={
                                                  styles.planPeriodLayout
                                                }>
                                                <div
                                                  className={styles.planPeriod}>
                                                  方案時間
                                                  <Datepicker
                                                    selectDate={
                                                      planDate.startDay
                                                    }
                                                    error={errorDate}
                                                    setSelectDate={(e) =>
                                                      setPlanDate({
                                                        ...planDate,
                                                        startDay: e,
                                                      })
                                                    }
                                                    startYear={`${
                                                      handleStartDay(
                                                        new Date()
                                                      ).split("-")[0]
                                                    }`}
                                                    disableEvents={[
                                                      {
                                                        start: `${handleStartDay(
                                                          new Date()
                                                        )}`,
                                                        end: `2100-12-31`,
                                                      },
                                                    ]}
                                                  />
                                                  {/* 至
                                                  <Datepicker
                                                    selectDate={planDate.endDay}
                                                    setSelectDate={(e) =>
                                                      setPlanDate({
                                                        ...planDate,
                                                        endDay: e,
                                                      })
                                                    }
                                                    startYear={`${handleStartDay(new Date()).split("-")[0]}`}
                                                    disableEvents={[
                                                      {
                                                        start: `${
                                                          planDate.startDay !==
                                                          ""
                                                            ? handleStartDay(
                                                                planDate.startDay,
                                                                true
                                                              )
                                                            : handleStartDay(
                                                                new Date()
                                                              )
                                                        }`,
                                                        end: `2100-12-31`,
                                                      },
                                                    ]}
                                                  /> */}
                                                </div>
                                                <div
                                                  className={
                                                    styles.planPeriodButton
                                                  }>
                                                  <Button
                                                    type='secondary'
                                                    title={"取消"}
                                                    onClick={() => {
                                                      setCalendarShow(
                                                        !calendarShow
                                                      );
                                                    }}
                                                  />
                                                  <Button
                                                    type='tertiary'
                                                    title={"完成"}
                                                    onClick={() => {
                                                      if (
                                                        planDate.startDay === ""
                                                      ) {
                                                        setErrorDate(true);
                                                      } else {
                                                        setErrorDate(false);
                                                        handleConfirm();
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          {/* <MonthlyCalendar
                                            calenderShow={
                                              calendarShow &&
                                              data.id === reserveIndex
                                            }
                                            setCalenderShow={setCalendarShow}
                                            handleConfirm={handleConfirm}
                                          /> */}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td className={styles.tableTd}>{data.name}</td>
                                <td className={styles.tableTd}>
                                  {moment(data.created_at).format("YYYY-MM-DD")}
                                </td>
                                <td className={styles.tableTd}>
                                  {data?.plans?.length}
                                </td>

                                <td className={styles.tableTd}>
                                  <div className={styles.iconsCell}>
                                    <Tips detail={"查看方案"}>
                                      <Button
                                        onClick={() => {
                                          setCalendarShow(false);
                                          setIndex(i);
                                        }}
                                        type={"pure"}
                                        icon={<MdPreview />}
                                      />
                                    </Tips>

                                    <Button
                                      onClick={() => {
                                        setToastValue(data.name);
                                        setStatus("delete");
                                        setRemoveId(data.id);
                                      }}
                                      type={"pure"}
                                      icon={<BiTrashAlt />}
                                      disabled={
                                        loading ||
                                        !permissions?.find(
                                          (item) =>
                                            item.includes(detail?.sys_alias) &&
                                            item.includes(
                                              "package.delete_package"
                                            )
                                        )
                                      }
                                    />
                                  </div>

                                  {index === i && (
                                    <div className={styles.planReviewOuter}>
                                      <div className={styles.header}>
                                        <div className={styles.title}>
                                          查看方案
                                        </div>
                                        <Button
                                          onClick={() => {
                                            setIndex();
                                          }}
                                          type='pure'
                                          icon={<MdClear />}
                                        />
                                      </div>
                                      <div className={styles.body}>
                                        <>
                                          {data?.plans?.find(
                                            (plan) => plan.is_trial
                                          ) && (
                                            <div
                                              className={styles.trialPlanOuter}>
                                              <div className={styles.explore}>
                                                <MdExplore />
                                              </div>
                                              <div className={styles.name}>
                                                {
                                                  data?.plans?.find(
                                                    (plan) => plan?.is_trial
                                                  )?.name
                                                }
                                              </div>
                                              <div
                                                className={
                                                  styles.detailsBoxOuter
                                                }>
                                                <div className={styles.detail}>
                                                  <div className={styles.title}>
                                                    試用人數上限
                                                  </div>
                                                  <div className={styles.count}>
                                                    {
                                                      data?.plans?.find(
                                                        (plan) => plan.is_trial
                                                      ).users_count_upper_limit
                                                    }
                                                    <span>人</span>
                                                  </div>
                                                </div>
                                                <div className={styles.detail}>
                                                  <div className={styles.title}>
                                                    試用天數
                                                  </div>
                                                  <div className={styles.count}>
                                                    {
                                                      data?.plans?.find(
                                                        (plan) => plan.is_trial
                                                      ).plans[0]?.validity_days
                                                    }
                                                    <span>天</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {data?.plans
                                            ?.filter((item) => !item.is_trial)
                                            .map((plan, planIndex) => (
                                              <React.Fragment key={planIndex}>
                                                <div
                                                  className={styles.planOuter}
                                                  key={`plan${planIndex}`}>
                                                  <div
                                                    className={
                                                      styles.planCount
                                                    }>
                                                    方案
                                                    {toChineseNumber(
                                                      planIndex + 1
                                                    )}
                                                  </div>
                                                  <div
                                                    className={styles.planName}>
                                                    {plan.name}
                                                  </div>
                                                  <div
                                                    className={styles.tagOuter}>
                                                    <div className={styles.tag}>
                                                      {plan?.users_count_upper_limit
                                                        ? `基本人數 ${plan?.users_count_upper_limit} 人` //`${plan?.users_count_upper_limit}人以下`
                                                        : `${plan?.users_count_lower_limit}人以上`}
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.tag
                                                      }>{`費用(元)每人/綁約時間 NT$ ${plan?.rental_fee}`}</div>
                                                    <div
                                                      className={
                                                        styles.tag
                                                      }>{`增加帳號數級距 ${plan?.additional_people_per_unit} 人`}</div>
                                                    <div
                                                      className={`${styles.tag} ${styles.discount}`}>
                                                      {`折扣 ${
                                                        100 - plan?.discount
                                                      } %`}
                                                      <div
                                                        className={
                                                          styles.helpBtnOuter
                                                        }>
                                                        <Button
                                                          type='pure'
                                                          icon={<MdHelp />}
                                                          onMouseEnter={() =>
                                                            setIsTipShow(
                                                              planIndex
                                                            )
                                                          }
                                                          onMouseLeave={() =>
                                                            setIsTipShow(false)
                                                          }
                                                          color={"#B4B4B4"}
                                                        />
                                                        {isTipShow ===
                                                          planIndex && (
                                                          <div
                                                            className={
                                                              styles.tips
                                                            }>
                                                            <span>
                                                              設定 100%
                                                              ，完全無折扣，
                                                            </span>
                                                            <span>
                                                              設定
                                                              0%，整筆折扣掉，付款金額為0。
                                                            </span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {plan?.plans?.map(
                                                    (item, index) => (
                                                      <>
                                                        <div
                                                          className={
                                                            styles.bundledOuter
                                                          }
                                                          key={`bundledPlan_${index}`}>
                                                          <div
                                                            className={
                                                              styles.days
                                                            }>
                                                            綁約
                                                            {item.validity_days}
                                                            天
                                                          </div>
                                                          <div
                                                            className={
                                                              styles.bundleBox
                                                            }>
                                                            <div
                                                              className={
                                                                styles.title
                                                              }>
                                                              基本費用 (元)
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.fee
                                                              }>
                                                              NT$
                                                              {item.rental_fee}
                                                            </div>
                                                          </div>
                                                          <div
                                                            className={
                                                              styles.bundleBox
                                                            }>
                                                            <div
                                                              className={
                                                                styles.title
                                                              }>
                                                              折扣 (%)
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.discount
                                                              }>
                                                              {item.discount}%
                                                            </div>
                                                          </div>
                                                          <div
                                                            className={
                                                              styles.bundleBox
                                                            }></div>
                                                        </div>
                                                      </>
                                                    )
                                                  )}
                                                </div>
                                              </React.Fragment>
                                            ))}
                                        </>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <EmptyBox
                                caption={
                                  fetchSearchValue
                                    ? "尚無搜尋結果"
                                    : "目前沒有方案組合"
                                }
                                type={1}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Pagination
                      onPageChange={handleChangePage}
                      pageCount={page + 1}
                      totalData={packages?.packages_count}
                      limit={pageLimit}
                      totalPages={Math.ceil(
                        packages?.packages_count / pageLimit
                      )}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.tableContainer}>
                  <EmptyBox caption='您沒有權限' />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </InnerLayout>
  );
};

export default PlanManagement;
