import { useState, useEffect } from "react";
import InnerLayout from "../../Layout/InnerLayout";
import styles from "./style/recommendCorse.module.scss";
import Button from "../../Utilities/Button";
import LoadingTable from "../../Utilities/LoadingTable";
import Alert from "../../Utilities/Alert";

import { BsFillPencilFill } from "react-icons/bs";
import {
  MdOutlineDone,
  MdClose,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectLifeCycle,
  getPaeiRecommendCorse,
  putPaeiRecommendCorse,
  removeLoading,
} from "../../../store/slice/lifeCycle";

import {
  editModeHandler,
  editModeToggleHandler,
  selectLeftNavigate,
} from "../../../store/slice/navigate";

const RecommendCorse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, courseData, success } = useSelector(selectLifeCycle);
  const { navigateToggle } = useSelector(selectLeftNavigate);
  const [courseList, setCourseList] = useState([]);
  const [isEditMode, setIsEditMode] = useState({ title: "", editMode: false });
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState({});

  useEffect(() => {
    dispatch(getPaeiRecommendCorse());
  }, [dispatch]);

  useEffect(() => {
    let newArr = [];
    if (courseData) {
      for (let key of Object.keys(courseData)) {
        newArr.push({ title: key, recommend: courseData[key] });
      }
    }
    setCourseList(newArr);
  }, [loading, courseData]);

  const handleEditCorse = (title) => {
    dispatch(editModeHandler(true));
    setIsEditMode({ title: title, editMode: true });
  };

  const handleEditRecommend = (title, value, index) => {
    let newArr = [...courseList];
    let newObj = { ...newArr[index] };
    newObj.title = title;
    newObj.recommend = value;
    newArr[index] = newObj;
    setCourseList(newArr);
  };

  const handlePostRecommend = (idx) => {
    const newArr = [...courseList];
    const newObj = { ...newArr[idx] };
    dispatch(
      putPaeiRecommendCorse({
        in_case: newObj.title,
        course_description: newObj.recommend,
      })
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(getPaeiRecommendCorse());
      setIsEditMode({ title: "", editMode: false });
      dispatch(editModeHandler(false));
      dispatch(removeLoading());
    }
  }, [dispatch, success]);

  return (
    <InnerLayout>
      <div className={styles.PAEIcontainer}>
        <div className={styles.header}>
          <div className={styles.title}>課程建議</div>
          <div className={styles.scoreSetting}>
            <div className={styles.tick}></div>
            <div className={styles.scoreSettingBtn}>
              <div className={styles.btn}>
                <Button
                  title='返回PAEI'
                  type='tertiary'
                  disabled={isEditMode.editMode}
                  icon={<MdOutlineKeyboardArrowLeft />}
                  onClick={() =>
                    navigate("/dashboard/tdp/PAEI-setting/practical")
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.listTitle}>課程建議列表</div>
          <div className={styles.tableContainer}>
            <table width={"100%"}>
              {loading && <LoadingTable row={12} column={3} />}
              <thead>
                <tr>
                  <th width={"20%"}>PAEI風格</th>
                  <th width={"75%"}>人才雲系統建議課程</th>
                  <th width={"5%"}></th>
                </tr>
              </thead>
              {!loading && (
                <>
                  <tbody>
                    {courseList?.map((item, idx) => (
                      <tr key={item.title}>
                        <td
                          style={{
                            borderBottom:
                              item.recommend && isEditMode.title !== item.title
                                ? "1px solid #DDDDDD"
                                : "0px",
                          }}>
                          <div className={styles.tdTitle}>{item.title}</div>
                        </td>
                        {item.title === isEditMode.title &&
                        isEditMode.editMode === true ? (
                          <td>
                            <div className={styles.tdTitle}>
                              <textarea
                                placeholder='-'
                                value={item.recommend ? item.recommend : ""}
                                onChange={(e) =>
                                  handleEditRecommend(
                                    item.title,
                                    e.target.value,
                                    idx
                                  )
                                }
                              />
                            </div>
                            <div className={styles.iconFlex}>
                              <div
                                className={styles.iconBg}
                                onMouseOver={() =>
                                  setIsMouseOver({
                                    index: idx,
                                    closeBtn: true,
                                    saveBtn: false,
                                  })
                                }
                                onMouseLeave={() =>
                                  setIsMouseOver({
                                    index: idx,
                                    closeBtn: false,
                                    saveBtn: false,
                                  })
                                }>
                                <Button
                                  icon={<MdClose />}
                                  type='quaternary'
                                  color='#999999'
                                  onClick={() => setIsSnackBarOpen(true)}
                                />
                                {isMouseOver.index === idx &&
                                  isMouseOver.closeBtn && (
                                    <div className={styles.hoverStyle}>
                                      取消
                                    </div>
                                  )}
                              </div>
                              <div
                                className={styles.iconBg}
                                onMouseOver={() =>
                                  setIsMouseOver({
                                    index: idx,
                                    closeBtn: false,
                                    saveBtn: true,
                                  })
                                }
                                onMouseLeave={() =>
                                  setIsMouseOver({
                                    index: idx,
                                    closeBtn: false,
                                    saveBtn: false,
                                  })
                                }>
                                <Button
                                  icon={<MdOutlineDone />}
                                  type='quaternary'
                                  color='#34C759'
                                  onClick={() => handlePostRecommend(idx)}
                                  disabled={!item.recommend}
                                />
                                {isMouseOver.index === idx &&
                                  isMouseOver.saveBtn && (
                                    <div className={styles.hoverStyle}>
                                      儲存
                                    </div>
                                  )}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td
                            style={{
                              borderBottom:
                                item.recommend &&
                                isEditMode.title !== item.title
                                  ? "1px solid #DDDDDD"
                                  : "0px",
                            }}>
                            <div className={styles.tdTitle}>
                              {item.recommend ? item.recommend : "-"}
                            </div>
                          </td>
                        )}
                        {item.title !== isEditMode.title &&
                          isEditMode.editMode === true && (
                            <td
                              style={{
                                borderBottom:
                                  item.recommend &&
                                  isEditMode.title !== item.title
                                    ? "1px solid #DDDDDD"
                                    : "0px",
                              }}>
                              <div
                                className={styles.tdIcon}
                                onClick={() => {
                                  if (!isEditMode.editMode) {
                                    handleEditCorse(item.title);
                                  } else {
                                    setIsSnackBarOpen(true);
                                  }
                                }}>
                                <Button
                                  icon={<BsFillPencilFill />}
                                  type='pure'
                                />
                                {!item.recommend && (
                                  <div className={styles.redDot}></div>
                                )}
                              </div>
                            </td>
                          )}
                        {item.title !== isEditMode.title &&
                          isEditMode.editMode === false && (
                            <td
                              style={{
                                borderBottom:
                                  item.recommend &&
                                  isEditMode.title !== item.title
                                    ? "1px solid #DDDDDD"
                                    : "0px",
                              }}>
                              <div
                                className={styles.tdIcon}
                                onClick={() => {
                                  if (!isEditMode.editMode) {
                                    handleEditCorse(item.title);
                                  } else {
                                    setIsSnackBarOpen(true);
                                  }
                                }}>
                                <Button
                                  icon={<BsFillPencilFill />}
                                  type='pure'
                                />
                                {!item.recommend && (
                                  <div className={styles.redDot}></div>
                                )}
                              </div>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
      <Alert
        mainTitle='跳過目前正在編輯的項目嗎？'
        subTitle='系統可能不會儲存你所做的變更'
        leftButtonText='繼續編輯'
        rightButtonText='跳過'
        isOpen={isSnackBarOpen || (isEditMode.editMode && navigateToggle)}
        onClose={() => {
          setIsSnackBarOpen(false);
          dispatch(editModeToggleHandler(false));
        }}
        onConfirm={() => {
          setIsEditMode({ title: "", editMode: false });
          setIsSnackBarOpen(false);
          dispatch(editModeHandler(false));
          dispatch(editModeToggleHandler(false));
          dispatch(getPaeiRecommendCorse());
        }}
      />
    </InnerLayout>
  );
};

export default RecommendCorse;
