import { useState } from 'react';
import InnerLayout from '../Layout/InnerLayout';
import styles from './style/PAEISettings.module.scss';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../Utilities/Button';
import Toast from '../Utilities/Toast';
import Alert from '../Utilities/Alert';
import Modal from '../Utilities/Modal';
import MenuTabs from '../Utilities/MenuTabs';

import Practical from './PAEI/Practical';
import AptitudePreviewModal from './PAEI/AptitudePreviewModal';
import { useEffect } from 'react';

import { selectLifeCycle, getPaeiAptitude, getPaeiRecommendCorse } from '../../store/slice/lifeCycle';

import { editModeHandler, editModeToggleHandler, selectLeftNavigate } from '../../store/slice/navigate';

const PAEISettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { aptitudeData, courseData } = useSelector(selectLifeCycle);
  const { navigateToggle } = useSelector(selectLeftNavigate);

  const [isToastShow, setIsToastShow] = useState({
    paeiSetting: false,
    aptitudeSetting: false,
  });
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAptitdeModalOpen, setIsAptitdeModalOpen] = useState(false);
  const [isAptitudePreviewModalOpen, setIsAptitudePreviewModalOpen] = useState(false);
  const [paeiArr, setPaeiArr] = useState([]);
  const [aptitudePreviewBtnDisable, setAptitudePreviewBtnDisable] = useState(true);

  const [activeTab, setActiveTab] = useState(0);
  const [courseNotDone, setCourseNotDone] = useState(true);

  const tabsItems = [
    {
      title: 'P',
      value: '/dashboard/tdp/PAEI-setting/practical',
    },
    { title: 'A', value: '/dashboard/tdp/PAEI-setting/administrative' },
    { title: 'E', value: '/dashboard/tdp/PAEI-setting/entrepreneurial' },
    { title: 'I', value: '/dashboard/tdp/PAEI-setting/integrative' },
    { title: 'paei', value: '/dashboard/tdp/PAEI-setting/paei' },
  ];

  useEffect(() => {
    dispatch(getPaeiAptitude());
  }, [dispatch]);

  useEffect(() => {
    let newArr = [];
    if (aptitudeData) {
      newArr.push(
        { type: aptitudeData?.type?.P, title: 'P' },
        { type: aptitudeData?.type?.A, title: 'A' },
        { type: aptitudeData?.type?.E, title: 'E' },
        { type: aptitudeData?.type?.I, title: 'I' },
        { type: aptitudeData?.type?.paei, title: 'paei' }
      );
    }
    newArr.forEach((item) => {
      item?.type?.forEach((section) => {
        let count = 0;
        if (section.chinese) {
          count++;
        }
        if (count === 0) {
          setAptitudePreviewBtnDisable(true);
        } else {
          setAptitudePreviewBtnDisable(false);
        }
      });
    });
  }, [aptitudeData, setAptitudePreviewBtnDisable]);

  useEffect(() => {
    if (params.reference === 'practical') {
      setActiveTab(0);
    } else if (params.reference === 'administrative') {
      setActiveTab(1);
    } else if (params.reference === 'entrepreneurial') {
      setActiveTab(2);
    } else if (params.reference === 'integrative') {
      setActiveTab(3);
    } else if (params.reference === 'paei') {
      setActiveTab(4);
    }
  }, [params.reference, setActiveTab]);

  useEffect(() => {
    dispatch(getPaeiRecommendCorse());
  }, [dispatch]);

  useEffect(() => {
    if (courseData) {
      const newArr = Object.keys(courseData);
      let count = 0;
      newArr.forEach((item) => {
        if (!courseData[item]) {
          count++;
        }
      });
      if (count !== 0) {
        setCourseNotDone(true);
      } else {
        setCourseNotDone(false);
      }
    }
  }, [courseData, setCourseNotDone]);

  return (
    <InnerLayout>
      <div className={styles.PAEIcontainer}>
        <div className={styles.header}>
          <div className={styles.title}>PAEI</div>
          <div className={styles.scoreSetting}>
            <div className={styles.tick}>
              {aptitudePreviewBtnDisable && (
                <>
                  <AiOutlineInfoCircle />
                  <span>查看性向總覽前請先進行任一屬性之性向設定</span>
                </>
              )}
            </div>
            <div className={styles.scoreSettingBtn}>
              <div className={styles.btn}>
                <Button
                  title='性向總覽'
                  type='tertiary'
                  disabled={isEditMode || aptitudePreviewBtnDisable}
                  onClick={() => setIsAptitudePreviewModalOpen(true)}
                />
              </div>
              <div className={styles.btn}>
                <Button
                  title='課程建議'
                  type='primary'
                  disabled={isEditMode}
                  icon={<MdKeyboardArrowRight />}
                  iconPosition='after'
                  onClick={() => navigate('/dashboard/tdp/PAEI-setting/recommend-corse')}
                />
                {courseNotDone && <div className={styles.redDot}></div>}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tabContainer}>
          <MenuTabs items={tabsItems} active={activeTab} type='link' disabled={isEditMode} style={{ padding: '8px 32px' }} />
        </div>
        <Practical
          setIsToastShow={setIsToastShow}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          setIsSnackBarOpen={setIsSnackBarOpen}
          isAptitdeModalOpen={isAptitdeModalOpen}
          setIsAptitdeModalOpen={setIsAptitdeModalOpen}
          paeiArr={paeiArr}
          setPaeiArr={setPaeiArr}
        />
      </div>

      <Modal isOpen={isAptitudePreviewModalOpen} size='lg'>
        <AptitudePreviewModal setIsAptitudePreviewModalOpen={setIsAptitudePreviewModalOpen} />
      </Modal>
      <Toast
        title={(isToastShow.paeiSetting && '說明文獻已儲存') || (isToastShow.aptitudeSetting && '性向設定已儲存')}
        show={isToastShow.paeiSetting || isToastShow.aptitudeSetting}
      />
      <Alert
        mainTitle='跳過目前正在編輯的項目嗎？'
        subTitle='系統可能不會儲存你所做的變更'
        leftButtonText='繼續編輯'
        rightButtonText='跳過'
        isOpen={isSnackBarOpen || (isEditMode && navigateToggle)}
        onClose={() => {
          setIsSnackBarOpen(false);
          dispatch(editModeToggleHandler(false));
        }}
        onConfirm={() => {
          setIsEditMode(false);
          setIsAptitdeModalOpen(false);
          setIsSnackBarOpen(false);
          dispatch(editModeToggleHandler(false));
          dispatch(editModeHandler(false));
        }}
      />
    </InnerLayout>
  );
};
export default PAEISettings;
