import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  sendGetClientRequest,
  sendPostClientRequest,
  sendDeleteClientRequest,
  sendPatchClientRequest,
} from "../../../api/helper";

// ASK 列表
export const getASKList = createAsyncThunk(
  "ask/getASKList",
  async (parameters) => {
    let url = `/api/ask_preset?category=${parameters.category}&page=${parameters.page}&page_length=${parameters.page_length}`;
    if (parameters?.key_word) {
      url += `&key_word=${parameters.key_word}`;
    }
    const response = await sendGetClientRequest(url);
    return response.data;
  }
);

// 上傳資料 Excel
export const askBatchImport = createAsyncThunk(
  "ask/askBatchImport",
  async (parameters) => {
    const response = await sendPostClientRequest(
      `/api/ask_batch_register`,
      parameters,
      true
    );
    return response;
  }
);

// 確認上傳 Excel
export const confirmBatchImport = createAsyncThunk(
  "ask/confirmBatchImport",
  async (parameters) => {
    const response = await sendPostClientRequest(
      `/api/ask_batch_register/${parameters.cacheID}`,
      parameters
    );
    return response;
  }
);

// 刪除單筆 ASK
export const deleteASK = createAsyncThunk(
  "ask/deleteASK",
  async (parameters) => {
    const response = await sendDeleteClientRequest(
      `/api/ask_preset/${parameters.askID}`,
      parameters
    );
    return response;
  }
);

// 編輯 ASK
export const updateASK = createAsyncThunk(
  "ask/updateASK",
  async (parameters) => {
    const postObj = {
      category: parameters.category,
      item: parameters.item,
      ability_ask_item: parameters.ability_ask_item,
    };
    const response = await sendPatchClientRequest(
      `/api/ask_preset/${parameters.askID}`,
      postObj
    );
    return response;
  }
);

const askSlice = createSlice({
  name: "ask",
  initialState: {
    loading: false,
    success: false,
    removed: false,
    updated: false,
    uploaded: false,
    ask_lists: "",
    import_data: "",
    error: null,
  },
  reducers: {
    resetSuccess: (state) => {
      state.success = false;
      state.removed = false;
      state.uploaded = false;
      state.updated = false;
      state.import_data = "";
    },
    updateError: (state) => {
      state.error = false;
    },
  },
  extraReducers: {
    // ASK LISTS
    [getASKList.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getASKList.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.loading = false;
        state.success = true;
        state.ask_lists = payload;
      } else {
        state.loading = false;
        state.success = false;
      }
    },
    [getASKList.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    },

    // IMPORT ASK EXCEL
    [askBatchImport.pending]: (state) => {
      state.loading = true;
    },
    [askBatchImport.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.import_data = payload.data;
      } else {
        state.import_data = "error";
      }
      state.loading = false;
    },
    [askBatchImport.rejected]: (state) => {
      state.loading = false;
    },

    // CONFIRM IMPORT ASK EXCEL
    [confirmBatchImport.pending]: (state) => {
      state.loading = true;
      state.uploaded = false;
    },
    [confirmBatchImport.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.uploaded = true;
      }
      state.loading = false;
    },
    [confirmBatchImport.rejected]: (state) => {
      state.loading = false;
      state.uploaded = false;
    },

    // UPDATE ASK
    [updateASK.pending]: (state) => {
      state.loading = true;
      state.updated = false;
    },
    [updateASK.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.updated = true;
      }
      state.loading = false;
    },
    [updateASK.rejected]: (state) => {
      state.loading = false;
      state.updated = false;
    },

    // DELETE ASK
    [deleteASK.pending]: (state) => {
      state.loading = true;
      state.removed = false;
    },
    [deleteASK.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.removed = true;
        state.error = false;
      } else {
        state.success = false;
        state.error = true;
      }
      state.loading = false;
    },
    [deleteASK.rejected]: (state) => {
      state.loading = false;
      state.removed = false;
    },
  },
});

export default askSlice.reducer;
export const selectASK = (state) => state.ask;
export const { resetSuccess, updateError } = askSlice.actions;
