import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../store/slice/user';

import { Link } from 'react-router-dom';

import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { MdErrorOutline } from 'react-icons/md';
import styles from './style/Auth.module.scss';

import { sendPostLogin } from '../../api/helper';

import { emailRegex } from '../../utils';
import { useNavigate } from 'react-router-dom';

import Button from '../Utilities/Button';
import ToggleSwitch from '../Utilities/ToggleSwitch';
import Toast from '../Utilities/Toast';

import Logo from '../../assets/Logo.png';

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState('');

  const [, setCookie] = useCookies(['token']);

  const [isFormFail, setIsFormFail] = useState(false);
  const [isLoginFail, setIsLoginFail] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRememberUser, setIsRememberUser] = useState(false);

  const [isRequiredSendEmail, setIsRequiredSendEmail] = useState(false);
  const [isSentEmail, serIsSentEmail] = useState(false);

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const onSubmit = async (data) => {
    setIsPending(true);
    const { email, password } = data;

    const res = await sendPostLogin('/api/auth/login', {
      email: email,
      password: password,
      type: 'center',
    });

    if (res.status === 200) {
      dispatch(updateUser(res.data.user));
      setCookie('token', res.data.access_token, {
        maxAge: isRememberUser ? 60 * 60 * 23 : 60 * 60 * 6,
        secure: true,
        sameSite: 'strict',
        path: '/',
      });

      localStorage.setItem('UserData', JSON.stringify(res.data.user));
      if (res.data.user?.roles.find((item) => item === 'workspace.tdp')) {
        navigate('/dashboard/tdp/menu');
      } else {
        navigate('/admin/workspace');
      }

      setIsPending(false);
    } else if (res.status === 403) {
      setErrorMessage('帳號尚未完成驗證');
      setIsLoginFail(true);
      setIsPending(false);
    } else {
      setErrorMessage('帳號或密碼錯誤');
      setIsLoginFail(true);
      setIsPending(false);
    }
  };

  const handleKeepRememberUser = () => {
    setIsRememberUser(!isRememberUser);
  };

  //TODO: 發送認證信件（重新寄送信件後需要disable多少秒）
  function handleSendVerifyEmail() {
    setIsToastOpen(true);
    serIsSentEmail(true);
    setTimeout(() => {
      setIsToastOpen(false);
    }, 3000);
    setTimeout(() => {
      serIsSentEmail(false);
    }, 10000);
  }

  return (
    <div className={styles.authBox}>
      <div className={styles.logoContainer}>
        <img src={Logo} alt='Logo' />
      </div>
      <div className={styles.formBox}>
        <div className={styles.formContainer}>
          <div>
            <div className={styles.titleTop}>中控系統</div>
            <div className={styles.titleBottom}>KEYSTONE</div>
            <div className={styles.subTitle}>啟動你的科技加速器</div>
            <div className={styles.formTitle}>登入</div>

            <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
              <input
                disabled={isPending ? true : false}
                className={styles.nonErrorInput}
                placeholder='請輸入帳號電子信箱'
                {...register('email', {
                  onChange: () => {
                    clearErrors();
                    setIsFormFail(false);
                    setIsLoginFail(false);
                    setIsRequiredSendEmail(false);
                  },
                  required: '帳號為必填',
                  pattern: {
                    value: emailRegex,
                    message: '帳號或密碼錯誤',
                  },
                })}
              />
              <div className={styles.passwordContent}>
                <input
                  disabled={isPending ? true : false}
                  className={styles.nonErrorInput}
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder='請輸入密碼'
                  {...register('password', {
                    required: '密碼為必填',
                    onChange: () => {
                      clearErrors();
                      setIsFormFail(false);
                      setIsLoginFail(false);
                      setIsRequiredSendEmail(false);
                    },
                  })}
                />
                <div className={styles.passwordContent}>
                  {isPasswordVisible ? (
                    <IoMdEyeOff onClick={() => setIsPasswordVisible(false)} cursor='pointer' />
                  ) : (
                    <IoMdEye onClick={() => setIsPasswordVisible(true)} cursor='pointer' />
                  )}
                </div>
              </div>
              <div className={styles.rememberUserContent}>
                <ToggleSwitch isChecked={isRememberUser} onChange={handleKeepRememberUser} />
                <h4 className={isRememberUser ? styles.rememberUserText : styles.rememberUserTextUnChecked}>記住電子信箱</h4>
              </div>
              {!isPending && (isFormFail || isLoginFail) && (
                <div className={styles.errorContent}>
                  <div className={styles.errorIcon}>
                    <MdErrorOutline />
                  </div>
                  <div className={styles.errorMessage}>
                    <h4>{errors.email?.message || errors.password?.message || errorMessage}</h4>
                  </div>
                  {isRequiredSendEmail && <Button className='tertiary' children='重新發送' onClick={handleSendVerifyEmail} isDisable={isSentEmail} />}
                </div>
              )}
              <div className={styles.submitContent}>
                <button className={styles.btnDefault} type='submit' onClick={() => setIsFormFail(true)} style={isPending ? { opacity: 0.3 } : null}>
                  {isPending && (
                    <svg
                      role='status'
                      className='inline mr-3 w-4 h-4 text-white animate-spin'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='#E5E7EB'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentColor'
                      />
                    </svg>
                  )}

                  {isPending ? '處理中' : '登入'}
                </button>
              </div>
            </form>
            <div className={styles.registerLinkContent}>
              <Link to='/forgot-password'>忘記密碼？</Link>
            </div>
          </div>
        </div>
      </div>
      <Toast show={isToastOpen} title='重新發送完成' subtitle='帳號認證信' error={false} />
    </div>
  );
};

export default LoginForm;
