import { useState, useEffect } from 'react';
import styles from './style/ToggleSwitch.module.scss';

const ToggleSwitch = ({ isChecked, onChange, isTransparent }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(isChecked);
  }, [isChecked]);

  const handleOnClick = () => {
    onChange();
    setValue(!value);
  };

  return (
    <label className={styles.switch}>
      <input type='checkbox' checked={value} onChange={handleOnClick} />
      <span className={isTransparent ? styles.sliderTransparent : styles.slider}></span>
    </label>
  );
};

export default ToggleSwitch;
