import { useState } from 'react';
import InnerLayout from '../../Layout/InnerLayout';
import styles from './style/Lists.module.scss';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdDeleteOutline, MdEditNote, MdUploadFile } from 'react-icons/md';
import { FiDownloadCloud } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ExcelDownload from '../../../assets/ask_example.xlsx';

import Button from '../../Utilities/Button';
import MenuTabs from '../../Utilities/MenuTabs';
import Paginate from '../../Utilities/Paginate';
import EmptyBox from '../../Utilities/EmptyBox';

import { useEffect } from 'react';
import { askBatchImport, deleteASK, getASKList, selectASK, resetSuccess, updateError } from '../../../store/slice/tdp/ask';
import ModalASK from './ModalASK';
import ModalImport from './ModalImport';
import MoreButton from '../../Utilities/MoreButton';
import { useSelector } from 'react-redux';
import Alert from '../../Utilities/Alert';
import Tips from '../../Utilities/Tips';
import { BsSearch } from 'react-icons/bs';

const PAEISettings = () => {
  const dispatch = useDispatch();
  const { ask_lists, removed, uploaded, updated, import_data, loading, error } = useSelector(selectASK);
  const params = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [openId, setOpenId] = useState('');
  const [removeId, setRemoveId] = useState('');
  const [category, setCategory] = useState('');
  // const [temp, setTemp] = useState('');
  const [keyword, setKeyword] = useState('');
  const [errorUpload, setErrorUpload] = useState(false);

  const [title, setTitle] = useState('Attitude');
  const [openImportModal, setOpenImportModal] = useState(false);
  const [page, setPage] = useState(1);
  const [isAlert, setIsAlert] = useState(false);
  const limit = 10;

  const tabsItems = [
    { title: 'Attitude', value: '/dashboard/tdp/ask/attitude' },
    { title: 'Skill', value: '/dashboard/tdp/ask/skill' },
    { title: 'Knowledge', value: '/dashboard/tdp/ask/knowledge' },
  ];

  useEffect(() => {
    if (removed || updated) {
      const postObj = {
        page: page,
        page_length: limit,
        category: category,
        key_word: keyword,
      };
      dispatch(getASKList(postObj));
      setTimeout(() => {
        dispatch(resetSuccess());
      }, 1000);
      setTimeout(() => {
        setDetail('');
      }, 2000);
    }
  }, [dispatch, removed, category, page, updated, keyword]);

  useEffect(() => {
    if (uploaded) {
      const postObj = {
        page: 1,
        page_length: limit,
        category: 'A',
      };
      dispatch(getASKList(postObj));
      setOpenImportModal(false);
      setTimeout(() => {
        dispatch(resetSuccess());
      }, 1500);
    }
  }, [dispatch, uploaded]);

  useEffect(() => {
    setPage(1);
    let postObj = '';
    if (params.reference === 'attitude') {
      setActiveTab(0);
      setTitle('Attitude');
      postObj = {
        page: 1,
        page_length: 10,
        category: 'A',
        key_word: keyword,
      };
      setCategory('A');
    } else if (params.reference === 'skill') {
      setActiveTab(1);
      setTitle('Skill');
      postObj = {
        page: 1,
        page_length: 10,
        category: 'SS',
        key_word: keyword,
      };
      setCategory('SS');
    } else if (params.reference === 'knowledge') {
      setActiveTab(2);
      setTitle('Knowledge');
      postObj = {
        page: 1,
        page_length: 10,
        category: 'K',
        key_word: keyword,
      };
      setCategory('K');
    }
    if (postObj) dispatch(getASKList(postObj));
  }, [params.reference, setActiveTab, dispatch, keyword]);

  const pageChangeHandler = (e) => {
    setPage(parseInt(e.selected + 1));
    const postObj = {
      page: e.selected + 1,
      page_length: limit,
      category: category,
      key_word: keyword,
    };
    dispatch(getASKList(postObj));
  };

  const getUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      let obj = {
        file: e.target.files[0],
      };
      dispatch(askBatchImport(obj));
      reader.readAsArrayBuffer(e.target.files[0]);
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (import_data === 'error') {
      setErrorUpload(true);
    } else if (import_data) {
      setErrorUpload(false);
      setOpenImportModal(true);
    }
  }, [import_data]);

  useEffect(() => {
    if (!error) return;
    setIsAlert(true);
    dispatch(updateError());
  }, [dispatch, error]);

  const handleRemove = () => {
    const postObj = {
      askID: removeId,
    };
    dispatch(deleteASK(postObj));
    setRemoveId('');
  };

  // const handleFilterEnter = (e) => {
  //   if (e.code === 'Enter') {
  //     setKeyword(temp);
  //     setPage(1);
  //   }
  // };

  // const handleFilter = () => {
  //   setKeyword(temp);
  //   setPage(1);
  // };

  return (
    <InnerLayout>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>ASK</div>
          <div className={styles.scoreSetting}>
            <div className={styles.tick}>
              <AiOutlineInfoCircle className={styles.icon} />
              <span>請下載範本進行編輯，並且以 xls, xlsb, xlsx 之檔案格式上傳</span>
            </div>
            <div className={styles.scoreSettingBtn}>
              <div className={styles.btn}>
                <a href={ExcelDownload} download>
                  <Button title='下載範本' type='tertiary' icon={<FiDownloadCloud />} iconPosition='after' />
                </a>
              </div>
              <div className={styles.btn}>
                <Button title='上傳資料' type='secondary' icon={<MdUploadFile />} iconPosition='after'>
                  <input type='file' id='uploadfile' onChange={(e) => getUploadFile(e)} accept='.xls, .xlsx' />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.tabContainer}>
          <MenuTabs items={tabsItems} active={activeTab} type='link' />

          <div className={styles.searchOutline}>
            <input
              type='text'
              placeholder='搜尋職能項目'
              className='searchInput'
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
              // onKeyUp={handleFilterEnter}
            />
            <div
              className={styles.BsSearch}
              // onClick={handleFilter}
            >
              <BsSearch />
            </div>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <div className={styles.title}>{title} 職能慣性項目列表</div>

          <table>
            <thead>
              <tr>
                <th>ASK 職能慣性類別</th>
                <th width='60%'>職能項目</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ask_lists?.data?.length > 0 ? (
                ask_lists?.data?.map((data, index) => (
                  <tr key={index}>
                    <td>{data.category?.substring(0, 1)}</td>
                    <td>
                      <span className={styles.textPurple}>{data.item}</span>
                    </td>
                    <td>
                      <div className={styles.buttonWrapper}>
                        <span>
                          <Tips detail={'查看職能慣性項目'}>
                            <Button
                              type='pure'
                              icon={<MdEditNote />}
                              onClick={() => {
                                setDetail(data);
                                setIsOpen(true);
                              }}
                              disabled={loading}
                            />
                          </Tips>
                        </span>
                        <MoreButton onClick={() => setOpenId(openId === data.id ? '' : data.id)} open={data.id === openId ? true : false}>
                          <Button
                            onClick={() => setRemoveId(data.id)}
                            fullWidth
                            type='quaternary'
                            title='刪除'
                            icon={<MdDeleteOutline />}
                            color='#FF766E'
                          />
                        </MoreButton>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <EmptyBox caption={'請先前往右上角「上傳資料」上傳ASK檔案'} type={3} height={300} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {ask_lists?.data?.length > 0 && (
          <Paginate
            onPageChange={pageChangeHandler}
            pageCount={page}
            limit={limit}
            totalPages={Math.ceil(ask_lists.recordsTotal / 10)}
            totalData={ask_lists.recordsTotal}
          />
        )}
      </div>

      <ModalASK status={detail} setStatus={setDetail} setIsOpen={setIsOpen} isOpen={isOpen} />

      <ModalImport status={openImportModal} setStatus={setOpenImportModal} setPage={setPage} />

      <Alert
        isOpen={removeId}
        onConfirm={handleRemove}
        onClose={() => setRemoveId('')}
        rightButtonText={'刪除'}
        leftButtonText='取消'
        mainTitle={`確認刪除該職能項目？`}
        isWarning={true}
      />

      <Alert
        uploadFile
        isOpen={errorUpload}
        onConfirm={getUploadFile}
        onClose={() => {
          setErrorUpload(false);
          dispatch(resetSuccess());
        }}
        rightButtonText={'重新上傳'}
        leftButtonText={'取消'}
        mainTitle={`此檔案不符合格式`}
        subTitle={`請下載範本進行編輯，並且以 xls,xlsb,xlsx 之檔案格式上傳`}
      />

      <Alert
        isOpen={isAlert}
        onClose={() => setIsAlert(false)}
        onConfirm={() => setIsAlert(false)}
        mainTitle={`　　該職能項目有企業正在使用　　無法刪除！`}
        subTitle={''}
        rightButtonText='關閉'
      />
    </InnerLayout>
  );
};
export default PAEISettings;
