import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnterpriseList, getDashboardFilter, selectDashboard } from '../../store/slice/tdp/dashboard';
import { useNavigate } from 'react-router-dom';

// Style
import styles from './style/Dashboard.module.scss';

//icon
import { MdPieChart, MdDashboard } from 'react-icons/md';

// Utility
import Dropdown from '../Utilities/DropdownDynamic';
import InputSearch from '../Utilities/InputSearch';
import Button from '../Utilities/Button';
import Table from '../Utilities/Table';
import LoadingTable from '../Utilities/LoadingTable';
import Paginate from '../Utilities/Paginate';
import Tips from '../Utilities/Tips';
import EmptyBox from '../Utilities/EmptyBox';

// Components
import InnerLayout from '../Layout/InnerLayout';
import DashboardPlanAnalysisModal from './DashboardPlanAnalysisModal';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filter, enterprise, loading } = useSelector(selectDashboard);

  const [dropdownItem, setDropdownItem] = useState([]);
  const [dropdownVal, setDropDownVal] = useState('');
  const [tempKeyword, setTempKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  useEffect(() => {
    if (!filter) dispatch(getDashboardFilter());
  }, [dispatch, filter]);

  useEffect(() => {
    if (filter) {
      let array = [
        {
          text: `所有 (${filter.all})`,
          value: 'all',
        },
        {
          text: `啟用中 (${filter.activate})`,
          value: 'activate',
        },
        {
          text: `已停用 (${filter.unactivate})`,
          value: 'unactivate',
        },
      ];
      setDropdownItem(array);
      // setDropDownVal({
      //   text: `所有 (${filter.all})`,
      //   value: 'all',
      // });
      setDropDownVal({
        text: `啟用中 (${filter.activate})`,
        value: 'activate',
      });
    }
  }, [dispatch, filter]);

  useEffect(() => {
    if (dropdownVal) {
      let activate = '';
      if (dropdownVal.value === 'all') {
        activate = '';
      } else if (dropdownVal.value === 'activate') {
        activate = 'true';
      } else if (dropdownVal.value === 'unactivate') {
        activate = 'false';
      }

      const postObj = {
        page: 1,
        limit: limit,
        activate: activate,
        search: keyword,
      };
      dispatch(getEnterpriseList(postObj));
      setPage(1);
    }
  }, [dispatch, dropdownVal, limit, keyword]);

  const [isPlanAnalysisModalOpen, setIsPlanAnalysissModalOpen] = useState(false);

  //table header
  const tableHeader = [
    {
      title: '公司名稱',
      value: 'enterprise_name',
      width: '25',
    },
    {
      title: '產業類型',
      value: 'enterprise_name',
      width: '20',
    },
    {
      title: '員工人數',
      value: 'headcount',
      width: '10',
    },
    {
      title: '啟用人數',
      value: 'users_using_service_count',
      width: '10',
    },
    {
      title: '使用方案類型',
      value: 'software_services_name',
      width: '20',
    },
    {
      title: '啟用狀態',
      value: 'activate_status',
      width: '10',
    },
    {
      title: '',
      value: '',
      width: '5',
    },
  ];

  const filterKeyword = () => {
    let activate = '';
    if (dropdownVal.value === 'all') {
      activate = '';
    } else if (dropdownVal.value === 'activate') {
      activate = 'true';
    } else if (dropdownVal.value === 'unactivate') {
      activate = 'false';
    }

    const postObj = {
      page: 1,
      limit: limit,
      search: tempKeyword,
      activate: activate,
    };
    dispatch(getEnterpriseList(postObj));
    setKeyword(tempKeyword);
    setPage(1);
  };

  const pageChangeHandler = (e) => {
    setPage(parseInt(e.selected + 1));

    let activate = '';
    if (dropdownVal.value === 'all') {
      activate = '';
    } else if (dropdownVal.value === 'activate') {
      activate = 'true';
    } else if (dropdownVal.value === 'unactivate') {
      activate = 'false';
    }

    const postObj = {
      page: parseInt(e.selected + 1),
      limit: limit,
      search: tempKeyword,
      activate: activate,
    };
    dispatch(getEnterpriseList(postObj));
  };

  //查看方案使用分析
  const handlePlanAnalysis = () => {
    setIsPlanAnalysissModalOpen(true);
  };

  // 查看公司執行概況
  const handleOpenDashboardOverview = (detail) => {
    navigate(`/dashboard/tdp/menu/${detail.enterprise_id}`);
  };

  return (
    <InnerLayout>
      <>
        <div className={styles.dashboardOuter}>
          <div className={styles.header}>總覽</div>
          <div className={styles.subHeader}>
            <div className={styles.subHeaderLeft}>
              <div className={styles.dropdownOuter}>
                <Dropdown listItems={dropdownItem} value={dropdownVal?.text} caption={'text'} onChange={(e) => setDropDownVal(e)} />
              </div>
              <InputSearch
                placeholder='搜尋公司名稱'
                onClick={filterKeyword}
                onChange={(e) => setTempKeyword(e.target.value)}
                value={tempKeyword}
                onKeyPress={filterKeyword}
              />
            </div>
            <div className={styles.subHeaderRight}>
              <Button title={'方案使用分析'} type={'tertiary'} icon={<MdPieChart />} iconPosition='after' onClick={() => handlePlanAnalysis()} />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.title}>企業列表</div>

            {/* todo 加上api loading */}
            <Table header={tableHeader} disabled={enterprise?.total === 0}>
              {loading ? (
                <LoadingTable row={10} column={7} />
              ) : (
                <>
                  {enterprise?.rows?.length > 0 ? (
                    <tbody>
                      {enterprise?.rows?.map((item, idx) => (
                        <tr key={idx}>
                          {tableHeader.map((headerTitle, headerIndex) => (
                            <td key={headerIndex} style={{ width: `${headerTitle.width}%` }}>
                              <div>
                                {headerTitle.value !== 'activate_status' && headerTitle.value !== '' ? (
                                  <span>{item[headerTitle.value]}</span>
                                ) : headerTitle.value === 'activate_status' ? (
                                  <div className={styles[`activate-${item[['activate_status']]}`]}>
                                    {item['activate_status'] === 0 ? '已停用' : '啟用中'}
                                  </div>
                                ) : (
                                  <>
                                    <Tips detail='查看公司執行概況'>
                                      <span>
                                        <Button type={'pure'} icon={<MdDashboard />} onClick={() => handleOpenDashboardOverview(item)} />
                                      </span>
                                    </Tips>
                                  </>
                                )}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <EmptyBox type={1} caption='企業列表無資料' />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>

            {enterprise?.rows?.length > 0 && (
              <Paginate
                onPageChange={pageChangeHandler}
                pageCount={page}
                limit={limit}
                totalPages={Math.ceil(enterprise?.total / limit)}
                totalData={enterprise?.total}
              />
            )}
          </div>
        </div>

        {isPlanAnalysisModalOpen && (
          <DashboardPlanAnalysisModal isPlanAnalysisModalOpen={isPlanAnalysisModalOpen} setIsPlanAnalysissModalOpen={setIsPlanAnalysissModalOpen} />
        )}
      </>
    </InnerLayout>
  );
};

export default Dashboard;
