import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slice/user';
import companyDataReducer from './slice/companyData';
import permissionSettingsReducer from './slice/permissionSettings';
import navigateReducer from './slice/navigate';
import workspaceRuducer from './slice/workspace';
import workspaceStatesRuducer from './slice/workspaceStates';
import enterpriseReducer from './slice/enterpriseLists';
import workspaceListReducer from './slice/workspaceList';

import menuReducer from './slice/manager/menu';
import paymentReducer from './slice/manager/payment';
import plansReducer from './slice/manager/plans';
import enterpriseCompanyReducer from './slice/manager/enterpriseCompany';
import askReducer from './slice/tdp/ask';
import dashboardReducer from './slice/tdp/dashboard';

import lifeCycleReducer from './slice/lifeCycle';

const store = configureStore({
  reducer: {
    user: userReducer,
    companyData: companyDataReducer,
    permissionSettings: permissionSettingsReducer,
    navigate: navigateReducer,
    workspace: workspaceRuducer,
    workspaceStates: workspaceStatesRuducer,
    enterpriseLists: enterpriseReducer,
    workspaceList: workspaceListReducer,

    lifeCycle: lifeCycleReducer,

    menu: menuReducer,
    payment: paymentReducer,
    plans: plansReducer,
    enterpriseCompany: enterpriseCompanyReducer,
    ask: askReducer,
    dashboard: dashboardReducer,
  },
});

export default store;
