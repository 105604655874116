import styles from "./style/Tag.module.scss";

import { IoIosCloseCircle } from "react-icons/io";

const Tag = ({ title, onClick, isDisplayOnly }) => {
  return (
    <div className={styles.groupTag}>
      <div className={styles.tagContent}>
        {isDisplayOnly && (
          <>
            <div className={styles.displaytitle}>{title}</div>
          </>
        )}
        {!isDisplayOnly && (
          <>
            <div className={styles.title}>{title}</div>
            <div className={styles.tagIcon}>
              <IoIosCloseCircle onClick={onClick} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tag;
