import { useEffect } from "react";
import styles from "./style/Toast.module.scss";
import { MdOutlineCheckCircleOutline, MdOutlineCancel } from "react-icons/md";

const Toast = (props) => {
  const { show, title, subtitle, error } = props;
  const notificationContainer = document.getElementById(
    "notification-container"
  );

  useEffect(() => {
    if (!notificationContainer) return;
    notificationContainer.style.top = show ? "40px" : "0px";
    notificationContainer.style.opacity = show ? "1" : "0";
    setTimeout(
      () => {
        notificationContainer.className = `${
          styles["notification-container"]
        } ${show ? styles["notification-on"] : styles["notification-off"]}`;
      },
      show ? 0 : 500
    );
  }, [show, notificationContainer]);

  return (
    <div
      id='notification-container'
      className={`${styles["notification-container"]} ${styles["notification-off"]}`}>
      <div className={styles.notification}>
        <p
          className={styles["notification-title"]}
          style={{ color: error ? "red" : "black" }}>
          {error ? (
            <MdOutlineCancel className={styles.iconError} />
          ) : (
            <MdOutlineCheckCircleOutline className={styles.icon} />
          )}{" "}
          {title}
        </p>
        <p className={styles["notification-subtitle"]}>{subtitle}</p>
      </div>
    </div>
  );
};

export default Toast;
