import styles from "./style/AlertSwitch.module.scss";

const AlertSwitch = ({
  isChecked,
  onChange,
  isTransparent,
  color = { on: "#333333", off: "#dddddd" },
}) => {
  return (
    <label className={styles.switch}>
      <input type='checkbox' checked={isChecked} onChange={onChange} />
      <span
        className={isTransparent ? styles.sliderTransparent : styles.slider}
        style={{
          backgroundColor: isChecked ? color.on : color.off,
        }}></span>
    </label>
  );
};

export default AlertSwitch;
