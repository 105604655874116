import { Outlet, useNavigate } from 'react-router-dom';
import styles from './style/layout.module.scss';

import LeftNavigation from '../Navigation/LeftNavigation';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

const PlatformLayout = () => {
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies?.token) {
      navigate('/');
    }
  }, [cookies, navigate]);

  return (
    <div className={styles.platform}>
      <LeftNavigation />
      <Outlet />
    </div>
  );
};

export default PlatformLayout;
