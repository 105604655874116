import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { sendPostRequest } from "../../api/helper";

// 忘記密碼 發送驗證信件
export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (parameters) => {
    const response = await sendPostRequest(
      "/api/auth/forget-password",
      parameters
    );
    return response;
  }
);

// 確認驗證信是否有效
export const verifytoken = createAsyncThunk(
  "user/verifytoken",
  async (parameters) => {
    const response = await sendPostRequest(
      "/api/auth/verify-reset-password-token",
      parameters
    );
    return response;
  }
);

// 重新設定密碼
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (parameters) => {
    const response = await sendPostRequest(
      "/api/auth/reset-password",
      parameters
    );
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogin: false,
    loading: false,
    message: "",
    success: false,
    userVerified: false,
    tokenVerify: null,
    user: {
      email: "",
      name: "",
      telephone: "",
      phoneNumber: "",
      jobTitle: "",
      numberOfWorkers: 0,
      industry: "",
      taxIDNumber: "",
      level: "",
    },
  },
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;
    },
    logoutAndClearUser: (state) => {
      state.user = {
        email: "",
        name: "",
        telephone: "",
        phoneNumber: "",
        jobTitle: "",
        numberOfWorkers: 0,
        industry: "",
        taxIDNumber: "",
        level: "",
      };
      state.isLogin = false;
    },
  },

  extraReducers: {
    // EDIT ACCOUNT PWD
    [forgotPassword.pending]: (state) => {
      state.loading = true;
      state.message = "";
      state.success = false;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.loading = false;
        state.success = true;
        state.message = "";
      } else {
        state.loading = false;
        state.message = payload.msg;
        state.success = false;
      }
    },
    [forgotPassword.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    },

    [verifytoken.pending]: (state) => {
      state.loading = true;
      state.userVerified = false;
    },
    [verifytoken.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.loading = false;
        state.user = payload.data;
        state.tokenVerify = true;
        state.userVerified = true;
      } else {
        state.loading = false;
        state.tokenVerify = false;
        state.userVerified = false;
      }
    },
    [verifytoken.rejected]: (state) => {
      state.loading = false;
      state.tokenVerify = false;
      state.userVerified = true;
    },

    [resetPassword.pending]: (state) => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.loading = false;
    },
    [resetPassword.rejected]: (state) => {
      state.loading = true;
    },
  },
});

export default userSlice.reducer;
export const selectUser = (state) => state.user;
export const { updateUser, logoutAndClearUser } = userSlice.actions;
