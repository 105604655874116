import styles from './style/Tabs.module.scss';

const Tabs = ({ selectedIndex, values, onChange }) => {
  const tabWidthPercentage = 100 / values.length;

  function handleTabOnClick(index) {
    if (index === selectedIndex) return;
    if (onChange) {
      const event = {
        tabIndex: index,
        tabValue: values[index],
      };
      onChange(event);
    }
  }

  function handleTabClassName(index) {
    let className = styles[`tab-value${selectedIndex === index ? '-selected' : isNaN(selectedIndex) && index === 0 ? '-selected' : ''}`];
    return className;
  }

  return (
    <div className={styles.tabs}>
      <div
        className={styles.tab}
        style={{
          left: `${tabWidthPercentage * selectedIndex}%`,
          width: `calc(${tabWidthPercentage}% - 6px)`,
        }}
      />
      <div className={styles.tabList}>
        {values.map((value, index) => (
          <div
            key={`tab-${index}`}
            style={{ width: `${tabWidthPercentage}%` }}
            className={handleTabClassName(index)}
            onClick={() => handleTabOnClick(index)}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
