import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import styles from "./style/questionPreviewTable.module.scss";
import EmptyBox from "../Utilities/EmptyBox";
import Modal from "../Utilities/Modal";
import TestModal from "./TestModal";
import Toast from "../Utilities/Toast";
import LoadingTable from "../Utilities/LoadingTable";

import { MdOutlineNavigateNext } from "react-icons/md";

import { getQuestions, selectLifeCycle } from "../../store/slice/lifeCycle";

const QuestionPreview = ({ listValue }) => {
  const dispatch = useDispatch();
  const { testData, testLoading, loading } = useSelector(selectLifeCycle);
  const [isToastShow, setIsToastShow] = useState({
    scoreToast: false,
    literatureToast: false,
    testToast: false,
  });
  const [testModalOpen, setTestModalOpen] = useState({
    isOpen: false,
    cycle_id: null,
    title: null,
  });

  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch]);

  const handleTestModal = (item) => {
    setTestModalOpen({
      isOpen: true,
      cycle_id: item.cycle_id,
      title: item.title,
    });
  };

  useEffect(() => {
    if (testLoading === "done") {
      dispatch(getQuestions());
    }
  }, [dispatch, testLoading]);

  return (
    <>
      <div className={styles.tableContainer}>
        <table>
          {listValue === "預覽全部" && (
            <>
              {testData?.map((item) => (
                <React.Fragment key={item.cycle_id}>
                  <thead>
                    <>
                      <span className={styles.title}>{item.title}</span>
                      <tr>
                        <th width='60px'>項次</th>
                        <th width='90%'>企業狀態描述</th>
                      </tr>
                    </>
                  </thead>
                  <>
                    {item.evaluation_question[0].question !== null ? (
                      item.evaluation_question.map((ques, idx) => (
                        <tbody key={ques.question_id}>
                          <tr>
                            <td>{`0${idx + 1}.`}</td>
                            <td>{ques.question}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan='2'>
                            <EmptyBox
                              caption={"目前還未設定該週期評測試題"}
                              title={"前往設定試題"}
                              onClick={() => handleTestModal(item)}
                              icon={<MdOutlineNavigateNext />}
                              iconPosition='after'
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </>
                </React.Fragment>
              ))}
            </>
          )}
          {listValue !== "預覽全部" && testData.length > 0 && (
            <>
              {testData
                .filter((val) => val.title === listValue)
                .map((item) => (
                  <React.Fragment key={item.cycle_id}>
                    <thead>
                      <>
                        <span className={styles.title}>{item.title}</span>
                        <tr>
                          <th width='10%'>項次</th>
                          <th>企業狀態描述</th>
                        </tr>
                      </>
                    </thead>
                    <>
                      {item.evaluation_question[0].question !== null ? (
                        item.evaluation_question.map((ques, idx) => (
                          <tbody key={ques.question_id}>
                            <tr>
                              <td className={styles.tdIndex}>{`0${
                                idx + 1
                              }.`}</td>
                              <td className={styles.tdQues}>{ques.question}</td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan='2'>
                              <EmptyBox
                                caption={"目前還未設定該週期評測試題"}
                                title={"前往設定試題"}
                                onClick={() => handleTestModal(item)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  </React.Fragment>
                ))}
            </>
          )}
          {loading && <LoadingTable row={5} column={2} />}
        </table>
      </div>
      <Modal isOpen={testModalOpen.isOpen} size={"lg"}>
        <TestModal
          testModalOpen={testModalOpen}
          setTestModalOpen={setTestModalOpen}
          setIsToastShow={setIsToastShow}
        />
      </Modal>
      <Toast show={isToastShow.testToast} title={"評測試題已儲存"}></Toast>
    </>
  );
};

export default QuestionPreview;
