import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  sendGetClientRequest,
  sendPostClientRequest,
  sendDeleteClientRequest,
  sendPatchClientRequest,
} from "../../../api/helper";

import { serialize } from "../../../utils";

export const getPlanPackages = createAsyncThunk(
  "plans/getPlanPackages",
  async (service) => {
    const response = await sendGetClientRequest(
      `/api/software-services/${service}/plan-packages`
    );
    return response.data;
  }
);

// 取得方案組合列表(新) - 未啟用(publishing = 0)
export const getPlanPackagesNew = createAsyncThunk(
  "plans/getPlanPackagesNew",
  async (parameters) => {
    const queryObj = {};
    if (parameters) {
      if (parameters.limit) queryObj.rows = parameters.limit;
      if (parameters.page) queryObj.page = parameters.page;
      if (parameters.search) queryObj.search = parameters.search;
      queryObj.type = "group_by_plan";
    }

    let queryString = "";
    if (JSON.stringify(queryObj) !== "{}")
      queryString = "?publishing=0&" + serialize(queryObj);

    // const response = await sendGetClientRequest(`/api/software-services/${parameters.service}/grouped-plan-packages${queryString}`);
    const response = await sendGetClientRequest(
      `/api/software-services/${parameters.service}/plan-packages${queryString}`
    );
    return response.data;
  }
);

// 取得方案組合列表(新) - 已啟用(publishing = 1)
export const getPlanPackagesPublishing = createAsyncThunk(
  "plans/getPlanPackagesPublishing",
  async (parameters) => {
    // const response = await sendGetClientRequest(`/api/software-services/${parameters.service}/grouped-plan-packages?publishing=1`);
    const response = await sendGetClientRequest(
      `/api/software-services/${parameters.service}/plan-packages?publishing=1&type=group_by_plan`
    );
    return response.data;
  }
);

export const getPlanPackageDetail = createAsyncThunk(
  "plans/getPlanPackageDetail",
  async (id) => {
    const response = await sendGetClientRequest(
      `/api/software-service-plan-packages/${id}`
    );
    return response.data;
  }
);

export const getPlanPackageDetailNew = createAsyncThunk(
  "plans/getPlanPackageDetailNew",
  async (id) => {
    const response = await sendGetClientRequest(
      `/api/software-service-grouped-plan-packages/${id}`
    );
    return response.data;
  }
);

export const createPlanPackage = createAsyncThunk(
  "plans/createPlanPackage",
  async (parameters) => {
    const postObj = {
      name: parameters.name,
      plans: parameters.plans,
    };
    const response = await sendPostClientRequest(
      `/api/software-services/${parameters.service}/plan-packages`,
      postObj
    );
    return response;
  }
);

export const createPlanPackageNew = createAsyncThunk(
  "plans/createPlanPackageNew",
  async (parameters) => {
    // const postObj = {
    //   name: parameters.name,
    //   plans: parameters.plans,
    // };
    const response = await sendPostClientRequest(
      // `/api/software-services/${parameters.service}/grouped-plan-packages`,
      `/api/software-services/${parameters.service}/plan-packages`,
      // postObj
      parameters
    );
    return response;
  }
);

export const deletePlanPackage = createAsyncThunk(
  "plans/deletePlanPackage",
  async (id) => {
    const response = await sendDeleteClientRequest(
      `/api/software-service-plan-packages/${id}`
    );
    return response;
  }
);

export const activatePlanPackage = createAsyncThunk(
  "plans/activatePlanPackage",
  async (id) => {
    const response = await sendPostClientRequest(
      `/api/software-service-plan-packages/${id}/publish`
    );
    return response;
  }
);

export const publishPlanPackage = createAsyncThunk(
  "plans/publishPlanPackage",
  async (parameters) => {
    const response = await sendPatchClientRequest(
      `/api/software-service-plan-packages/${parameters.id}`,
      parameters
      // { publish_month: parameters.publish_month }
    );
    return response;
  }
);

const planSlice = createSlice({
  name: "plans",
  initialState: {
    packages: [],
    package_detail: null,
    success: false,
    refresh: false,
    loading: false,
    publish: false,
    publishError: false,
    newPlanLoading: false,
  },
  reducers: {
    updateDetailPlan: (state) => {
      state.package_detail = null;
    },
    removeSuccess: (state) => {
      state.refresh = false;
      state.publish = false;
    },
    removePublishError: (state) => {
      state.publishError = false;
    },
  },
  extraReducers: {
    // GET PLAN PACKAGES
    [getPlanPackages.pending]: (state) => {
      state.loading = true;
    },
    [getPlanPackages.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.packages = payload;
        state.loading = false;
      } else {
        state.loading = false;
      }
    },
    [getPlanPackages.rejected]: (state) => {
      state.loading = false;
    },

    // GET PLAN PACKAGES New
    [getPlanPackagesNew.pending]: (state) => {
      state.loading = true;
    },
    [getPlanPackagesNew.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.packages = payload;
        state.loading = false;
      } else {
        state.loading = false;
      }
    },
    [getPlanPackagesNew.rejected]: (state) => {
      state.loading = false;
    },

    [getPlanPackagesPublishing.pending]: (state) => {
      state.loading = true;
    },
    [getPlanPackagesPublishing.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.publishingPackages = payload;
        state.loading = false;
      } else {
        state.loading = false;
      }
    },
    [getPlanPackagesPublishing.rejected]: (state) => {
      state.loading = false;
    },

    // GET PLAN PACKAGES DETAIL
    [getPlanPackageDetail.pending]: (state) => {
      state.loading = true;
    },
    [getPlanPackageDetail.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.package_detail = payload;
        state.loading = false;
      }
    },
    [getPlanPackageDetail.rejected]: (state) => {
      state.loading = false;
    },

    // GET PLAN PACKAGES DETAIL NEW
    [getPlanPackageDetailNew.pending]: (state) => {
      state.loading = true;
    },
    [getPlanPackageDetailNew.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.package_detail = payload;
        state.loading = false;
      }
    },
    [getPlanPackageDetailNew.rejected]: (state) => {
      state.loading = false;
    },

    // CREATE PLAN PACKAGES
    [createPlanPackage.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createPlanPackage.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.success = "新增方案組合成功";
        state.loading = false;
      }
    },
    [createPlanPackage.rejected]: (state) => {
      state.loading = false;
    },

    // CREATE PLAN PACKAGES NEW
    [createPlanPackageNew.pending]: (state) => {
      state.refresh = false;
      state.newPlanLoading = true;
    },
    [createPlanPackageNew.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.refresh = true;
        state.newPlanLoading = false;
        // let newData = state.packages;
        // newData.packages.push(payload.data);
        // newData.packages_count += 1;
        // state.packages = newData;
      }
    },
    [createPlanPackageNew.rejected]: (state) => {
      state.newPlanLoading = false;
      state.refresh = false;
    },

    // DELETE PLAN PACKAGES
    [deletePlanPackage.pending]: (state) => {
      state.loading = true;
      state.refresh = false;
    },
    [deletePlanPackage.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.refresh = true;
        state.loading = false;
      }
    },
    [deletePlanPackage.rejected]: (state) => {
      // state.loading = false;
      state.refresh = false;
    },

    // ACTIVATE PLAN PACKAGES
    [activatePlanPackage.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [activatePlanPackage.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.success = "啟用成功";
        state.loading = false;
      }
    },
    [activatePlanPackage.rejected]: (state) => {
      state.loading = false;
    },

    // PUBLISH PLAN PACKAGES
    [publishPlanPackage.pending]: (state) => {
      state.loading = true;
    },
    [publishPlanPackage.fulfilled]: (state, { payload }) => {
      if (payload) {
        if (payload.msg === "success") {
          state.publish = true;
          state.loading = false;
        } else {
          state.publishError = true;
          state.loading = false;
        }
      }
    },
    [publishPlanPackage.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default planSlice.reducer;
export const selectPlans = (state) => state.plans;
export const { updateDetailPlan, removeSuccess, removePublishError } =
  planSlice.actions;
