import InnerLayout from "../Layout/InnerLayout";
import styles from "./style/personalTalent.module.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPersonalTalent,
  putPersonalTalent,
  selectLifeCycle,
} from "../../store/slice/lifeCycle";

import {
  editModeHandler,
  editModeToggleHandler,
  selectLeftNavigate,
} from "../../store/slice/navigate";

import Button from "../Utilities/Button";
import Alert from "../Utilities/Alert";
import Toast from "../Utilities/Toast";

import { MdModeEditOutline } from "react-icons/md";

const PersonalTalent = () => {
  const { loading, success, talentData } = useSelector(selectLifeCycle);
  const { navigateToggle } = useSelector(selectLeftNavigate);
  const dispatch = useDispatch();
  const [sudoku, setSudoku] = useState();
  const [squareType, setSquareType] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isToastShow, setIsToastShow] = useState(false);
  const [editBtnDisable, setEditBtnDisable] = useState(true);
  const [hintShow, setHintShow] = useState({ show: false, id: null });
  const [isMouseOverShow, setIsMouseOverShow] = useState({});

  useEffect(() => {
    dispatch(getPersonalTalent());
  }, [dispatch]);

  useEffect(() => {
    if (talentData) {
      setSudoku(talentData.talent);
    }
  }, [talentData, setSudoku]);

  useEffect(() => {
    const newArr = [];
    if (talentData) {
      const newObj = talentData.employee_category;
      if (newObj) {
        const keys = Object.keys(newObj);
        keys.forEach((item) => {
          newArr.push({
            name: item,
            category_id: newObj[item].category_id,
            description: newObj[item].description,
          });
        });
        setSquareType(newArr);
      }
    }
  }, [talentData, setSquareType]);

  useEffect(() => {
    let count = 0;
    if (sudoku && isEditMode) {
      sudoku.forEach((item) => {
        if (!item.guide_recommend || !item.category) {
          count++;
        }
      });
      if (count === 0) {
        setEditBtnDisable(false);
      } else {
        setEditBtnDisable(true);
      }
    }
  }, [sudoku, isEditMode, setEditBtnDisable]);

  const handleCancelEdit = () => {
    setIsSnackBarOpen(true);
    setIsMouseOverShow({ editModeHover: false });
    dispatch(editModeHandler(true));
  };

  const handleDoneEdit = () => {
    const talent = [];
    sudoku.forEach((item) => {
      talent.push({
        id: item.talent_id,
        title: item.title,
        recommend: item.guide_recommend,
      });
    });
    setIsMouseOverShow({ editModeHover: false });
    dispatch(putPersonalTalent({ talent: talent }));
  };

  useEffect(() => {
    if (success) {
      setIsToastShow(true);
      dispatch(getPersonalTalent());
      setIsEditMode(false);
      setTimeout(() => {
        setIsToastShow(false);
      }, 2000);
    }
  }, [success, setIsToastShow, dispatch]);

  const handleOnInput = (value, index) => {
    let newArr = [...sudoku];
    let newObj = { ...newArr[index] };
    newObj.guide_recommend = value;
    newArr[index] = newObj;
    setSudoku(newArr);
  };

  const handleOnInputName = (value, index) => {
    let newArr = [...sudoku];
    let newObj = { ...newArr[index] };
    newObj.title = value;
    newArr[index] = newObj;
    setSudoku(newArr);
  };

  const getColor = (idx) => {
    if (idx === 0 || idx === 1 || idx === 4) {
      return "Green";
    } else if (idx === 2 || idx === 5) {
      return "Yellow";
    } else if (idx === 3) {
      return "Grey";
    } else if (idx === 6 || idx === 7) {
      return "DarkGrey";
    } else if (idx === 8) {
      return "Pink";
    }
  };

  const getBorderColor = (idx) => {
    if (idx === 0 || idx === 1 || idx === 4) {
      return {
        border: "2px solid rgba(52, 199, 89, 0.3)",
      };
    } else if (idx === 2 || idx === 5) {
      return {
        border: "2px solid rgba(255, 204, 0, 0.3)",
      };
    } else if (idx === 3) {
      return {
        border: "2px solid  rgba(221, 221, 221, 0.3)",
      };
    } else if (idx === 6 || idx === 7) {
      return {
        border: "2px solid rgba(180, 180, 180, 0.3)",
      };
    } else if (idx === 8) {
      return {
        border: "2px solid  rgba(255, 45, 85, 0.3)",
      };
    }
  };

  return (
    <InnerLayout>
      <div>
        <div className={styles.personalTalentOuter}>
          <div className={styles.header}>
            <div className={styles.title}>人才九宮格</div>
          </div>
          <div className={styles.layout}>
            <div className={styles.typeHeadOuter}>
              <div className={styles.typeTitle}>員工類型</div>
              {!isEditMode && (
                <div
                  className={styles.penOuter}
                  onClick={() => {
                    setIsEditMode(true);
                    dispatch(editModeHandler(true));
                  }}
                  onMouseOver={() =>
                    setIsMouseOverShow({ editModeHover: true })
                  }
                  onMouseLeave={() =>
                    setIsMouseOverShow({ editModeHover: false })
                  }>
                  <Button icon={<MdModeEditOutline />} type='quaternary' />
                  {sudoku && !sudoku[0].guide_recommend && (
                    <div className={styles.redDot}></div>
                  )}
                  {isMouseOverShow.editModeHover && (
                    <div className={styles.hoverStyle}>編輯</div>
                  )}
                </div>
              )}
              {isEditMode && (
                <div className={styles.editModeOuter}>
                  <Button
                    title='取消'
                    type='subsidiary'
                    onClick={handleCancelEdit}
                  />
                  <Button
                    title='完成編輯'
                    disable={editBtnDisable}
                    type='primary'
                    onClick={handleDoneEdit}
                  />
                </div>
              )}
            </div>
            {loading && (
              <div
                style={{ width: "5vw", marginTop: "30vh", marginLeft: "32vw" }}>
                <svg
                  role='status'
                  className='text-white animate-spin'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='#E5E7EB'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentColor'
                  />
                </svg>
              </div>
            )}
            {!loading && (
              <div className={styles.squareBlock}>
                <div className={styles.squareType}>
                  {squareType?.map((item) => (
                    <React.Fragment key={item.category_id}>
                      <div
                        className={styles.squareOuter}
                        onMouseOver={() =>
                          setIsMouseOverShow({
                            id: item.category_id,
                            squareHover: true,
                          })
                        }
                        onMouseLeave={() =>
                          setIsMouseOverShow({
                            id: null,
                            squareHover: false,
                          })
                        }>
                        <div
                          className={styles.square}
                          style={{
                            background: item.name.includes("潛力")
                              ? "rgba(255, 204, 0, 0.3)"
                              : item.name.includes("磐石")
                              ? "rgba(52, 199, 89, 0.3)"
                              : item.name.includes("老化")
                              ? "rgba(221, 221, 221, 0.3)"
                              : item.name.includes("老化")
                              ? "rgba(221, 221, 221, 0.3)"
                              : item.name.includes("風險")
                              ? "rgba(180, 180, 180, 0.3)"
                              : item.name.includes("關懷")
                              ? "rgba(255, 45, 85, 0.3)"
                              : "",
                          }}></div>
                        <div className={styles.typeName}>{item.name}</div>
                        {item.category_id === isMouseOverShow.id &&
                          isMouseOverShow.squareHover && (
                            <div className={styles.hoverStyle}>
                              {item.description}
                            </div>
                          )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.leftSide}>
                    <span className={styles.yVariable} style={{ top: "34px" }}>
                      KPI
                    </span>
                    <span>高</span>
                    <span>中</span>
                    <span>低</span>
                  </div>
                  <div className={styles.main}>
                    {sudoku?.map((item, idx) => (
                      <div className={styles.unitBorder} key={idx}>
                        {!isEditMode && (
                          <div
                            className={`${styles.unit} ${
                              styles[`color${getColor(idx)}`]
                            }`}
                            onMouseOver={() => {
                              setHintShow({ show: true, id: item.talent_id });
                            }}
                            onMouseLeave={() => {
                              setHintShow({ show: false, id: null });
                            }}>
                            <div className={styles.title}>{item.title}</div>
                            {item.guide_recommend &&
                              hintShow.show &&
                              item.talent_id === hintShow.id && (
                                <div className={styles.hint}>
                                  <div className={styles.hintTitle}>
                                    績效改善（KPI）引導建議
                                  </div>
                                  <div className={styles.hintSubtitle}>
                                    {item.guide_recommend}
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                        {isEditMode && (
                          <div
                            className={styles.unit}
                            style={getBorderColor(idx)}>
                            <div className={styles.editOuter}>
                              <input
                                className={styles.inputTitle}
                                type='text'
                                placeholder='請輸入'
                                value={item.title}
                                onChange={(e) =>
                                  handleOnInputName(e.target.value, idx)
                                }
                              />
                              <textarea
                                className={styles.inputDescription}
                                type='text'
                                placeholder='請輸入績效改善（KPI）引導建議...'
                                value={item.guide_recommend}
                                onChange={(e) =>
                                  handleOnInput(e.target.value, idx)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className={styles.bottomSide}>
                      <span>低</span>
                      <span>中</span>
                      <span>高</span>
                      <span className={styles.xVariable}>ASK</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Alert
        mainTitle='跳過正在編輯項目？'
        subTitle='系統可能不會儲存你所做的變更'
        rightButtonText='跳過'
        leftButtonText='繼續編輯'
        isOpen={isSnackBarOpen || (isEditMode && navigateToggle)}
        onClose={() => {
          setIsSnackBarOpen(false);
          dispatch(editModeToggleHandler(false));
          setIsMouseOverShow({ editModeHover: false });
        }}
        onConfirm={() => {
          setIsEditMode(false);
          setIsSnackBarOpen(false);
          setIsMouseOverShow({ editModeHover: false });
          dispatch(editModeHandler(false));
          dispatch(editModeToggleHandler(false));
        }}
      />
      <Toast title='員工類型已儲存' show={isToastShow} />
    </InnerLayout>
  );
};

export default PersonalTalent;
