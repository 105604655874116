import axios from "axios";
import { logOut } from "../utils";

export async function sendApiRequest(method, url, payload, isImage) {
  const getCookie = `; ${document.cookie}`;
  const getToken = getCookie.split(`; token=`);
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_URL ?? "") + url,
  };

  if (payload && !isImage) settings.data = JSON.stringify(payload);

  if (payload && isImage) {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    settings.data = formData;
  }

  if (token && !isImage) {
    settings.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else if (token && isImage) {
    settings.headers = {
      "Content-Type": "application/www-x-form-urlencoded",
      Authorization: "Bearer" + token,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  }

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    console.error("Error when calling API: ", err?.response);
    return err?.response;
  }
}

export async function sendApiClientRequest(method, url, payload, isImage) {
  const getCookie = `; ${document.cookie}`;
  const getToken = getCookie.split(`; token=`);
  const token = getToken[1];

  const settings = {
    method: method,
    url: (process.env.REACT_APP_CLIENT_API_URL ?? "") + url,
  };

  if (payload && !isImage) settings.data = JSON.stringify(payload);

  if (payload && isImage) {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    settings.data = formData;
  }

  if (token && !isImage) {
    settings.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else if (token && isImage) {
    settings.headers = {
      "Content-Type": "application/www-x-form-urlencoded",
      Authorization: "Bearer" + token,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  }

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    if (err?.response?.status === 401) {
      logOut();
    }
    return err?.response;
  }
}
