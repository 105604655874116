import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { CheckMarkCircle } from "../../icons";
import styles from "./style/Auth.module.scss";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  postEmailToken,
  selectWorkspaceListsSlice,
  postEmailPwd,
} from "../../store/slice/workspaceList";

import { passwordRegex } from "../../utils";

import Logo from "../../assets/Logo.png";

const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
    reset,
  } = useForm();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const workspace = useSelector(selectWorkspaceListsSlice);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isFormFail, setIsFormFail] = useState(false);
  const [stepCount, setStepCount] = useState(1);

  useEffect(() => {
    if (token) {
      dispatch(postEmailToken({ token: token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (workspace.postEmailTokenData.error === true) {
      setInputDisabled(true);
    }
  }, [workspace.postEmailTokenData]);

  const navigate = useNavigate();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const handleConfirmPassword = (confirmPassword) => {
    const password = getValues("password");
    return password === confirmPassword;
  };

  const onSubmit = (data) => {
    dispatch(postEmailPwd({ token: token, password: data.password }));
    reset();
  };
  useEffect(() => {
    if (workspace.postEmailPwdLoading === "doneLoading") {
      setStepCount(2);
    }
  }, [workspace.postEmailPwdLoading, setStepCount]);

  return (
    <div className={styles.authBox}>
      <div className={styles.logoContainer}>
        <img
          src={Logo}
          alt='Logo'
          styles={{ boxShadow: "0px 4px 56px #FFFFFF", background: "#FFFFFF" }}
        />
      </div>

      <div className={styles.formBox}>
        <div className={styles.formContainer}>
          <div>
            <div className={styles.titleBottom}>設定密碼</div>

            {/* Stepper */}
            <div className={styles.stepper}>
              <div className={styles.stepperLine} />
              <div
                className={
                  stepCount === 1
                    ? styles.stepperActivationCircle
                    : stepCount > 1
                      ? styles.stepperFinishedCircle
                      : styles.stepperCircle
                }>
                1
              </div>

              <div
                className={
                  stepCount === 2
                    ? styles.stepperActivationCircle
                    : styles.stepperCircle
                }>
                2
              </div>
            </div>

            {/*  */}
            {stepCount === 1 && (
              <form
                className={styles.loginForm}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formTitle}>請填寫新密碼</div>
                <div className={styles.passwordContent}>
                  <input
                    disabled={inputDisabled}
                    placeholder='請輸入密碼'
                    maxLength={20}
                    type={isPasswordVisible ? "text" : "password"}
                    {...register("password", {
                      onChange: () => {
                        clearErrors();
                        setIsFormFail(false);
                      },
                      required: "請再次輸入密碼",
                      minLength: {
                        value: 8,
                        message:
                          "須為大/小英文字母與數字、特殊符號８～２０碼組合",
                      },
                      pattern: {
                        value: passwordRegex,
                        message:
                          "須為大/小英文字母與數字、特殊符號８～２０碼組合",
                      },
                    })}
                    className={
                      errors.password && isFormFail
                        ? styles.errorInput
                        : styles.nonErrorInput
                    }
                  />

                  <div className={styles.passwordContent}>
                    {isPasswordVisible ? (
                      <AiFillEyeInvisible
                        onClick={() => setIsPasswordVisible(false)}
                        cursor='pointer'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setIsPasswordVisible(true)}
                        cursor='pointer'
                      />
                    )}
                  </div>
                </div>
                {errors.password && isFormFail && (
                  <p>{errors.password?.message}</p>
                )}

                <div className={styles.passwordContent}>
                  <input
                    disabled={inputDisabled}
                    placeholder='確認密碼'
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    {...register("confirmPassword", {
                      onChange: () => {
                        clearErrors();
                        setIsFormFail(false);
                      },
                      required: "確認密碼",
                      validate: {
                        confirm: (e) => handleConfirmPassword(e) || "密碼不符",
                      },
                    })}
                    className={
                      errors.confirmPassword && isFormFail
                        ? styles.errorInput
                        : styles.nonErrorInput
                    }
                  />
                  <div className={styles.passwordContent}>
                    {isConfirmPasswordVisible ? (
                      <AiFillEyeInvisible
                        onClick={() => setIsConfirmPasswordVisible(false)}
                        cursor='pointer'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setIsConfirmPasswordVisible(true)}
                        cursor='pointer'
                      />
                    )}
                  </div>
                </div>
                {errors.confirmPassword && isFormFail && (
                  <p>{errors.confirmPassword?.message}</p>
                )}
                <div className={styles.passwordAlert}>長度至少 8 個字元</div>
                <div className={styles.passwordAlert}>包含大 / 小英文字母</div>
                <div className={styles.passwordAlert}>
                  至少一個數字或符號(例如：# % $ @ ……)
                </div>
                <div className={styles.inputContent}>
                  <button
                    className={styles.btnDefault}
                    type='submit'
                    disabled={inputDisabled}
                    onClick={() => setIsFormFail(true)}>
                    設定完成
                  </button>
                </div>
                {inputDisabled && <div className={styles.emailExpired}>驗證信已過期</div>}
              </form>
            )}
            {stepCount === 2 && (
              <div className={styles.confirmContent}>
                <CheckMarkCircle className={styles.checkMarkIcon} />
                <h2>
                  密碼修改完成，
                  <br />
                  請至登入頁重新登入。
                </h2>
                <button
                  className={styles.btnDefault}
                  onClick={() => navigate("/login")}>
                  返回登入頁
                  {workspace?.postEmailPwdLoading === "loading" && (
                    <svg
                      role='status'
                      className='inline mr-3 w-4 h-4 text-white animate-spin'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='#E5E7EB'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentColor'
                      />
                    </svg>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
