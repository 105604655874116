import styles from './style/layout.module.scss';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import EmptyBox from '../Utilities/EmptyBox';
import Button from '../Utilities/Button';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { assignModalClose } from '../../store/slice/workspaceStates';
import { selectWorkspaceListsSlice, putEnterpriseAssignedWorkspace, putAssignedData } from '../../store/slice/workspaceList';

const AssignTableAll = ({ workspaceId, setTabValue, userAssigned, setSearchTerm }) => {
  const [listId, setListId] = useState(null);
  const dispatch = useDispatch();
  const workspace = useSelector(selectWorkspaceListsSlice);

  const handleAssigned = (data) => {
    if (!userAssigned.isUser) {
      dispatch(
        putEnterpriseAssignedWorkspace({
          center_workspace_id: workspaceId,
          enterprise_id: data.enterprise_id,
          is_cancel: 0,
        })
      );
    } else {
      dispatch(
        putAssignedData({
          center_workspace_id: workspaceId,
          enterprise_id: data.enterprise_id,
          is_cancel: 0,
          user_id: userAssigned.userId,
        })
      );
    }
  };

  const handleCancelAssigned = (data) => {
    if (!userAssigned.isUser) {
      dispatch(
        putEnterpriseAssignedWorkspace({
          center_workspace_id: workspaceId,
          enterprise_id: data.enterprise_id,
          is_cancel: 1,
        })
      );
    } else {
      dispatch(
        putAssignedData({
          center_workspace_id: workspaceId,
          enterprise_id: data.enterprise_id,
          is_cancel: 1,
          user_id: userAssigned.userId,
        })
      );
    }
  };

  const handleDetail = (data) => {
    if (data.enterprise_id === listId) {
      setListId('');
    } else {
      setListId(data.enterprise_id);
    }
  };
  return (
    <React.Fragment>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <>
              <tr>
                <th>公司名稱</th>
                <th>統一編號</th>
                <th>建立時間</th>
                <th>產業</th>
                <th>員工人數</th>
                <th></th>
                <th></th>
              </tr>
            </>
          </thead>

          {workspace?.assignedListData?.data?.length > 0 ? (
            <tbody>
              {workspace?.assignedListData?.data?.map((data) => (
                <React.Fragment key={data.enterprise_id}>
                  <tr>
                    <td className={styles.tableTd}>{data.enterprise_name}</td>
                    <td className={styles.tableTd}>{data.tax_id}</td>
                    <td className={styles.tableTd}>{data.build_at}</td>
                    <td className={styles.tableTd}>{data.industry}</td>
                    <td className={styles.tableTd}>{data.headcount}</td>
                    <td className={styles.tableTd}>
                      {!userAssigned.isUser && data.assign.workspace !== '-' && (
                        <Button
                          type='secondary'
                          title={'取消指派'}
                          onClick={() => handleCancelAssigned(data)}
                          disabled={workspace?.enterpriseAssignedLoad === 'loading'}
                        />
                      )}
                      {!userAssigned.isUser && data.assign.workspace === '-' && (
                        <Button
                          type='tertiary'
                          title={'指派'}
                          onClick={() => handleAssigned(data)}
                          disabled={workspace?.enterpriseAssignedLoad === 'loading'}
                        />
                      )}
                      {userAssigned?.isUser && Array.isArray(data?.assign?.user) && (
                        <Button
                          type={data?.assign?.user?.find((user) => userAssigned?.userId === user) ? 'secondary' : 'tertiary'}
                          title={data?.assign?.user?.find((user) => userAssigned?.userId === user) ? '取消指派' : '指派'}
                          onClick={() =>
                            data?.assign?.user?.find((user) => userAssigned?.userId === user) ? handleCancelAssigned(data) : handleAssigned(data)
                          }
                          disabled={workspace?.putAssignedDataLoad === 'loading'}
                        />
                      )}

                      {userAssigned.isUser && data?.assign?.user === '-' && (
                        <Button
                          type='tertiary'
                          title={'指派'}
                          onClick={() => handleAssigned(data)}
                          disabled={workspace?.putAssignedDataLoad === 'loading'}
                        />
                      )}
                    </td>
                    <td className={styles.tableTd} style={{ cursor: 'pointer' }} onClick={() => handleDetail(data)}>
                      {data.enterprise_id === listId ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                    </td>
                  </tr>

                  {data.enterprise_id === listId && (
                    <tr>
                      <td colSpan='7'>
                        <div className={styles.details}>
                          <div>
                            <div className={styles.wrapContainer}>
                              <div className={styles.detailsListContainer}>
                                <div className={styles.detailsListTitle}>電子郵件</div>
                                <div className={styles.detailsList}>{data.email}</div>
                              </div>
                              <div className={styles.detailsListContainer}>
                                <div className={styles.detailsListTitle}>電話</div>
                                <div className={styles.detailsList}>{data.phone}</div>
                              </div>
                              <div className={styles.detailsListContainer}>
                                <div className={styles.detailsListTitle}>手機門號</div>
                                <div className={styles.detailsList}>{data.cell_phone}</div>
                              </div>
                              <div className={styles.detailsListContainer}>
                                <div className={styles.detailsListTitle}>職稱</div>
                                <div className={styles.detailsList}>{data.job}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan='7'>{workspace?.assignedListLoad === 'doneLoading' && <EmptyBox caption={'目前尚無任何付費的企業帳號'} />}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className={styles.fixedContainer}>
        <div className={styles.listCount}>共 {workspace?.assignedListData?.data?.length} 項</div>
        <div className={styles.btnContainer}>
          <div className={styles.mr}></div>
          <Button
            type='primary'
            title={'關閉'}
            onClick={() => {
              dispatch(assignModalClose());
              setTabValue(0);
              setSearchTerm('');
              setListId(null);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssignTableAll;
