import { useState } from 'react';
import styles from './style/Table.module.scss';
import { IoChevronDown } from 'react-icons/io5';
import { IoMdArrowDropdown } from 'react-icons/io';

const Table = ({ header, children, type, onChange, reset, fetching, disabled, fullArrow = false }) => {
  const [arrowIndex, setArrowIndex] = useState({ sortby: '', arrow: false });

  const handleArrowChange = (value) => {
    if (fetching) return;
    if (value !== arrowIndex.sortby) {
      const result = { sortby: value, arrow: false };
      setArrowIndex(result);
      onChange(result);
    } else {
      const result = { ...arrowIndex, arrow: !arrowIndex.arrow };
      setArrowIndex(result);
      onChange(result);
    }
  };

  function handleBtnImgClassName(value) {
    let className = `${styles['btn-img']}`;
    if (arrowIndex.sortby === value && !reset && arrowIndex.arrow) {
      className += ` ${styles['rotate180']}`;
    } else {
      className += ` ${styles['rotate0']}`;
    }
    return className;
  }

  return (
    <table className={`${styles.tableComponent} ${type === 'condensed' && styles.condensed}`}>
      <thead>
        <tr>
          {header?.map((data, index) => (
            <th
              key={index}
              width={`${data.width}%`}
              onClick={() => data.arrow && !disabled && handleArrowChange(data.value)}
              style={{
                borderRadius: index === 0 ? '10px 0px 0px 10px' : index === header.length - 1 ? ' 0px 10px 10px 0px' : '0',
              }}
            >
              <div className={`${data.arrow && styles.arrow} ${data.value === arrowIndex.sortby && !reset && styles.titleFocus}`}>
                {data.title}
                <div className={handleBtnImgClassName(data.value)}>{data.arrow && (fullArrow ? <IoMdArrowDropdown /> : <IoChevronDown />)}</div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      {children}
    </table>
  );
};

export default Table;
