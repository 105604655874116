import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { sendGetClientRequest, sendPutClientRequest } from '../../api/helper';

// 週期列表 Get
export const getEnterpriseStrategy = createAsyncThunk('lifeCycle/getEnterpriseStrategy', async (parameters) => {
  let responseUrl;
  if (parameters.cycle_id) {
    responseUrl = `/api/enterprise_strategy/references?cycle_id=${parameters.cycle_id}`;
  } else if (!parameters.cycle_id) {
    responseUrl = `/api/enterprise_strategy/references`;
  }
  const response = await sendGetClientRequest(responseUrl);
  return response;
});

// 週期列表 PUT修改
export const putEnterpriseStrategy = createAsyncThunk('user/putEnterpriseStrategy', async (parameters) => {
  const response = await sendPutClientRequest(`/api/enterprise_strategy/references/${parameters.cycle_id}`, parameters);
  return response;
});

// 分數區間(GET)
export const getStrategyScore = createAsyncThunk('user/getStrategyScore', async (parameters) => {
  const response = await sendGetClientRequest(`/api/enterprise_strategy/check_score`, parameters);
  return response;
});

// 修改分數區間 (PUT)
export const putStrategyScore = createAsyncThunk('user/putStrategyScore', async (parameters) => {
  const response = await sendPutClientRequest(`/api/enterprise_strategy/score_setting`, parameters);
  return response;
});

// 預覽試題 GET
export const getQuestions = createAsyncThunk('user/getQuestions', async (parameters) => {
  const response = await sendGetClientRequest(`/api/enterprise_strategy/questions`, parameters);
  return response;
});

// 編輯試題 PUT
export const putQuestions = createAsyncThunk(`user/putQuestions`, async (parameters) => {
  const response = await sendPutClientRequest(`/api/enterprise_strategy/questions/${parameters.cycle_id}`, parameters);
  return response;
});

// PAEI GET 文獻列表
export const getPaeiReference = createAsyncThunk(`user/getPaeiReference`, async (parameters) => {
  const response = await sendGetClientRequest(`/api/paei/references/?type=${parameters.type}`, parameters);
  return response;
});

// PAEI PUT修改 文獻列表
export const putPaeiReference = createAsyncThunk(`user/putPaeiReference`, async (parameters) => {
  const response = await sendPutClientRequest(`/api/paei/references`, parameters);
  return response;
});

// paei課程建議列表 GET
export const getPaeiRecommendCorse = createAsyncThunk(`user/getPaeiRecommendCorse`, async (parameters) => {
  const response = await sendGetClientRequest(`/api/paei/recommend_course`, parameters);
  return response;
});

// paei編輯課程建議列表 PUT
export const putPaeiRecommendCorse = createAsyncThunk(`user/putPaeiRecommendCorse`, async (parameters) => {
  const response = await sendPutClientRequest(`/api/paei/recommend_course`, parameters);
  return response;
});

// PAEI 性向列表 GET
export const getPaeiAptitude = createAsyncThunk(`user/getPaeiAptitude`, async (parameters) => {
  const response = await sendGetClientRequest(`/api/paei/aptitude`, parameters);
  return response;
});

// PAEI 編輯性向列表 PUT
export const putPaeiAptitude = createAsyncThunk(`user/putPaeiAptitude`, async (parameters) => {
  const response = await sendPutClientRequest(`/api/paei/aptitude`, parameters);
  return response;
});

// 人才九宮格列表 GET
export const getPersonalTalent = createAsyncThunk(`user/getPersonalTalent`, async (parameters) => {
  const response = await sendGetClientRequest(`/api/talent`, parameters);
  return response;
});

// 人才九宮格列表 PUT
export const putPersonalTalent = createAsyncThunk(`user/putPersonalTalent`, async (parameters) => {
  const response = await sendPutClientRequest(`/api/talent`, parameters);
  return response;
});

const lifeCycleSlice = createSlice({
  name: 'lifeCycle',
  initialState: {
    loading: false,
    message: '',
    success: false,
    scoreLoading: false,
    testData: [],
    testLoading: false,
    PaeiData: [],
    aptitudeData: {},
    talentData: {},
  },
  reducers: {
    updateCycle: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;
    },
    removeLoading: (state) => {
      state.scoreLoading = false;
      state.testLoading = false;
      state.success = false;
    },
  },
  extraReducers: {
    // GET Enterprise Strategy
    [getEnterpriseStrategy.pending]: (state) => {
      state.loading = true;
      state.message = '';
    },
    [getEnterpriseStrategy.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.loading = false;
        state.message = payload.data;
      }
    },
    [getEnterpriseStrategy.rejected]: (state) => {
      state.loading = false;
    },

    // Put Enterprise Strategy
    [putEnterpriseStrategy.pending]: (state) => {
      state.loading = true;
      state.message = '';
      state.success = false;
    },
    [putEnterpriseStrategy.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.success = true;
      }
    },
    [putEnterpriseStrategy.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    },

    // get Score settings
    [getStrategyScore.pending]: (state) => {
      state.loading = true;
      state.message = '';
    },
    [getStrategyScore.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.scoreData = payload.data;
      }
    },
    [getStrategyScore.rejected]: (state) => {
      state.loading = false;
    },

    // putStrategyScore
    [putStrategyScore.pending]: (state) => {
      state.scoreLoading = true;
    },
    [putStrategyScore.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.scoreLoading = 'done';
      }
    },
    [putStrategyScore.rejected]: (state) => {
      state.scoreLoading = false;
    },

    // getQuestions
    [getQuestions.pending]: (state) => {
      state.loading = true;
    },
    [getQuestions.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.testData = payload.data;
      }
    },
    [getQuestions.rejected]: (state) => {
      state.loading = false;
    },

    // putQuestions
    [putQuestions.pending]: (state) => {
      state.testLoading = true;
    },
    [putQuestions.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.testLoading = 'done';
      }
    },
    [putQuestions.rejected]: (state) => {
      state.testLoading = false;
    },

    // 取得文獻列表
    [getPaeiReference.pending]: (state) => {
      state.loading = true;
    },
    [getPaeiReference.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.PaeiData = payload.data;
      }
    },
    [getPaeiReference.rejected]: (state) => {
      state.loading = false;
    },

    // 編輯 文獻列表
    [putPaeiReference.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [putPaeiReference.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.success = true;
      }
    },
    [putPaeiReference.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    },

    // getPaeiRecommendCorse
    [getPaeiRecommendCorse.pending]: (state) => {
      state.loading = true;
    },
    [getPaeiRecommendCorse.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.courseData = payload.data;
      }
    },
    [getPaeiRecommendCorse.rejected]: (state) => {
      state.loading = false;
    },

    // putPaeiRecommendCorse
    [putPaeiRecommendCorse.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [putPaeiRecommendCorse.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.success = true;
      }
    },
    [putPaeiRecommendCorse.rejected]: (state) => {
      state.success = false;
      state.loading = false;
    },

    // get 性向設定
    [getPaeiAptitude.pending]: (state) => {
      state.loading = true;
    },
    [getPaeiAptitude.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.aptitudeData = payload.data;
      }
    },
    [getPaeiAptitude.rejected]: (state) => {
      state.loading = false;
    },

    // put 性向設定
    [putPaeiAptitude.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [putPaeiAptitude.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.success = true;
      }
    },
    [putPaeiAptitude.rejected]: (state) => {
      state.success = false;
      state.loading = false;
    },

    // getPersonalTalent
    [getPersonalTalent.pending]: (state) => {
      state.loading = true;
    },
    [getPersonalTalent.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.talentData = payload.data;
      }
    },
    [getPersonalTalent.rejected]: (state) => {
      state.loading = false;
    },

    // putPersonalTalent
    [putPersonalTalent.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [putPersonalTalent.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
        state.success = true;
      }
    },
    [putPersonalTalent.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    },
  },
});

export default lifeCycleSlice.reducer;
export const selectLifeCycle = (state) => state.lifeCycle;
export const { updateCycle, removeLoading } = lifeCycleSlice.actions;
