import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  sendGetClientRequest,
  sendPatchClientRequest,
  sendPostClientRequest,
} from "../../../api/helper";

export const getMenu = createAsyncThunk("menu/getMenu", async () => {
  const response = await sendGetClientRequest(`/api/software-services`);
  return response.data;
});

export const getDetail = createAsyncThunk(
  "menu/getDetail",
  async (parameters) => {
    const response = await sendGetClientRequest(
      parameters.group_plans
        ? `/api/software-services/${parameters.service}?group_plans=${parameters.group_plans}`
        : `/api/software-services/${parameters.service}`
    );
    return response.data;
  }
);

// 設定服務負責人
export const postRepresentativeUser = createAsyncThunk(
  "menu/postRepresentativeUser",
  async (parameters) => {
    const response = await sendPostClientRequest(
      `/api/software-services/${parameters.service}/representative?user_email=${parameters.user_email}`
    );
    return response;
  }
);

export const updateDetail = createAsyncThunk(
  "menu/updateDetail",
  async (parameters) => {
    const postObj = {
      activate_mode: parameters.activate_mode,
      status: parameters.status,
    };
    const response = await sendPatchClientRequest(
      `/api/software-services/${parameters.service}`,
      postObj
    );
    return response;
  }
);

export const updateDetailSetting = createAsyncThunk(
  "menu/updateDetailSetting",
  async (parameters) => {
    const postObj = {
      activate_mode: parameters.activate_mode,
      status: parameters.status,
    };
    const response = await sendPatchClientRequest(
      `/api/software-services/${parameters.service}`,
      postObj
    );
    return response;
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menu: [],
    menuNew: [],
    detail: null,
    loading: false,
    updateLoading: false,
    success: false,
    postError: false,
    error: true,
  },
  reducers: {
    removeSuccess: (state) => {
      state.success = false;
    },
    removePostError: (state) => {
      state.postError = false;
    },
  },
  extraReducers: {
    // GET MENU
    [getMenu.pending]: (state) => {
      state.loading = true;
    },
    [getMenu.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.menu = payload;
        state.loading = false;
      }
    },
    [getMenu.rejected]: (state) => {
      state.loading = false;
    },

    // GET DETAIL
    [getDetail.pending]: (state) => {
      state.loading = true;
    },
    [getDetail.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.detail = payload;
        state.loading = false;
      }
    },
    [getDetail.rejected]: (state) => {
      state.loading = false;
    },

    // SET REPRESENT USER
    [postRepresentativeUser.pending]: (state) => {
      state.updateLoading = true;
    },
    [postRepresentativeUser.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.updateLoading = false;
        state.success = true;
      }
    },
    [postRepresentativeUser.rejected]: (state) => {
      state.updateLoading = false;
    },

    // UPDATE DETAIL
    [updateDetail.pending]: (state) => {
      state.loading = true;
    },
    [updateDetail.fulfilled]: (state, { payload }) => {
      if (payload?.msg === "success") {
        state.success = true;
      } else {
        state.postError = true;
      }
      state.loading = false;
    },
    [updateDetail.rejected]: (state) => {
      state.loading = false;
    },

    // UPDATE DETAIL SETTING
    [updateDetailSetting.pending]: (state) => {
      state.loading = true;
    },
    [updateDetailSetting.fulfilled]: (state, { payload }) => {
      if (payload.msg === "success") {
        state.loading = false;
      }
    },
    [updateDetailSetting.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default menuSlice.reducer;
export const selectMenu = (state) => state.menu;
export const { removeSuccess, removePostError } = menuSlice.actions;
