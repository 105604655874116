import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import { MdClear, MdHelp } from "react-icons/md";
import { BiTrashAlt } from "react-icons/bi";

import styles from "./style/Dashboard.module.scss";
import Modal from "../Utilities/Modal";
import Button from "../Utilities/Button";
// import ToggleSwitch from "../Utilities/ToggleSwitch";
import TextField from "../Utilities/TextField";

import {
  createPlanPackageNew,
  selectPlans,
} from "../../store/slice/manager/plans";
import { ClipLoader } from "react-spinners";
import Datepicker from "../Utilities/Datepicker/Datepicker";
import { toChineseNumber } from "../../utils";

const ModalCreatePlan = ({
  createPlan,
  setCreatePlan,
  setStatus,
  setToastValue,
  restart,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { newPlanLoading } = useSelector(selectPlans);
  const modalRef = useRef(null);

  //trial plan state
  const [error, setError] = useState(false);
  const [planTitle, setPlanTitle] = useState("");
  const [planDate, setPlanDate] = useState({ startDay: "", endDay: "" });
  const [isTipShow, setIsTipShow] = useState(false);

  const [isTrialPlanChecked, setIsTrialPlanChecked] = useState(false);
  const [maxUsers, setMaxUsers] = useState("");
  const [validityDays, setValidityDays] = useState("");

  const [planList, setPlanList] = useState([
    {
      name: "Basic",
      users_count: 0,
      validity_days: 0,
      rental_fee: 0,
      discount: 100,
      span: 0,
    },
    {
      name: "Pro",
      users_count: 0,
      validity_days: 0,
      rental_fee: 0,
      discount: 100,
      span: 0,
    },
  ]);

  // const handleSwitchTrialPlan = () => {
  //   if (isTrialPlanChecked) {
  //     setIsTrialPlanChecked(false);
  //     setValidityDays("");
  //     setMaxUsers("");
  //   } else {
  //     setIsTrialPlanChecked(true);
  //   }
  // };

  const handleStartDay = (date, startDay) => {
    const day = new Date(date);
    return format(
      day.setDate(day.getDate() - (startDay ? 1 : 0)),
      "yyyy-MM-dd"
    );
  };

  const handlePlanListChange = (index, type, value) => {
    setPlanList((e) => {
      e[index][type] = type === "name" ? value : +value;
      return [...e];
    });
  };

  const handleDeleteCard = (index) => {
    setPlanList((e) => {
      return [...e.filter((_, cardIndex) => cardIndex !== index)];
    });
  };

  const checkPlanListError = () => {
    let error = false;
    planList.forEach((e) => {
      if (error) return;
      if (!e.name || e.name === "") error = true;
      if (!e.users_count || e.users_count <= 0) error = true;
      if (!e.validity_days || e.validity_days <= 0) error = true;
      if (!e.rental_fee || e.rental_fee <= 0) error = true;
      if (
        (!e.discount && e.rental_fee !== 0) ||
        e.discount < 0 ||
        e.discount > 100
      )
        error = true;
      if (!e.span || e.span <= 0) error = true;
    });
    return error;
  };

  return (
    <Modal isOpen={createPlan} size='lg' height={`calc(100vh - 200px)`}>
      <div className={styles.ModalContainer} ref={modalRef}>
        {newPlanLoading ? (
          <div className={styles.bigLoading}>
            <ClipLoader size={60} />
          </div>
        ) : (
          <>
            <div className={styles.modalHeader}>
              <div className={styles.modalTitle}>新增方案組合</div>
              <div className={styles.modalClose}>
                <Button
                  type={"tertiary"}
                  icon={<MdClear />}
                  color={"#5856D6"}
                  onClick={() => {
                    setCreatePlan(false);
                    setPlanTitle("");

                    setIsTrialPlanChecked(false);
                    setMaxUsers(null);
                    setValidityDays(null);
                  }}
                />
              </div>
            </div>
            <div className={`${styles.modalBody}  ${styles.createNewPlan}`}>
              <div className={styles.planTitleContainer}>
                <div className={styles.planTitle}>
                  方案組合名稱
                  {!planTitle && error && (
                    <div className={styles.titleErr}>方案組合名稱不可為空</div>
                  )}
                </div>
                <TextField
                  placeholder={"請輸入"}
                  error={!planTitle && error}
                  onChange={(e) => {
                    setPlanTitle(e);
                  }}
                  value={planTitle || ""}
                />

                <div className={styles.planPeriod}>
                  方案時間
                  <Datepicker
                    selectDate={planDate.startDay}
                    setSelectDate={(e) =>
                      setPlanDate({ ...planDate, startDay: e })
                    }
                    startYear={`${handleStartDay(new Date()).split("-")[0]}`}
                    disableEvents={[
                      {
                        start: `${handleStartDay(new Date())}`,
                        end: `${
                          planDate.endDay !== ""
                            ? planDate.endDay
                            : "2100-12-31"
                        }`,
                      },
                    ]}
                    error={error && !planDate.startDay}
                  />
                  {/* 至
              <Datepicker
                selectDate={planDate.endDay}
                setSelectDate={(e) =>
                  setPlanDate({ ...planDate, endDay: e })
                }
                startYear={`${handleStartDay(new Date()).split("-")[0]}`}
                disableEvents={[
                  {
                    start: `${
                      planDate.startDay !== ""
                        ? handleStartDay(planDate.startDay, true)
                        : handleStartDay(new Date())
                    }`,
                    end: `2100-12-31`,
                  },
                ]}
              /> */}
                </div>
              </div>
              {/* start試用方案 */}
              {/* <div
                className={styles.TrialPlan}
                style={{
                  boxShadow: isTrialPlanChecked
                    ? "0px 4px 20px rgba(88, 86, 214, 0.16)"
                    : null,
                }}>
                <div className={styles.toggle}>
                  <ToggleSwitch
                    isChecked={isTrialPlanChecked}
                    onChange={handleSwitchTrialPlan}
                  />
                  <div className={`${styles.title}`}>試用方案 (非必要)</div>
                </div>
                <div className={styles.limit}>
                  <div
                    className={`${styles.title} ${
                      !isTrialPlanChecked && styles.disabled
                    }`}>
                    試用人數上限
                  </div>
                  <TextField
                    placeholder='請輸入 (須大於 0)'
                    width='100%'
                    isDisabled={!isTrialPlanChecked}
                    numeric={true}
                    value={maxUsers ? maxUsers : ""}
                    onChange={(e) => setMaxUsers(e)}
                    error={
                      (!maxUsers || maxUsers <= 0) &&
                      error &&
                      isTrialPlanChecked
                    }
                    min={1}
                  />
                  {(!maxUsers || maxUsers <= 0) &&
                    error &&
                    isTrialPlanChecked && (
                      <div className={styles.errorMessage}>
                        人數上限不可為空
                      </div>
                    )}
                </div>
                <div className={styles.limit}>
                  <div
                    className={`${styles.title} ${
                      !isTrialPlanChecked && styles.disabled
                    }`}>
                    試用天數
                  </div>
                  <TextField
                    placeholder='請輸入 (須大於 0)'
                    width='100%'
                    isDisabled={!isTrialPlanChecked}
                    numeric={true}
                    value={validityDays ? validityDays : ""}
                    onChange={(e) => setValidityDays(e)}
                    error={
                      (!validityDays || validityDays <= 0) &&
                      error &&
                      isTrialPlanChecked
                    }
                    min={1}
                  />
                  {(!validityDays || validityDays <= 0) &&
                    error &&
                    isTrialPlanChecked && (
                      <div className={styles.errorMessage}>
                        試用天數不可為空
                      </div>
                    )}
                </div>
              </div> */}
              {/* 試用方案end */}
              <div className={styles.planLayout}>
                {planList.map((planData, index) => (
                  <div key={index} className={styles.planCard}>
                    <div className={styles.planCardTop}>
                      <div className={styles.planCardTitle}>
                        方案{toChineseNumber(index + 1)}
                      </div>
                      <Button
                        type='pure'
                        icon={<BiTrashAlt />}
                        color={"#ff766e"}
                        disabled={
                          index === 0 && planList.length === 1 ? true : false
                        }
                        onClick={() => handleDeleteCard(index)}
                      />
                    </div>
                    <div className={styles.planCardMid}>
                      <div className={styles.planCardSelectTitleLeft}>
                        方案名稱
                      </div>
                      <TextField
                        placeholder={"請輸入"}
                        width='200px'
                        error={!planData.name && error}
                        onChange={(e) => {
                          handlePlanListChange(index, "name", e);
                        }}
                        value={planData.name || ""}
                      />
                      <div className={styles.planCardSelectTitleMid}>
                        基本人數
                      </div>
                      <TextField
                        placeholder='請輸入'
                        width='170px'
                        numeric={true}
                        value={planData.users_count || ""}
                        onChange={(e) => {
                          handlePlanListChange(index, "users_count", e);
                        }}
                        error={
                          (!planData.users_count ||
                            planData.users_count <= 0) &&
                          error
                        }
                        min={1}
                      />
                      <div className={styles.planCardSelectTitleRight}>
                        綁約天數
                      </div>
                      <TextField
                        placeholder='請輸入'
                        width='150px'
                        numeric={true}
                        value={planData.validity_days || ""}
                        onChange={(e) => {
                          // handlePlanListChange(index, "validity_days", e);
                          handlePlanListChange(0, "validity_days", e);
                          handlePlanListChange(1, "validity_days", e);
                        }}
                        error={
                          (!planData.validity_days ||
                            planData.validity_days <= 0) &&
                          error
                        }
                        min={1}
                      />
                    </div>
                    <div
                      className={styles.planCardMid}
                      style={{ margin: "-5px 0" }}>
                      <div className={styles.planCardSelectTitleLeft} />
                      <div
                        className={styles.errorMessage}
                        style={{ width: "200px" }}>
                        {!planData.name && error && "未輸入方案名稱"}
                      </div>
                      <div className={styles.planCardSelectTitleMid} />
                      <div
                        className={styles.errorMessage}
                        style={{ width: "170px" }}>
                        {(!planData.users_count || planData.users_count <= 0) &&
                          error &&
                          "未輸入基本人數"}
                      </div>
                      <div className={styles.planCardSelectTitleRight} />
                      <div
                        className={styles.errorMessage}
                        style={{ width: "150px" }}>
                        {(!planData.validity_days ||
                          planData.validity_days <= 0) &&
                          error &&
                          "未輸入綁約天數"}
                      </div>
                    </div>
                    <div className={styles.planCardBottom}>
                      <div className={styles.planCardSelectTitleLeft}>
                        費用
                        <span style={{ fontSize: "10px", marginLeft: "3px" }}>
                          每人/綁約時間 (元)
                        </span>
                      </div>
                      <TextField
                        placeholder='請輸入'
                        width='200px'
                        numeric={true}
                        value={planData.rental_fee || ""}
                        onChange={(e) => {
                          handlePlanListChange(index, "rental_fee", e);
                        }}
                        error={
                          (!planData.rental_fee || planData.rental_fee <= 0) &&
                          error
                        }
                        min={1}
                      />
                      <div className={styles.planCardSelectTitleMid}>
                        折扣(%)
                        <div className={styles.helpBtnOuter}>
                          <Button
                            type='pure'
                            icon={<MdHelp />}
                            onMouseEnter={() => setIsTipShow(index)}
                            onMouseLeave={() => setIsTipShow(false)}
                            color={"#B4B4B4"}
                          />
                          {isTipShow === index && (
                            <div className={styles.tips}>
                              <span>設定 100% ，完全無折扣，</span>
                              <span>設定 0%，整筆折扣掉，付款金額為0。</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <TextField
                        placeholder='請輸入'
                        width='170px'
                        numeric={true}
                        isDisabled
                        value={planData.discount || ""}
                        onChange={(e) => {
                          // handlePlanListChange(index, "discount", e);
                          handlePlanListChange(0, "discount", e);
                          handlePlanListChange(1, "discount", e);
                        }}
                        error={
                          (!planData.discount ||
                            planData.discount > 100 ||
                            planData.discount < 0) &&
                          error
                        }
                        min={0}
                        max={100}
                      />
                      <div className={styles.planCardSelectTitleRight}>
                        增加帳號數級距
                      </div>
                      <TextField
                        placeholder='請輸入'
                        width='150px'
                        numeric={true}
                        value={planData.span || ""}
                        onChange={(e) => {
                          handlePlanListChange(index, "span", e);
                        }}
                        error={(!planData.span || planData.span <= 0) && error}
                        min={1}
                      />
                    </div>
                    <div
                      className={styles.planCardBottom}
                      style={{ margin: "-5px 0" }}>
                      <div className={styles.planCardSelectTitleLeft} />
                      <div
                        className={styles.errorMessage}
                        style={{ width: "200px" }}>
                        {(!planData.rental_fee || planData.rental_fee <= 0) &&
                          error &&
                          "未輸入費用"}
                      </div>
                      <div className={styles.planCardSelectTitleMid} />
                      <div
                        className={styles.errorMessage}
                        style={{ width: "170px" }}>
                        {(!planData.discount ||
                          planData.discount > 100 ||
                          planData.discount < 0) &&
                          error &&
                          `${
                            planData.discount > 100 || planData.discount < 0
                              ? "請輸入 0 ~ 100 之間"
                              : "未輸入折扣"
                          }`}
                      </div>
                      <div className={styles.planCardSelectTitleRight} />
                      <div
                        className={styles.errorMessage}
                        style={{ width: "150px" }}>
                        {(!planData.span || planList[index].span <= 0) &&
                          error &&
                          "未輸入增加帳號數級距"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={styles.modalFooter}
              style={{ backgroundColor: "#ffffff", zIndex: 3 }}>
              <div className={styles.errorImply}>
                <Button
                  type='tertiary'
                  title={"新增方案"}
                  onClick={() =>
                    setPlanList([
                      ...planList,
                      {
                        name: "",
                        users_count: 0,
                        validity_days: 0,
                        rental_fee: 0,
                        discount: 0,
                        span: 0,
                      },
                    ])
                  }
                />
              </div>
              <Button
                type='tertiary'
                title={"完成"}
                onClick={() => {
                  if (
                    planTitle !== "" &&
                    planDate.startDay !== "" &&
                    (!isTrialPlanChecked ||
                      (isTrialPlanChecked &&
                        maxUsers > 0 &&
                        validityDays > 0)) &&
                    !checkPlanListError()
                  ) {
                    let data = {
                      service: params.id,
                      type: "group_by_plan",
                      name: planTitle,
                      publish_date: format(planDate.startDay, "yyyy-MM-dd"),
                      plans: [],
                    };
                    if (planDate.endDay !== "") {
                      data.publish_end_date = format(
                        planDate.endDay,
                        "yyyy-MM-dd"
                      );
                    }
                    planList.forEach((e, index) => {
                      data.plans.push({
                        plan: ["keystone.core.basic", "keystone.core.pro"][
                          index
                        ],
                        name: e.name,
                        validity_days: e.validity_days,
                        users_count_upper_limit: e.users_count,
                        additional_people_per_unit: e.span,
                        rental_fee: e.rental_fee,
                        discount: 100 - e.discount,
                      });
                    });
                    dispatch(createPlanPackageNew(data)).then((e) => {
                      if (e.payload.msg === "success") {
                        restart();
                      }
                    });
                    setStatus("create");
                    setError(false);
                    setCreatePlan(false);
                    setPlanTitle("");
                    setIsTrialPlanChecked(false);
                    setMaxUsers(null);
                    setValidityDays(null);
                  } else {
                    setError(true);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalCreatePlan;
