import styles from "./style/layout.module.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

import {
  postNewWorkspaceUserV2,
  selectWorkspaceListsSlice,
} from "../../store/slice/workspaceList";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../utils";

import Button from "../Utilities/Button";

const WorkspaceUserModal = ({ isOpen, setCreateUserModal, workspaceId }) => {
  // DISPATCH
  const dispatch = useDispatch();
  // SELECTOR
  const workspace = useSelector(selectWorkspaceListsSlice);
  // STATES
  const [isFormFail, setIsFormFail] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({});

  const onSubmit = (data) => {
    dispatch(
      postNewWorkspaceUserV2({
        workspace: workspaceId,
        user_name: data.name,
        user_email: data.email,
      })
    );
  };

  useEffect(() => {
    if (workspace?.success) {
      setCreateUserModal(false);
      clearErrors();
      reset();
    } else if (workspace?.error) {
      setEmailRegistered(true);
      setIsFormFail(true);
    }
  }, [
    workspace?.success,
    workspace?.error,
    clearErrors,
    reset,
    setCreateUserModal,
  ]);

  return (
    <div id='myModal' className={isOpen ? styles.userModalOpen : styles.modal}>
      <div className={styles.modalheaderContainer}>
        <div className={styles.headerTitle}>新增使用者</div>
        <div
          className={styles.closeModalOuter}
          onClick={() => {
            setCreateUserModal(false);
            reset();
          }}>
          <AiOutlineClose className={styles.closeModal} />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.modalInputOuter}>
          <div className={styles.inputTitle}>姓名</div>
          <input
            disabled={workspace?.loading}
            placeholder='請輸入姓名'
            {...register("name", {
              onChange: () => {
                clearErrors();
                setIsFormFail(false);
              },
              required: "請輸入姓名",
            })}
            className={errors.name ? styles.errorInput : styles.input}
          />
          {errors.name?.message && (
            <p className={styles.errorMsg}>{errors.name?.message}</p>
          )}
          <div className={styles.inputTitle}>電子郵件</div>
          <input
            disabled={workspace?.loading}
            placeholder='請輸入電子信箱'
            {...register("email", {
              onChange: () => {
                clearErrors();
                setIsFormFail(false);
                setEmailRegistered(false);
              },
              required: "請輸入電子信箱",
              pattern: {
                value: emailRegex,
                message: "請輸入正確格式",
              },
            })}
            className={
              errors.email && isFormFail ? styles.errorInput : styles.input
            }
          />
          {errors.email?.message && (
            <p className={styles.errorMsg}>{errors.email?.message}</p>
          )}
          {emailRegistered && isFormFail && (
            <p className={styles.errorMsg}>此用戶已加入workspace</p>
          )}
        </div>
        <div className={styles.footerBtn}>
          <Button
            type={"secondary"}
            title={"新增"}
            disabled={workspace?.loading}
            onClick={() => setIsFormFail(true)}
          />
        </div>
      </form>
    </div>
  );
};
export default WorkspaceUserModal;
