import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAnalysis,
  selectDashboard,
} from "../../store/slice/tdp/dashboard";

import styles from "./style/DashboardPlanAnalysisModal.module.scss";

import { MdPieChart, MdClose } from "react-icons/md";
import Dropdown from "../Utilities/DropdownDynamic";
import Table from "../Utilities/Table";
import EmptyBox from "../Utilities/EmptyBox";
import PieChart from "../Utilities/PieChart";
import Modal from "../Utilities/Modal";

const DashboardPlanAnalysisModal = ({
  isPlanAnalysisModalOpen,
  setIsPlanAnalysissModalOpen,
}) => {
  const dispatch = useDispatch();
  const { filter, analysis } = useSelector(selectDashboard);

  const [dropdownItem, setDropdownItem] = useState([]);
  const [dropdownVal, setDropDownVal] = useState("");

  useEffect(() => {
    let array = [
      {
        text: `所有 (${filter.all})`,
        value: "all",
      },
      {
        text: `啟用中 (${filter.activate})`,
        value: "activate",
      },
      {
        text: `已停用 (${filter.unactivate})`,
        value: "unactivate",
      },
    ];
    setDropdownItem(array);
    setDropDownVal({
      text: `所有 (${filter.all})`,
      value: "all",
    });
  }, [dispatch, filter]);

  useEffect(() => {
    if (dropdownVal) {
      let activate = "";
      if (dropdownVal.value === "all") {
        activate = "";
      } else if (dropdownVal.value === "activate") {
        activate = "true";
      } else if (dropdownVal.value === "unactivate") {
        activate = "false";
      }

      const postObj = {
        activate: activate,
      };
      dispatch(getDashboardAnalysis(postObj));
    }
  }, [dispatch, dropdownVal]);

  //點擊圓餅圖 - 顯示右側內容
  const [clickEvent, setClickEvent] = useState("");
  const [eventLength, setEventLength] = useState(0);
  const [chartData, setChartData] = useState([]);

  //chart color
  const [chartColor, setChartColor] = useState([
    "rgba(142, 225, 231, 1)",
    "rgba(207, 242, 173, 1)",
    "rgba(140, 157, 202, 1)",
    "rgba(207, 163, 241, 1)",
    "rgba(246, 227, 156, 1)",
    "rgba(226, 139, 133, 1)",
  ]);

  //table header
  const tableHeader = [
    {
      title: "公司名稱",
      value: "enterprise_name",
      width: "80",
    },
    {
      title: "人數",
      value: "headcount",
      width: "20",
    },
  ];

  //chart資料結構
  useEffect(() => {
    let arr = [];
    analysis?.rows?.forEach((item) => {
      arr.push({ value: item.ratio, dimension: item.services_name });
    });
    setChartData(arr);
  }, [analysis]);

  //clickEvent 長度判斷
  useEffect(() => {
    if (clickEvent) {
      let data = analysis?.rows
        ?.find((list) => list.services_name === clickEvent)
        .enterprise_list.filter((filtered) =>
          dropdownVal?.text?.includes("所有")
            ? filtered.activate_status === 0 || filtered.activate_status === 1
            : dropdownVal?.text?.includes("啟用")
            ? filtered.activate_status === 1
            : filtered.activate_status === 0
        );
      setEventLength(data.length);
    }
  }, [clickEvent, dropdownVal, analysis]);

  return (
    <Modal isOpen={isPlanAnalysisModalOpen} size='lg'>
      <div className={styles.planAnalysisOuter}>
        <div className={styles.header}>
          <div className={styles.title}>
            <MdPieChart className={styles.icon} />
            <span>方案使用分析</span>
          </div>
          <div
            className={styles.close}
            onClick={() => setIsPlanAnalysissModalOpen(false)}>
            <MdClose className={styles.icon} style={{ fontSize: "22px" }} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.planPercentage}>
            <div className={styles.planHeader}>
              <div className={styles.planTitle}>方案使用佔比</div>
              <div className={styles.planDropdown}>
                <Dropdown
                  listItems={dropdownItem}
                  value={dropdownVal?.text}
                  caption={"text"}
                  onChange={(e) => setDropDownVal(e)}
                />
              </div>
            </div>
            <div className={styles.planBody}>
              <div className={styles.chartOuter}>
                {chartData.length > 0 && (
                  <PieChart
                    data={chartData}
                    backgroundColor={chartColor}
                    legendDisplay={false}
                    setClickEvent={setClickEvent}
                    tableData={analysis}
                    setChartColor={setChartColor}
                    dropdownVal={dropdownVal}
                  />
                )}
              </div>
              <div className={styles.tableOuter}>
                <table>
                  <thead>
                    <tr>
                      <th widhth='72%'>方案</th>
                      <th width='20%'>數量</th>
                      <th width='8%'>佔比</th>
                    </tr>
                  </thead>
                  <tbody>
                    {analysis?.rows?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <div
                              className={styles.dot}
                              style={{
                                background: `${chartColor[idx]}`,
                              }}></div>
                            {item.services_name}
                          </td>
                          <td style={{ color: "#666666" }}>{item.total}</td>
                          <td
                            style={{ color: "#666666" }}>{`${item.ratio.toFixed(
                            0
                          )}%`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={styles.planContent}>
            <div className={styles.content}>目前顯示內容為：</div>
            {!clickEvent && <div className={styles.contentNoClick}>-</div>}

            {clickEvent && (
              <div className={styles.contentClickTitle}>{`${
                dropdownVal?.text?.split(" (")[0]
              }的「${clickEvent}」`}</div>
            )}
            <div className={styles.contentTitle}>
              {clickEvent
                ? `符合的公司列表（${eventLength}）`
                : "符合的公司列表"}
            </div>
            <Table header={tableHeader}>
              {clickEvent && (
                <tbody>
                  {analysis?.rows
                    ?.find((list) => list.services_name === clickEvent)
                    // filter dropdown的資料
                    .enterprise_list.filter((filtered) =>
                      dropdownVal?.text?.includes("所有")
                        ? filtered.activate_status === 0 ||
                          filtered.activate_status === 1
                        : dropdownVal?.text?.includes("啟用")
                        ? filtered.activate_status === 1
                        : filtered.activate_status === 0
                    )
                    // 把filter過的資料map出來
                    .map((item, idx) => (
                      <tr key={idx}>
                        {tableHeader.map((headerTitle, headerIdx) => (
                          <td key={`table-${headerIdx}`}>
                            {item[headerTitle.value]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  {eventLength === 0 && (
                    <tr>
                      <td colSpan='7'>
                        <EmptyBox caption='尚無符合的公司列表' type={1} />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {/* non click empty box */}
              {!clickEvent && (
                <tbody>
                  <tr>
                    <td colSpan='7'>
                      <EmptyBox
                        caption='點擊圓餅圖查看使用該方案的所有公司'
                        type={4}
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DashboardPlanAnalysisModal;
