import { useEffect, useRef, useState } from 'react';
import styles from './style/Tips.module.scss';

const Tips = (props) => {
  const [displayTip, setDisplayTip] = useState(false);
  const [tipPosition, setTipPosition] = useState(0);
  const containerRef = useRef(null);
  const { topDiff = 0, leftDiff = 0, penetrate, maxWidth } = props;

  useEffect(() => {
    if (containerRef.current) {
      const dom = containerRef.current.getBoundingClientRect();
      setTipPosition(dom.height);
    }
  }, [containerRef]);

  return (
    <div ref={containerRef} style={{ position: 'relative' }} onMouseEnter={() => setDisplayTip(true)} onMouseLeave={() => setDisplayTip(false)}>
      {props.children}
      {displayTip && (
        <div
          className={`${styles.tips} ${penetrate ? styles.penetrate : ''}`}
          style={{
            top: `${tipPosition + topDiff}px`,
            transform: `translateX(${-40 + leftDiff}%)`,
            maxWidth: `${maxWidth}`,
          }}
        >
          {props.detail ? props.detail : ''}
        </div>
      )}
    </div>
  );
};

export default Tips;
