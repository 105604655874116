import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';

import './style/DatePicker.css';
import { HiChevronLeft, HiChevronRight, HiChevronDown } from 'react-icons/hi';
import { MdToday } from 'react-icons/md';

/* IncludeDisableEvent example */
// const datePickerDisableEvents = [
//   [
//     {
//       start: `${new Date().getFullYear() - 1}-12-31T00:00:00+05:00`,
//       end: `${new Date().getFullYear()}-03-31T00:00:00+05:00`
//     }
//   ],
// ];

const Datepicker = ({ selectDate, setSelectDate, disableEvents, error, disabled, startYear, minDate }) => {
  const [startDate, setStartDate] = useState('');
  const [selectYear, setSelectYear] = useState(false);
  const [contentHeight, setContentHeight] = useState(null);
  const years = range(startYear || 2023, getYear(new Date()) + 10, 1);

  const disableFunction = (events) => {
    if (events) {
      const disabledDateRanges = events.map((range) => ({
        start: new Date(range.start),
        end: new Date(range.end),
      }));
      return disabledDateRanges;
    }
  };

  useEffect(() => {
    if (selectDate) {
      setStartDate(new Date(selectDate));
    } else if (selectDate === '') {
      setStartDate('');
    }
  }, [selectDate]);

  //抓取月曆數字高度
  useEffect(() => {
    let dateHeight = document.querySelector('.react-datepicker__month');
    let contentHeight = dateHeight?.getBoundingClientRect().height;
    setContentHeight(contentHeight);
  }, [selectYear]);

  const handleCalendarClose = () => {
    setSelectYear(false);
    setContentHeight(null);
  };

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return (
    <div
      className='datepicker-container'
      style={{
        border: error ? '1px solid red' : '1px solid #eeeeee',
        opacity: disabled ? 0.4 : 1,
      }}
    >
      <DatePicker
        placeholderText='請選擇'
        onCalendarClose={handleCalendarClose}
        dateFormat='yyyy-M-dd'
        includeDateIntervals={disableFunction(disableEvents)}
        disabled={disabled}
        renderCustomHeader={({
          date,
          changeYear,
          // changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            className='header'
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'space-between',
              height: '24px',
            }}
          >
            <div
              className='react-datepicker-month-select'
              onClick={() => {
                setSelectYear(!selectYear);
              }}
            >
              <span>{getYear(date)}</span>
              <HiChevronDown
                style={{
                  color: '#215DF6',
                  transition: '0.5s',
                  transform: selectYear ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                opacity: selectYear ? 0 : 1,
                transition: '0.1s',
              }}
            >
              <div className='select-Bgc'>
                <HiChevronLeft onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{ color: '#215DF6' }} />
              </div>
              <div className='month-name'>{months[getMonth(date)]}</div>
              <div className='select-Bgc'>
                <HiChevronRight onClick={increaseMonth} disabled={nextMonthButtonDisabled} style={{ color: '#215DF6' }} />
              </div>
            </div>
            {contentHeight && (
              <div className='selectYearContainer'>
                <div className='selectYearWrapper'>
                  <div
                    className='year-select'
                    style={{
                      height: contentHeight + 32,
                      opacity: selectYear ? 1 : 0,
                      transition: '0.1s',
                      display: selectYear ? 'flex' : 'none',
                    }}
                  >
                    {years.map((option) => (
                      <div
                        className='year-data'
                        key={option}
                        onClick={() => {
                          changeYear(option);
                          setSelectYear(false);
                        }}
                        style={{
                          backgroundColor: getYear(date) === option ? '#215df6' : null,
                          color: getYear(date) === option ? '#FFFFFF' : null,
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        minDate={minDate}
        selected={startDate}
        onChange={(date) => {
          if (date) {
            setStartDate(new Date(date));
            setSelectDate(new Date(date));
          } else {
            setStartDate('');
            setSelectDate('');
          }
        }}
      />
      <MdToday className='icon' />
    </div>
  );
};

export default Datepicker;
