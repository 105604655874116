import styles from "./style/Modal.module.scss";

const Modal = ({ isOpen, children, size, height, width }) => {
  return (
    <div id='myModal' className={isOpen ? styles.modalOpen : styles.modal}>
      <div
        className={
          size === "xl"
            ? styles.modalContentXl
            : size === "lg"
            ? styles.modalContentLg
            : size === "md"
            ? styles.modalContentMd
            : styles.modalContentSm
        }
        style={{ height: height ? height : "", width: width ? width : "" }}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
