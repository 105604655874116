import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { sendGetClientRequest } from '../../api/helper';

export const getEnterpriseList = createAsyncThunk('user/getEnterpriseList', async (parameters) => {
  let responseUrl;
  if (parameters.service && parameters.keyword && parameters.currentPage && parameters.limit) {
    responseUrl = `/api/enterprise_management?keyword=${parameters.keyword}&currentPage=${parameters.currentPage}&limit=${parameters.limit}&service=${parameters.service}`;
  } else if (parameters.service && parameters.currentPage && parameters.limit) {
    responseUrl = `/api/enterprise_management?currentPage=${parameters.currentPage}&limit=${parameters.limit}&service=${parameters.service}`;
  } else if (parameters.keyword && parameters.currentPage && parameters.limit) {
    responseUrl = `/api/enterprise_management?currentPage=${parameters.currentPage}&limit=${parameters.limit}&keyword=${parameters.keyword}`;
  } else if (parameters.currentPage && parameters.limit) {
    responseUrl = `/api/enterprise_management?currentPage=${parameters.currentPage}&limit=${parameters.limit}`;
  }
  const response = await sendGetClientRequest(responseUrl);
  return response;
});

export const getEnterpriseManagement = createAsyncThunk('user/getEnterpriseManagement', async (parameters) => {
  const response = await sendGetClientRequest(`/api/enterprise_management/${parameters.enterprise_id}`, parameters);
  return response.data;
});

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: {
    enterpriseListsData: [],
    enterpriseListsLoading: 'notLoading',
    service_lists: [],
    active_service: '全部',
  },
  reducers: {
    updateActiveService: (state, action) => {
      state.active_service = action.payload;
    },
  },
  extraReducers: {
    // GET ENTERPRISE LISTS
    [getEnterpriseList.pending]: (state) => {
      state.enterpriseListsData = [];
      state.enterpriseListsLoading = 'loading';
    },
    [getEnterpriseList.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.enterpriseListsData = payload.data;
        state.enterpriseListsLoading = 'doneLoading';
      } else {
        state.enterpriseListsData = [];
        state.enterpriseListsLoading = 'loadingfailed';
      }
    },
    [getEnterpriseList.rejected]: (state) => {
      state.enterpriseListsData = [];
      state.enterpriseListsLoading = 'notLoading';
    },

    // GET ENTERPRISE SERVICE MANAGEMENT
    [getEnterpriseManagement.pending]: (state) => {
      state.loading = true;
    },
    [getEnterpriseManagement.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.service_lists = payload.data;
        state.loading = false;
      }
    },
    [getEnterpriseManagement.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export default enterpriseSlice.reducer;
export const selectEnterprise = (state) => state.enterpriseLists;
export const { updateActiveService } = enterpriseSlice.actions;
