import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  sendGetClientRequest,
  sendPatchClientRequest,
} from "../../../api/helper";

export const getPayment = createAsyncThunk(
  "payment/getPayment",
  async (service) => {
    const response = await sendGetClientRequest(
      `/api/software-services/${service}/payment-methods`
    );
    return response.data;
  }
);

export const updatePayment = createAsyncThunk(
  "payment/updatePayment",
  async (method) => {
    const response = await sendPatchClientRequest(
      `/api/payment-methods/${method}`,
      { enable: true }
    );
    return response;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payment: [],
    success: false,
    loading: false,
  },
  extraReducers: {
    // GET PAYMENT
    [getPayment.pending]: (state) => {
      state.loading = true;
    },
    [getPayment.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.payment = payload;
        state.loading = false;
      }
    },
    [getPayment.rejected]: (state) => {
      state.loading = false;
    },

    // UPDATE PAYMENT
    [updatePayment.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updatePayment.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.success = true;
        state.loading = false;
      }
    },
    [updatePayment.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default paymentSlice.reducer;
export const selectPayment = (state) => state.payment;
