import styles from './style/layout.module.scss';
import InnerLayout from '../Layout/InnerLayout';
import AlertSwitch from '../Utilities/AlertSwitch';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  getDetail,
  removeSuccess,
  removePostError,
  selectMenu,
  updateDetail,
  updateDetailSetting,
  postRepresentativeUser,
} from '../../store/slice/manager/menu';
import { updateActiveService } from '../../store/slice/enterpriseLists';
import { emailRegex } from '../../utils';

import Radio from '../Utilities/Radio';
import SnackBar from '../Utilities/Alert';
import Toast from '../Utilities/Toast';
import moment from 'moment';
import Button from '../Utilities/Button';
import TextField from '../Utilities/TextField';

import { MdClear, MdDone } from 'react-icons/md';

const Dashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSwitch, setIsSwitch] = useState(false);
  const [startService, setStartService] = useState(false);
  const [type, setType] = useState('');
  const [isRepresentativeUserSetting, setIsRepresentativeUserSetting] = useState(false);
  const [representativeUserEmail, setRepresentativeUserEmail] = useState('');
  const [representativeUserEmailError, setRepresentativeUserEmailError] = useState(false);

  // toast status
  const [status, setStatus] = useState('');

  const { detail, success, updateLoading, postError } = useSelector(selectMenu);

  useEffect(() => {
    setStartService(false);
    dispatch(getDetail({ service: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (detail) {
      setType(detail.activate_mode);
      setIsSwitch(detail.status);
    }
  }, [detail]);

  useEffect(() => {
    if (success || postError) {
      dispatch(getDetail({ service: params.id }));
      setIsRepresentativeUserSetting(false);
      setRepresentativeUserEmail('');
    }

    setTimeout(() => {
      dispatch(removeSuccess());
    }, 1200);
    setTimeout(() => {
      setStatus('');
      dispatch(removePostError());
    }, 1500);
  }, [dispatch, success, params.id, status, postError]);

  const handleSuccess = () => {
    const postObj = {
      service: detail.id,
      activate_mode: type,
      status: !isSwitch,
    };
    dispatch(updateDetail(postObj));
    setStatus('serviceStatus');
    setStartService(false);
  };

  const changeMode = (mode) => {
    const postObj = {
      service: detail.id,
      activate_mode: mode,
      status: isSwitch,
    };
    dispatch(updateDetailSetting(postObj));
    setType(mode);
  };

  const handleSetRepresentativeUser = () => {
    if (representativeUserEmail.match(emailRegex)) {
      setRepresentativeUserEmailError(false);
      dispatch(
        postRepresentativeUser({
          service: detail.id,
          user_email: representativeUserEmail.replace('+', '%2B'),
        })
      );
      setStatus('representUser');
    } else {
      setRepresentativeUserEmailError(true);
    }
  };

  useEffect(() => {
    if (representativeUserEmailError) {
      if (representativeUserEmail.match(emailRegex)) {
        setRepresentativeUserEmailError(!representativeUserEmailError);
      }
    }
  }, [representativeUserEmailError, representativeUserEmail]);

  return (
    <InnerLayout>
      <div className={styles.dashboard}>
        <div className={styles.dashboardRight}>
          <div className={styles.dashboardTitle}>
            {detail && (
              <>
                {detail?.name} / {detail?.name_local}
              </>
            )}
          </div>
          <div className={styles.dashboardSettings}>
            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>服務啟用狀態</div>
              <div className={styles.dashboardSettingssContent}>
                <AlertSwitch isChecked={isSwitch} onChange={() => setStartService(true)} />
                <div className={styles.startService}>{isSwitch ? '啟用中' : '停用中'}</div>
              </div>
            </div>
            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>啟用日期</div>
              <div className={styles.dashboardSettingssContent}>
                {detail?.status && detail?.status_updated_at ? moment(detail?.status_updated_at).format('YYYY/MM/DD') : '-'}
              </div>
            </div>
            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>停用日期</div>
              <div className={styles.dashboardSettingssContent}>
                {detail?.status === false && detail?.status_updated_at ? moment(detail?.status_updated_at).format('YYYY/MM/DD') : '-'}
              </div>
            </div>
            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>啟用中企業帳號總數</div>
              <div className={styles.dashboardSettingssContent}>
                <div className={styles.wrapper}>
                  {detail?.enterprises_using_service_count}
                  <div className={styles.buttonWrapper}>
                    <Button
                      type='tertiary'
                      title={'查看'}
                      onClick={() => {
                        navigate('/admin/company-system');
                        dispatch(updateActiveService(detail?.alias));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>總使用人數</div>
              <div className={styles.dashboardSettingssContent}>{detail?.users_using_service_count}</div>
            </div>

            <div className={styles.dashboardFlex} style={{ display: 'none' }}>
              <div className={styles.dashboardSettingsTitle}>服務負責人設定</div>
              <div className={styles.dashboardSettingssContent}></div>
            </div>

            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>服務開通設定</div>
              <div className={styles.dashboardSettingssContent}>
                <div className={styles.RadioMargin}>
                  <Radio value={type === 'center' ? true : false} onChange={() => changeMode('center')}>
                    由管理者開通
                  </Radio>
                </div>

                <Radio value={type === 'enterprise' ? true : false} onChange={() => changeMode('enterprise')}>
                  由企業主開通
                </Radio>
              </div>
            </div>

            <div className={styles.dashboardFlex}>
              <div className={styles.dashboardSettingsTitle}>負責人</div>
              <div className={styles.dashboardSettingssContent}>
                {detail?.representative_user && !isRepresentativeUserSetting && !updateLoading ? (
                  <div className={styles.representativeUserDetail}>
                    {detail?.representative_user?.email}
                    <Button title={'設定'} type={'tertiary'} onClick={() => setIsRepresentativeUserSetting(true)} />
                  </div>
                ) : !detail?.representative_user && !isRepresentativeUserSetting && !updateLoading ? (
                  <div className={styles.representativeNonSettingOuter}>
                    <div className={styles.nonSettingTitle}>尚未設定</div>
                    <Button title={'設定'} type={'tertiary'} onClick={() => setIsRepresentativeUserSetting(true)} />
                  </div>
                ) : updateLoading ? (
                  <>
                    <Skeleton height={40} width={297} />
                  </>
                ) : (
                  <div className={styles.userSettingInputOuter}>
                    <TextField
                      placeholder={'請輸入負責人的電子郵件'}
                      onChange={(e) => setRepresentativeUserEmail(e)}
                      value={representativeUserEmail ? representativeUserEmail : ''}
                      width={320}
                      error={representativeUserEmailError}
                      disabled={updateLoading}
                    />
                    {representativeUserEmailError && <div className={styles.errorMsg}>請輸入正確的格式。</div>}
                    <div className={styles.btnGroup}>
                      <Button
                        type={'secondary'}
                        icon={<MdClear />}
                        color={'#999'}
                        onClick={() => {
                          setRepresentativeUserEmail('');
                          setIsRepresentativeUserSetting(!isRepresentativeUserSetting);
                          setRepresentativeUserEmailError(false);
                        }}
                        disabled={updateLoading}
                      />
                      <Button
                        type={'secondary'}
                        icon={<MdDone />}
                        disabled={!representativeUserEmail || updateLoading}
                        color={'#34c759'}
                        onClick={handleSetRepresentativeUser}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <SnackBar
              isOpen={startService}
              onConfirm={handleSuccess}
              onClose={() => setStartService(false)}
              rightButtonText={isSwitch ? '停用' : '啟用'}
              leftButtonText='取消'
              mainTitle={isSwitch ? `停用「${detail?.name}於KEYSTONE系統」` : `啟用「${detail?.name}於KEYSTONE系統」`}
            />

            <Toast
              show={success || postError}
              title={
                postError
                  ? `「${detail?.name}」尚有企業帳號租賃中,無法改變服務啟用狀態`
                  : status === 'serviceStatus' && isSwitch
                  ? `已啟用${detail?.name}於KEYSTONE系統`
                  : status === 'serviceStatus' && !isSwitch
                  ? `已停用${detail?.name}於KEYSTONE系統`
                  : status === 'representUser'
                  ? `服務負責人設定完成`
                  : ''
              }
              subtitle={detail?.name}
              error={false}
            />
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Dashboard;
