import styles from "./style/ModalASK.module.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MdEditNote, MdUndo } from "react-icons/md";

import Modal from "../../Utilities/Modal";
import Button from "../../Utilities/Button";
import Table from "../../Utilities/Table";
import Tabs from "../../Utilities/Tabs";
import {
  confirmBatchImport,
  resetSuccess,
  selectASK,
} from "../../../store/slice/tdp/ask";

const ModalImport = ({ status, setStatus, setPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { import_data } = useSelector(selectASK);
  const tabList = ["Attitude", "Soft Skill", "Hard Skill", "Knowledge"];
  const [tabValue, setTabValue] = useState(0);
  const [detail, setDetail] = useState("");

  const headerData = [
    {
      title: "ASK 職能慣性類別",
      width: 25,
    },
    {
      title: "職能項目",
      width: 70,
    },
    {
      title: "",
      width: 5,
    },
  ];

  const subHeaderData = [
    {
      title: "慣性行為層次",
      width: 25,
    },
    {
      title: "慣性行為描述",
      width: 75,
    },
  ];

  const handleCancel = () => {
    setStatus(false);
    dispatch(resetSuccess());
  };

  const handleImport = () => {
    const postObj = {
      cacheID: import_data.cache_id,
    };
    dispatch(confirmBatchImport(postObj));
    dispatch(resetSuccess());
    setDetail("");
    setPage(1);
    navigate("/dashboard/tdp/ask/attitude");
    setTimeout(() => {
      setTabValue(0);
    }, 1000);
  };

  return (
    <Modal size='xl' isOpen={status}>
      <div className={styles.modalHeader}>
        <div className={styles.modalSubHeader}>
          <div className={styles.modalTitle}>上傳資料預覽</div>
          {detail && (
            <Button
              type='tertiary'
              icon={<MdUndo />}
              title='返回上一頁'
              onClick={() => setDetail("")}
            />
          )}
        </div>
      </div>

      <div className={styles.modalBody}>
        {detail ? (
          <>
            <div className={styles.colHalf}>
              <div className={styles.inputWrapper}>
                <label className={styles.labelTitle}>ASK 職能慣性類別</label>
                <span>{detail.category}</span>
              </div>
              <div className={styles.inputWrapper}>
                <label className={styles.labelTitle}>職能項目</label>
                <span>{detail.item}</span>
              </div>
            </div>

            <Table header={subHeaderData}>
              <tbody>
                {detail.ability_ask_item?.map((data, index) => (
                  <tr key={index}>
                    <td width={`${subHeaderData[0].width}%`}>L{data.level}</td>
                    <td width={`${subHeaderData[1].width}%`}>
                      <div className={styles.editWrapper}>
                        {data.content.map((item, idx) => (
                          <React.Fragment key={idx}>
                            <div className={styles.desc}>{item}</div>
                          </React.Fragment>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <div className={styles.tabContainer}>
              <Tabs
                values={tabList}
                onChange={(e) => setTabValue(e.tabIndex)}
                selectedIndex={tabValue}
              />
            </div>

            <Table header={headerData}>
              <tbody>
                {import_data?.data?.length > 0 &&
                  import_data?.data
                    ?.filter(
                      (item) =>
                        (tabValue === 0 && item.category === "A") ||
                        (tabValue === 1 && item.category === "SS") ||
                        (tabValue === 2 && item.category === "HS") ||
                        (tabValue === 3 && item.category === "K")
                    )
                    ?.map((data, index) => (
                      <tr key={index}>
                        <td width={`${headerData[0].width}%`}>
                          {data.category}
                        </td>
                        <td width={`${headerData[1].width}%`}>
                          <span className={styles.textPurple}>{data.item}</span>
                        </td>
                        <td width={`${headerData[2].width}%`}>
                          <Button
                            type='pure'
                            icon={<MdEditNote />}
                            onClick={() => setDetail(data)}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </>
        )}
      </div>

      <div className={`${styles.modalFooter} ${styles.right}`}>
        <span style={{ marginRight: "8px" }}>
          <Button
            type='quaternary'
            title='取消'
            onClick={handleCancel}
            style={{ marginRight: "8px" }}
          />
        </span>
        <Button type='primary' title='載入' onClick={handleImport} />
      </div>
    </Modal>
  );
};

export default ModalImport;
