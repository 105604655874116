import styles from './style/Modal.module.scss';
// import Button from "./Button";
// import IMG_LIST from "../constants/MMS_IMG_LIST";
import { useSelector } from 'react-redux';
import { selectLeftNavigate } from '../../../store/slice/navigate';

const ServiceError = () => {
  const leftNavigate = useSelector(selectLeftNavigate);
  const showLeftNavigate = leftNavigate.toggleOpen;
  return (
    <div className={showLeftNavigate ? styles.serviceError : styles.serviceNavigate}>
      <>
        <p className={styles.title}>KEYSTONE Workspace</p>
        <p className={styles.text1}>歡迎使用 Workspace 服務</p>
        <p className={styles.text2}>為了更好的體驗，請使用大於 1024*768px 的螢幕瀏覽</p>
      </>
    </div>
  );
};

export default ServiceError;
