
import React from 'react';

const CheckMarkCircle = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 5.83784C19.3419 5.83784 5.83784 19.3419 5.83784 36C5.83784 52.6581 19.3419 66.1622 36 66.1622C52.6581 66.1622 66.1622 52.6581 66.1622 36C66.1622 19.3419 52.6581 5.83784 36 5.83784ZM0 36C0 16.1177 16.1177 0 36 0C55.8823 0 72 16.1177 72 36C72 55.8823 55.8823 72 36 72C16.1177 72 0 55.8823 0 36ZM49.1067 20.3208C50.5118 21.1111 51.0101 22.8909 50.2197 24.2959C46.2758 31.3073 36.8552 47.3144 34.9614 50.5291C33.7254 52.6271 30.9102 52.7003 29.456 51.0166L20.6558 40.8268C19.6021 39.6067 19.7369 37.7635 20.957 36.7098C22.1771 35.6561 24.0203 35.791 25.074 37.0111L31.657 44.6335C35.0989 38.7784 41.9406 27.1068 45.1316 21.4338C45.922 20.0288 47.7017 19.5305 49.1067 20.3208Z"
        fill="currentcolor"
      />
    </svg>
  );

}

export default CheckMarkCircle;