import styles from "./style/aptitudePreviewModal.module.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaeiAptitude,
  selectLifeCycle,
} from "../../../store/slice/lifeCycle";

import { MdClose } from "react-icons/md";

import Button from "../../Utilities/Button";

const AptitudePreviewModal = ({ setIsAptitudePreviewModalOpen }) => {
  const dispatch = useDispatch();
  const { aptitudeData } = useSelector(selectLifeCycle);
  const [paeiAptitudeList, setPaeiAptitudeList] = useState([]);

  useEffect(() => {
    dispatch(getPaeiAptitude());
  }, [dispatch]);

  useEffect(() => {
    let newArr = [];
    if (aptitudeData) {
      newArr.push(
        { type: aptitudeData?.type?.P, title: "P" },
        { type: aptitudeData?.type?.A, title: "A" },
        { type: aptitudeData?.type?.E, title: "E" },
        { type: aptitudeData?.type?.I, title: "I" }
      );
      setPaeiAptitudeList(newArr);
    }
  }, [aptitudeData, setPaeiAptitudeList]);

  const borderHandler = (sectionIdx) => {
    let className = styles.testBlock;
    if (sectionIdx === 0) {
      className += " " + styles.firstBlock;
    }
    if (sectionIdx === 1) {
      className += " " + styles.secondBlock;
    }
    if (sectionIdx === 2) {
      className += " " + styles.thirdBlock;
    }
    if (sectionIdx === 3) {
      className += " " + styles.lastBlock;
    }
    return className;
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div>性向總覽</div>
          <Button
            icon={<MdClose />}
            onClick={() => setIsAptitudePreviewModalOpen(false)}
            type='secondary'
            color='#999999'
          />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.bg}>
          <div className={styles.testWrapper}>
            {paeiAptitudeList?.map((section, sectionIdx) => (
              <React.Fragment key={section.title}>
                <div className={`${borderHandler(sectionIdx)}`}>
                  {section?.type?.map((unit, unitIdx) => (
                    <div key={unitIdx} className={styles.unitBlock}>
                      <div className={styles.title}>{unit.chinese}</div>
                      <div className={styles.subtitle}>{unit.english}</div>
                    </div>
                  ))}
                  <div className={styles.bgTitle}>{section.title}</div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AptitudePreviewModal;
