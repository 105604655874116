import styles from './style/TextArea.module.scss';

const TextArea = ({ placeholder, value, onChange, error, height }) => {
  return (
    <textarea
      className={styles.textField}
      placeholder={placeholder ? placeholder : '請輸入'}
      value={value}
      onChange={(e) => onChange(e)}
      style={
        error
          ? {
              border: '1px solid #ff3b30',
              height: height || '100px',
            }
          : { height: height || '100px' }
      }
    />
  );
};

export default TextArea;
