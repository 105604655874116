import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { sendGetClientRequest, sendPostClientRequest, sendDeleteClientRequest, sendPutClientRequest, sendPatchClientRequest } from '../../api/helper';

import { serialize } from '../../utils';

// Get Workspace V2
export const getWorkspaceListV2 = createAsyncThunk('user/getWorkspaceListV2', async (parameters) => {
  const queryObj = {};
  if (parameters) {
    if (parameters.limit) queryObj.limit = parameters.limit;
    if (parameters.page) queryObj.page = parameters.page;
    if (parameters.search) queryObj.search = parameters.search;
  }
  let queryString = '';
  if (JSON.stringify(queryObj) !== '{}') queryString = '?' + serialize(queryObj);

  const response = await sendGetClientRequest(`/api/v2/center/workspaces${queryString}`);
  return response;
});

// workspace啟用 + 停用 V2
export const postWorkspaceActivationV2 = createAsyncThunk('user/postWorkspaceActivationV2', async (parameters) => {
  const response = await sendPatchClientRequest(`/api/v2/center/workspaces/${parameters.workspace}`, parameters);
  return response;
});

// Post New workspace V2
export const postNewWorkspaceV2 = createAsyncThunk('user/postNewWorkspaceV2', async (parameters) => {
  const response = await sendPostClientRequest(`/api/v2/center/workspaces`, parameters);
  return response;
});

// Delete workspace V2
export const deleteWorkspaceV2 = createAsyncThunk('user/deleteWorkspaceV2', async (parameters) => {
  const response = await sendDeleteClientRequest(`/api/center_workspace/delete_workspace/${parameters.workspace}`);
  return response;
});

// Get Workspace UserList V2
export const getWorkspaceUserListV2 = createAsyncThunk('user/getWorkspaceUserListV2', async (parameters) => {
  const queryObj = {};
  if (parameters) {
    if (parameters.limit) queryObj.limit = parameters.limit;
    if (parameters.page) queryObj.page = parameters.page;
    if (parameters.search) queryObj.search = parameters.search;
  }
  let queryString = '';
  if (JSON.stringify(queryObj) !== '{}') queryString = '?' + serialize(queryObj);

  const response = await sendGetClientRequest(`/api/v2/center/workspaces/${parameters.workspace}/workspace-users${queryString}`);
  return response;
});

// POST Workspace New Users V2
export const postNewWorkspaceUserV2 = createAsyncThunk('user/postNewWorkspaceUserV2', async (parameters) => {
  const response = await sendPostClientRequest(`/api/v2/center/workspaces/${parameters.workspace}/workspace-users`, parameters);
  return response;
});

// DELETE Workspace User V2
export const deleteWorkspaceUserV2 = createAsyncThunk('user/deleteWorkspaceUserV2', async (parameters) => {
  const response = await sendDeleteClientRequest(`/api/v2/center/workspace-users/${parameters.workspace_user}`, parameters);
  return response;
});

// Check workspaceUser Email_token
export const postEmailToken = createAsyncThunk('user/postEmailToken', async (parameters) => {
  const response = await sendPostClientRequest(`/api/center_workspace/check_token`, parameters);
  return response;
});

// POST User Verify
export const postEmailPwd = createAsyncThunk('user/postEmailPwd', async (parameters) => {
  const response = await sendPostClientRequest(`/api/center_workspace/verifyUser`, parameters);
  return response;
});

// UPDATE(PUT) workspace Name
export const updateWorkspaceName = createAsyncThunk('user/updateWorkspaceName', async (parameters) => {
  const response = await sendPutClientRequest(`/api/center_workspace/${parameters.center_workspace_id}/rename`, parameters);
  return response;
});

export const getWorkspaceSettingsV2 = createAsyncThunk('user/getWorkspaceSettingsV2', async (parameters) => {
  const response = await sendGetClientRequest(`/api/v2/center/workspaces/${parameters.workspace}/service-permissions?group=1`);
  return response;
});

// POST workspace permission settings
export const postWorkspaceSettingV2 = createAsyncThunk('user/postWorkspaceSettingV2', async (parameters) => {
  const response = await sendPostClientRequest(`/api/v2/center/workspaces/${parameters[0]?.workspace}/service-permissions`, parameters);
  return response;
});

export const getWorkspaceUserSettingsV2 = createAsyncThunk('user/getWorkspaceUserSettingsV2', async (parameters) => {
  const response = await sendGetClientRequest(`/api/v2/center/workspace-users/${parameters.workspace_user}/service-permissions?group=1`);
  return response;
});

// Post 設定 WorkspaceUser 服務管理權限
export const postWorkspaceUserSettingV2 = createAsyncThunk('user/postWorkspaceUserSettingV2', async (parameters) => {
  const response = await sendPostClientRequest(`/api/v2/center/workspace-users/${parameters[0]?.workspace_user}/service-permissions`, parameters);
  return response;
});

// GET workspace Assigned
export const getWorksapceAssignedListAll = createAsyncThunk('user/getWorksapceAssignedListAll', async (parameters) => {
  let responseUrl;
  if (parameters.keyword && parameters.center_workspace_id) {
    responseUrl = `/api/center_workspace/${parameters.center_workspace_id}/assign_workspace_panel?keyword=${parameters.keyword}`;
  } else if (!parameters.keyword && parameters.center_workspace_id) {
    responseUrl = `/api/center_workspace/${parameters.center_workspace_id}/assign_workspace_panel`;
  }
  const response = await sendGetClientRequest(responseUrl);
  return response;
});

// PUT Assigned enterprise to workspace
export const putEnterpriseAssignedWorkspace = createAsyncThunk('user/putEnterpriseAssignedWorkspace', async (parameters) => {
  const response = await sendPutClientRequest(
    `/api/center_workspace/${parameters.center_workspace_id}/assign_to_workspace?enterprise_id=${parameters.enterprise_id}&is_cancel=${parameters.is_cancel}`,
    parameters
  );
  return response;
});

//GET User workspace Assigned List
export const getUserassignedList = createAsyncThunk('user/getUserassignedList', async (parameters) => {
  let responseUrl;
  if (parameters.keyword && parameters.center_workspace_id) {
    responseUrl = `/api/center_workspace/${parameters.center_workspace_id}/assign_user_panel?keyword=${parameters.keyword}`;
  } else if (!parameters.keyword && parameters.center_workspace_id) {
    responseUrl = `/api/center_workspace/${parameters.center_workspace_id}/assign_user_panel`;
  }
  const response = await sendGetClientRequest(responseUrl);
  return response;
});

// PUT Assigned Data
export const putAssignedData = createAsyncThunk('user/putAssignedData', async (parameters) => {
  const response = await sendPutClientRequest(
    `/api/center_workspace/${parameters.center_workspace_id}/assign_to_user?enterprise_id=${parameters.enterprise_id}&user_id=${parameters.user_id}&is_cancel=${parameters.is_cancel}`,
    parameters
  );
  return response;
});

const workspaceListSlice = createSlice({
  name: 'workspaceList',
  initialState: {
    tableLoading: false,
    loading: false,
    success: false,
    error: false,
    isDelete: false,
    isPermissionSuccess: false,
    newWorkspaceData: {},
    enterpriseListsData: [],
    getWorkspaceUserListData: {},
    postEmailTokenData: {},
    postEmailTokenLoading: 'notLoading',
    postEmailPwdLoading: 'notLoading',
    workspaceNameLoading: 'notLoading',
    permissionDataV2: [],
    assignedListData: [],
    assignedListLoad: 'notLoading',
    enterpriseAssignedLoad: 'notLoading',
    userAssignedListDataLoad: 'notLoading',
    userAssignedListData: [],
    putAssignedDataLoad: 'notLoading',
  },
  reducers: {
    removeSuccess: (state) => {
      state.success = false;
      state.isDelete = false;
      state.isPermissionSuccess = false;
    },
  },
  extraReducers: {
    // get workspacelist new V2
    [getWorkspaceListV2.pending]: (state) => {
      state.tableLoading = true;
    },
    [getWorkspaceListV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.workspaceListData = payload;
        state.tableLoading = false;
      }
    },
    [getWorkspaceListV2.rejected]: (state) => {
      state.tableLoading = false;
    },

    // 啟用停用workspace V2
    [postWorkspaceActivationV2.pending]: (state) => {
      state.loading = true;
    },
    [postWorkspaceActivationV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.success = true;
      } else {
        state.loading = false;
      }
    },
    [postWorkspaceActivationV2.rejected]: (state) => {
      state.loading = false;
    },

    // POST New Workspace V2
    [postNewWorkspaceV2.pending]: (state) => {
      state.loaing = true;
    },
    [postNewWorkspaceV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loaing = false;
        state.success = true;
        state.newWorkspaceData = payload.data;
      } else {
        state.loaing = false;
        state.error = true;
      }
    },
    [postNewWorkspaceV2.rejected]: (state) => {
      state.loaing = false;
    },

    // delete workspace V2
    [deleteWorkspaceV2.pending]: (state) => {
      state.loading = true;
    },
    [deleteWorkspaceV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.isDelete = true;
      } else {
        state.error = false;
      }
    },
    [deleteWorkspaceV2.rejected]: (state) => {
      state.loading = false;
    },

    [getWorkspaceUserListV2.pending]: (state) => {
      state.tableLoading = true;
    },
    [getWorkspaceUserListV2.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.tableLoading = false;
        state.getWorkspaceUserListData = payload.data;
      }
    },
    [getWorkspaceUserListV2.rejected]: (state) => {
      state.tableLoading = false;
    },

    // post new User V2
    [postNewWorkspaceUserV2.pending]: (state) => {
      state.loading = true;
    },
    [postNewWorkspaceUserV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.success = true;
      } else {
        state.error = true;
      }
    },
    [postNewWorkspaceUserV2.rejected]: (state) => {
      state.postNewWorkspaceUserLoading = 'notLoading';
    },

    // delete workspace user V2
    [deleteWorkspaceUserV2.pending]: (state) => {
      state.loading = true;
    },
    [deleteWorkspaceUserV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.success = true;
      }
    },
    [deleteWorkspaceUserV2.rejected]: (state) => {
      state.loading = false;
    },

    [postEmailToken.pending]: (state) => {
      state.postEmailTokenLoading = 'loading';
    },
    [postEmailToken.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.postEmailTokenLoading = 'doneLoading';
        state.postEmailTokenData = payload;
      } else {
        state.postEmailTokenLoading = 'loadingfailed';
        state.postEmailTokenData = payload;
      }
    },
    [postEmailToken.rejected]: (state) => {
      state.postEmailTokenLoading = 'notLoading';
    },
    [postEmailPwd.pending]: (state) => {
      state.postEmailPwdLoading = 'loading';
    },
    [postEmailPwd.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.postEmailPwdLoading = 'doneLoading';
      } else {
        state.postEmailPwdLoading = 'loadingfailed';
      }
    },
    [postEmailPwd.rejected]: (state) => {
      state.postEmailPwdLoading = 'notLoading';
    },
    [updateWorkspaceName.pending]: (state) => {
      state.workspaceNameLoading = 'loading';
    },
    [updateWorkspaceName.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.workspaceNameLoading = 'doneLoading';
      } else {
        state.workspaceNameLoading = 'loadingfailed';
      }
    },
    [updateWorkspaceName.rejected]: (state) => {
      state.workspaceNameLoading = 'notLoading';
    },

    // permission setting V2
    [getWorkspaceSettingsV2.pending]: (state) => {
      state.loading = true;
    },
    [getWorkspaceSettingsV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.permissionDataV2 = payload.data;
      }
    },
    [getWorkspaceSettingsV2.rejected]: (state) => {
      state.loading = false;
    },

    // permission user setting V2
    [getWorkspaceUserSettingsV2.pending]: (state) => {
      state.loading = true;
    },
    [getWorkspaceUserSettingsV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.permissionDataV2 = payload.data;
      }
    },
    [getWorkspaceUserSettingsV2.rejected]: (state) => {
      state.loading = false;
    },

    // post workspace permssion settings V2 (最高服務管理權限)
    [postWorkspaceSettingV2.pending]: (state) => {
      state.loading = true;
    },
    [postWorkspaceSettingV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.isPermissionSuccess = true;
      } else {
        state.error = true;
      }
    },
    [postWorkspaceSettingV2.rejected]: (state) => {
      state.loading = false;
    },

    [getWorksapceAssignedListAll.pending]: (state) => {
      state.assignedListLoad = 'loading';
    },
    [getWorksapceAssignedListAll.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.assignedListLoad = 'doneLoading';
        state.assignedListData = payload.data;
      } else {
        state.assignedListLoad = 'loadingfailed';
      }
    },
    [getWorksapceAssignedListAll.rejected]: (state) => {
      state.assignedListLoad = 'notLoading';
    },

    [putEnterpriseAssignedWorkspace.pending]: (state) => {
      state.enterpriseAssignedLoad = 'loading';
    },
    [putEnterpriseAssignedWorkspace.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.enterpriseAssignedLoad = 'doneLoading';
      } else {
        state.enterpriseAssignedLoad = 'loadingfailed';
      }
    },
    [putEnterpriseAssignedWorkspace.rejected]: (state) => {
      state.enterpriseAssignedLoad = 'notLoading';
    },

    [getUserassignedList.pending]: (state) => {
      state.userAssignedListDataLoad = 'loading';
    },
    [getUserassignedList.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.userAssignedListDataLoad = 'doneLoading';
        state.assignedListData = payload.data;
      } else {
        state.userAssignedListDataLoad = 'loadingfailed';
      }
    },
    [getUserassignedList.rejected]: (state) => {
      state.userAssignedListDataLoad = 'notLoading';
    },

    [putAssignedData.pending]: (state) => {
      state.putAssignedDataLoad = 'loading';
    },
    [putAssignedData.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.putAssignedDataLoad = 'doneLoading';
      } else {
        state.putAssignedDataLoad = 'loadingfailed';
      }
    },
    [putAssignedData.rejected]: (state) => {
      state.putAssignedDataLoad = 'notLoading';
    },

    // post workspace user permssion settings V2 (user服務管理權限)
    [postWorkspaceUserSettingV2.pending]: (state) => {
      state.loading = true;
    },
    [postWorkspaceUserSettingV2.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.isPermissionSuccess = true;
      } else {
        state.error = true;
      }
    },
    [postWorkspaceUserSettingV2.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export default workspaceListSlice.reducer;
export const selectWorkspaceListsSlice = (state) => state.workspaceList;
export const { removeSuccess } = workspaceListSlice.actions;
