import { createSlice } from "@reduxjs/toolkit";

const workspace = createSlice({
  name: "workspaceList",
  initialState: [
    {
      id: 1,
      name: "廣告工具行銷組",
      createdTime: "2022/04/01",
      usersNum: 0,
      serviceProject: "-",
      isOpen: false,
      permissionManagement: {},
      userList: [
        {
          name: "Adbert",
          email: "adbert@gmail.com.tw",
          joinDate: "2022-05-05",
          management: "",
          permission: "未認證",
          userCompany: "Adbert",
        },
      ],
      assignedList: [],
    },
    {
      id: 2,
      name: "廣告工具行銷組",
      createdTime: "2022/04/01",
      usersNum: 0,
      serviceProject: "-",
      permissionManagement: {},
      isOpen: false,
      userList: [],
      assignedList: [],
    },
  ],
  reducers: {
    toggleOpen: (state, action) => {
      state[action.payload].isOpen = true;
    },
    toggleClose: (state, action) => {
      state[action.payload].isOpen = false;
    },
    addWorksapceList: (state, action) => {
      state.push(action.payload);
    },
    deleteWorkspaceList: (state, action) => {
      state.splice(action.payload, 1);
    },
    editWorksapceName: (state, action) => {
      const { fowardIndex, workspaceName } = action.payload;
      state[fowardIndex].name = workspaceName;
    },
    addAssignedList: (state, action) => {
      const { fowardIndex, AssignedData } = action.payload;
      state[fowardIndex].assignedList.push(AssignedData);
    },
    removeAssignedList: (state, action) => {
      const { fowardIndex, AssignedData } = action.payload;
      state[fowardIndex].assignedList.splice(
        state[fowardIndex].assignedList
          .map((v) => v.id)
          .indexOf(AssignedData.id),
        1
      );
    },
    addUserList: (state, action) => {
      const { fowardIndex, userName, userEmail, userCompany } = action.payload;
      state[fowardIndex].userList.push({
        name: userName,
        email: userEmail,
        userCompany,
        joinDate: new Date().toLocaleDateString(),
        management: "",
        permission: "未認證",
      });
    },
    removeUserList: (state, action) => {
      const { fowardIndex, removeIndex } = action.payload;
      state[fowardIndex].userList.splice(removeIndex, 1);
    },
    searchWorkspace: (state, action) => {
      state.filter((v) => v.name.includes(action.payload));
    },
  },
});

export default workspace.reducer;
export const selectWorkspaceList = (state) => state.workspace;
export const {
  toggleOpen,
  toggleClose,
  addWorksapceList,
  deleteWorkspaceList,
  editWorksapceName,
  addAssignedList,
  removeAssignedList,
  addUserList,
  removeUserList,
  searchWorkspace,
} = workspace.actions;
