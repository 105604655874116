import { createSlice } from '@reduxjs/toolkit';

const navigate = createSlice({
  name: 'navigate',
  initialState: {
    toggleOpen: true,
    navigateEditMode: false,
    navigateToggle: false,
  },
  reducers: {
    toggleOff: (state) => {
      state.toggleOpen = false;
    },
    toggleOn: (state) => {
      state.toggleOpen = true;
    },
    editModeHandler: (state, action) => {
      state.navigateEditMode = action.payload;
    },
    editModeToggleHandler: (state, action) => {
      state.navigateToggle = action.payload;
    },
  },
});

export default navigate.reducer;
export const selectLeftNavigate = (state) => state.navigate;
export const { toggleOn, toggleOff, editModeHandler, editModeToggleHandler } = navigate.actions;
