const cdnIconUrl = "https://cdn.adbert.com.tw/cgp/icons/";
const cdnIconMartechUrl = "https://cdn.adbert.com.tw/martech/hrc/";
const cdnIconMantechUrl = "https://cdn.adbert.com.tw/mantech/";
const cdnIconMartechUrls = "https://cdn.adbert.com.tw/martech/";

const ICON_IMG_LIST = {
  menuDashboard: `${cdnIconMartechUrl}icon-dashboard.png`,
  menuOrganization: `${cdnIconMartechUrl}icon-organization.png`,
  menuCycle: `${cdnIconMartechUrl}icon-fact-check.png`,
  menuPolicy: `${cdnIconMartechUrl}icon-navigation.png`,
  menuCourse: `${cdnIconMartechUrl}icon-school.png`,
  menuEvaluation: `${cdnIconMartechUrl}icon-cooperation-set.png`,
  menuBusiness: `${cdnIconMartechUrls}icon-business.png`,
  avatar: `${cdnIconMartechUrl}icon-avatar.png`,

  managerBusiness: `${cdnIconMantechUrl}icon-apartment.png`,
  managerDepartment: `${cdnIconMantechUrl}icon-supervised-user.png`,
  managerEmployee: `${cdnIconMantechUrl}icon-contact.png`,
  managerTarget: `${cdnIconMantechUrl}icon-supervised-set.png`,

  personalEnterprise: `${cdnIconMantechUrl}icon-trending.png`,
  personalAppraisal: `${cdnIconMantechUrl}icon-assessment.png`,
  personalPolicy: `${cdnIconMantechUrl}icon-trending.png`,
  personalCourse: `${cdnIconMantechUrl}icon-cooperation.png`,
  personalInfo: `${cdnIconMantechUrl}icon-personal-set.png`,

  add: `${cdnIconUrl}add.png`,
  visibility: `${cdnIconUrl}visibility.png`,
  visibilityOff: `${cdnIconUrl}visibility_off.png`,
  arrowDown: `${cdnIconUrl}keyboard_arrow_down.png`,
  arrowRight: `${cdnIconUrl}keyboard_arrow_right.png`,
  more: `${cdnIconUrl}more_horiz.png`,
  componentSet: `${cdnIconUrl}componentSet.png`,
  trashCan: `${cdnIconUrl}delete.png`,
  trash: `${cdnIconMartechUrl}icon-delete-outline.png`,
  edit: `${cdnIconMartechUrl}icon-edit.png`,
  clear: `${cdnIconMartechUrl}icon-clear.png`,
  listBullet: `${cdnIconMartechUrl}icon-list-bullet.png`,
  close: `${cdnIconMartechUrl}icon-close.png`,
  alignLeft: `${cdnIconUrl}format_align_left.png`,
  alignCenter: `${cdnIconUrl}format_align_center.png`,
  alignRight: `${cdnIconUrl}format_align_right.png`,
  insertLink: `${cdnIconUrl}insert_link.png`,
  radioUnchecked: `${cdnIconUrl}radio_unchecked.png`,
  radioChecked: `${cdnIconUrl}radio_checked.png`,
  deleteSet: `${cdnIconUrl}deleteSet.png`,
  serviceError: `${cdnIconUrl}service_error.png`,
  arrowDropdown: `${cdnIconUrl}arrow_drop_down.png`,
  done: `${cdnIconUrl}done.png`,
};

export default ICON_IMG_LIST;
