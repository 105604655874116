import styles from './style/layout.module.scss';
import React, { useEffect, useState } from 'react';
import Button from '../Utilities/Button';
import Drawer from '../Utilities/Drawer';
import Checkbox from '../Utilities/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import {
  getWorkspaceSettingsV2,
  getWorkspaceUserSettingsV2,
  selectWorkspaceListsSlice,
  postWorkspaceSettingV2,
  postWorkspaceUserSettingV2,
  removeSuccess,
} from '../../store/slice/workspaceList';

const WorkspacePermission = ({ permissionSettings, setPermissionSettings, workspaceId, settingIndex, setFoward }) => {
  const dispatch = useDispatch();
  const [managementCount, setManagementCount] = useState({
    enterpriseManagement: 0,
    packageManagement: 0,
  });
  const { permissionDataV2, isPermissionSuccess } = useSelector(selectWorkspaceListsSlice);

  useEffect(() => {
    // 取得服務最高管理權限
    if (permissionSettings.modalOpen && workspaceId && !permissionSettings.isUserPermission) {
      dispatch(
        getWorkspaceSettingsV2({
          workspace: workspaceId,
        })
      );
    }

    if (isPermissionSuccess) {
      dispatch(removeSuccess());
    }

    if (permissionSettings.modalOpen && workspaceId && permissionSettings.isUserPermission) {
      dispatch(
        getWorkspaceUserSettingsV2({
          workspace_user: permissionSettings?.user_id,
        })
      );
    }
  }, [dispatch, workspaceId, permissionSettings, isPermissionSuccess, settingIndex]);

  function handleSetPermission(item, group) {
    let arr = [
      {
        workspace: permissionSettings?.isUserPermission ? null : workspaceId,
        workspace_user: permissionSettings?.isUserPermission ? permissionSettings?.user_id : null,
        name: item.name,
        has_permission: !item.has_permission,
      },
    ];

    // 勾選'非檢視'的管理權限時，若檢視權限未啟用，會連動被啟動。
    if (
      !item.name.includes('view') &&
      !item.has_permission &&
      !permissionDataV2?.rows[0]?.permissions[group].find((item) => item.name.includes('view')).has_permission
    ) {
      arr.push({
        name: permissionDataV2?.rows[0]?.permissions[group].find((item) => item.name.includes('view')).name,
        has_permission: true,
      });
    }

    // post設定的api
    if (permissionSettings?.isUserPermission) {
      dispatch(postWorkspaceUserSettingV2(arr));
    } else {
      dispatch(postWorkspaceSettingV2(arr));
    }
  }

  useEffect(() => {
    if (permissionDataV2?.rows?.length > 0) {
      let enterpriseCount = 0;
      let packageCount = 0;
      permissionDataV2?.rows[0]?.permissions?.enterpriseManagement.forEach((item) => {
        if (!item.name.includes('view') && item.has_permission) {
          enterpriseCount++;
        }
      });
      permissionDataV2?.rows[0]?.permissions?.packageManagement.forEach((item) => {
        if (!item.name.includes('view') && item.has_permission) {
          packageCount++;
        }
      });
      setManagementCount({
        enterpriseManagement: enterpriseCount,
        packageManagement: packageCount,
      });
    }
  }, [permissionDataV2]);

  return (
    <div>
      <Drawer isOpen={permissionSettings?.modalOpen} size={'md'}>
        <div className={styles.headerContainer}>
          <div className={styles.permissionTitle}>{settingIndex ? '設定服務權限' : '設定服務最高管理權限'}</div>
          <div className={styles.headerRight}>{permissionSettings.workspace_name}</div>
        </div>
        {!permissionSettings?.isUserPermission ? (
          <div className={styles.headerTop}>目前設定為最高權限，該 Workspace 內的使用者權限無法高於此設定。</div>
        ) : (
          <div className={styles.headerTop}>使用者權限僅能勾選該 Workspace 擁有的權限。</div>
        )}
        <div className={styles.systemTitle}>{permissionSettings?.manage_service}</div>
        <div className={styles.listTopic}>企業帳號管理</div>
        <div className={styles.managementlist}>
          {permissionDataV2?.rows?.length > 0 &&
            permissionDataV2?.rows[0]?.permissions?.enterpriseManagement?.map((item, index) => (
              <>
                <div className={styles.checkboxOuter} key={`checklist-${index}`}>
                  <div className={styles.planTitle}>{item.name_local.split('.')[1]}</div>
                  <Checkbox
                    value={item?.has_permission}
                    isDisable={(item.name.includes('view') && item.has_permission && managementCount?.enterpriseManagement > 0) || !item.can_enable}
                    color={'#5856D6'}
                    onClick={() => handleSetPermission(item, 'enterpriseManagement')}
                  />
                </div>
              </>
            ))}
        </div>
        <div className={styles.listTopic}>方案管理</div>
        <div className={styles.managementlist}>
          {permissionDataV2?.rows?.length > 0 &&
            permissionDataV2?.rows[0]?.permissions?.packageManagement?.map((item, index) => (
              <>
                <div className={styles.checkboxOuter} key={`${index}`}>
                  <div className={styles.planTitle}>{item.name_local.split('.')[1]}</div>
                  <Checkbox
                    value={item?.has_permission}
                    isDisable={(item.name.includes('view') && item.has_permission && managementCount?.packageManagement > 0) || !item.can_enable}
                    color={'#5856D6'}
                    onClick={() => handleSetPermission(item, 'packageManagement')}
                  />
                </div>
              </>
            ))}
        </div>
        <div className={styles.drawerFooter}>
          <Button
            type={'primary'}
            title='完成'
            onClick={() => {
              setPermissionSettings({
                modalOpen: false,
                isUserPermission: false,
              });
            }}
          />
        </div>
      </Drawer>
    </div>
  );
};
export default WorkspacePermission;
