import styles from './style/layout.module.scss';
import InnerLayout from '../Layout/InnerLayout';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectEnterprise, getEnterpriseList, updateActiveService } from '../../store/slice/enterpriseLists';
import { selectMenu } from '../../store/slice/manager/menu';

import { IoChevronDownOutline } from 'react-icons/io5';

import Pagination from '../Utilities/PaginationNew';
import InputSearch from '../Utilities/InputSearch';
import Toast from '../Utilities/Toast';
import Button from '../Utilities/Button';
import ServiceListDetail from './ServiceListDetail';
import EmptyBox from '../Utilities/EmptyBox';
import LoadingTable from '../Utilities/LoadingTable';
import Dropdown from '../Utilities/DropdownDynamic';
import Tips from '../Utilities/Tips';
import { MdCheckCircle } from 'react-icons/md';
import moment from 'moment';

const CompanySystem = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchSearchValue, setFetchSearchValue] = useState('');
  const [success] = useState(false);
  const [index, setIndex] = useState();
  const [status] = useState('');
  const [openDetail, setOpenDetail] = useState(false);
  const [enterpriseData, setEnterpriseData] = useState(null);
  const [options, setOptions] = useState([]);

  // 分頁
  const [page, setPage] = useState(0);

  const limit = 10;

  const dispatch = useDispatch();
  const enterprise = useSelector(selectEnterprise);
  const { menu } = useSelector(selectMenu);

  useEffect(() => {
    let array = ['全部'];
    if (menu?.length > 0) {
      menu.forEach((data) => {
        array.push(data.alias);
      });
    }
    setOptions(array);
  }, [menu]);

  useEffect(() => {
    dispatch(
      getEnterpriseList({
        currentPage: page + 1,
        limit: limit,
        keyword: fetchSearchValue,
        service: menu?.find((item) => item.sys_alias === enterprise.active_service)
          ? menu?.find((item) => item.sys_alias === enterprise.active_service)?.id
          : null,
      })
    );
  }, [enterprise.active_service, dispatch, fetchSearchValue, menu, page]);

  const handleDropdown = (e) => {
    let service = e;
    if (service === 'Keystone Core') service = 'tdp';
    dispatch(updateActiveService(service));
    setPage(0);
  };

  const handleListDetail = (i) => {
    if (openDetail === true && index === i) {
      setOpenDetail(false);
    } else {
      setIndex(i);
      setOpenDetail(true);
    }
  };

  const restart = () => {
    setFetchSearchValue('');
    let parameters = {
      limit: limit,
      currentPage: page + 1,
      keyword: '',
      service: menu?.find((item) => item.sys_alias === enterprise.active_service)
        ? menu?.find((item) => item.sys_alias === enterprise.active_service)?.id
        : null,
    };
    dispatch(getEnterpriseList(parameters));
  };

  return (
    <>
      <InnerLayout>
        <ServiceListDetail enterprise={enterpriseData} setEnterprise={setEnterpriseData} />

        <div className={styles.dashboard}>
          <div className={styles.dashboardBusinessRight}>
            <div className={styles.dashboardTitle}>企業帳號系統管理</div>
            <div className={styles.dashboardListContainer}>
              <div className={styles.dashboardListTitle}>企業帳號列表</div>
              <div className={styles.searchBar}>
                <div className={styles.dropdownWrapper}>
                  <Dropdown
                    placeholder='全部'
                    value={enterprise.active_service === 'tdp' ? 'Keystone Core' : enterprise.active_service}
                    listItems={options}
                    onChange={(e) => handleDropdown(e)}
                    wide={true}
                  />
                </div>

                <InputSearch
                  placeholder='搜尋公司名稱或統一編號'
                  width={'200px'}
                  value={searchTerm}
                  onKeyPress={(e) =>
                    (e.code === 'Enter' || e.charCode === 13) && enterprise?.enterpriseListsLoading !== 'loading' && setFetchSearchValue(searchTerm)
                  }
                  onChange={(e) => setSearchTerm(e.target.value)}
                  clickSearchIcon={() => {
                    if (enterprise?.enterpriseListsLoading !== 'loading') {
                      setFetchSearchValue(searchTerm);
                      setPage(1);
                    }
                  }}
                  onClear={() => {
                    restart();
                    setSearchTerm('');
                  }}
                />
              </div>
            </div>

            <Toast
              show={success}
              title={status ? '已成功開通「Content Gallery Platform」' : '已成功停用「Content Gallery Platform」'}
              subtitle={'艾普特媒體'}
            />
            <div className={styles.tableContainer}>
              {enterprise?.enterpriseListsLoading === 'loading' ? (
                <table>
                  <thead>
                    <tr>
                      <th>公司名稱</th>
                      <th>統一編號</th>
                      <th>電子郵件</th>
                      <th>服務項目</th>
                      <th>開通設定</th>
                      <th></th>
                    </tr>
                  </thead>
                  <LoadingTable row={10} column={6} />
                </table>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>公司名稱</th>
                      <th>統一編號</th>
                      <th>電子郵件</th>
                      <th>服務項目</th>
                      <th style={{ minWidth: '55px' }}>開通設定</th>
                      <th></th>
                    </tr>
                  </thead>

                  {enterprise?.enterpriseListsData?.data?.length > 0 ? (
                    <tbody>
                      {enterprise?.enterpriseListsData?.data?.map((data) => (
                        <React.Fragment key={data.enterprise_id}>
                          <tr>
                            <td className={styles.tableTd}>
                              <div className={styles.flex}>
                                {data.email_verified_at && (
                                  <Tips detail={`${moment(data.email_verified_at).format('YYYY/MM/DD')} Email已驗證`}>
                                    <MdCheckCircle className={styles.emailVerified} />
                                  </Tips>
                                )}
                                {data.enterprise_name}
                              </div>
                            </td>
                            <td className={styles.tableTd}>
                              <span className={styles.monsterat}>{data.tax_id}</span>
                            </td>
                            <td className={styles.tableTd}>{data.email}</td>
                            <td className={styles.tableTd}>
                              {data.subscribed_services?.map((item) => (
                                <span className={styles.tagGroup} key={item.service_alias}>
                                  {item.service_alias}
                                </span>
                              ))}
                            </td>
                            <td className={styles.tableTd}>
                              <Button type='tertiary' title={'前往'} onClick={() => setEnterpriseData(data)} />
                            </td>
                            <td className={`${styles.dropLists} ${styles.tableTd}`}>
                              <Button
                                type='pure'
                                icon={<IoChevronDownOutline />}
                                open={data.enterprise_id === index && openDetail === true}
                                onClick={() => handleListDetail(data.enterprise_id)}
                              />
                            </td>
                          </tr>

                          {data.enterprise_id === index && openDetail === true && (
                            <tr>
                              <td colSpan='7'>
                                <div className={styles.details}>
                                  <div>
                                    <div className={styles.wrapContainer}>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>產業</div>
                                        <div className={styles.detailsList}>{data.industry}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>員工人數</div>
                                        <div className={styles.detailsList}>{data.headcount}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>公司電話</div>
                                        <div className={styles.detailsList}>
                                          {data.phone_country} {data.phone}
                                        </div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人姓名</div>
                                        <div className={styles.detailsList}>{data.contact_name ? data.contact_name : '-'}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人職稱</div>
                                        <div className={styles.detailsList}>{data.job}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人性別</div>
                                        <div className={styles.detailsList}>
                                          {!data.contact_gender && '-'}
                                          {data.contact_gender === 'male' && '男性'}
                                          {data.contact_gender === 'female' && '女性'}
                                          {data.contact_gender === 'other' && '其他'}
                                        </div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人電話</div>
                                        <div className={styles.detailsList}>
                                          {data.cellphone_country} {data.cell_phone}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan='7'>
                          <EmptyBox caption={'目前沒有任何企業帳號'} />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              )}
              {enterprise?.enterpriseListsLoading === 'loading' ? (
                <div></div>
              ) : (
                <Pagination
                  pageCount={page + 1}
                  onPageChange={(e) => {
                    setPage(e.selected);
                  }}
                  totalData={enterprise.enterpriseListsData.recordsTotal}
                  limit={limit}
                  totalPages={Math.ceil(enterprise.enterpriseListsData.recordsTotal / limit)}
                />
              )}
            </div>
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default CompanySystem;
