import styles from "./style/MenuTabs.module.scss";
import { Link } from "react-router-dom";
import React from "react";

const Tabs = ({ items, type, active, onClick, disabled, style }) => {
  return (
    <div className={styles.tabContainer}>
      {items.map((data, index) =>
        disabled ? (
          <div
            key={index}
            className={`${styles.tab} ${active === index && styles.active} ${styles.disabled
              }`}>
            {data.icon ? data.icon : ""}
            {data.title}
          </div>
        ) : (
          <React.Fragment key={index}>
            {type === "link" ? (
              <Link to={data.value}>
                <div
                  className={`${styles.tab} ${active === index && styles.active
                    }`}
                  style={style ? style : {}}>
                  {data.icon ? data.icon : ""}
                  {data.title}
                </div>
              </Link>
            ) : (
              <div
                className={`${styles.tab} ${active === index && styles.active}`}
                onClick={() => active !== data.value && onClick(data.value)}>
                {data.icon ? data.icon : ""}
                {data.title}
              </div>
            )}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default Tabs;
