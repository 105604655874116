import styles from './style/layout.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { selectLeftNavigate } from '../../store/slice/navigate';
import MediaQueryComponent from '../Utilities/MediaQueryComponent';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissions, selectPermissionSettings } from '../../store/slice/permissionSettings';
import { useCookies } from 'react-cookie';
import { logoutAndClearUser } from '../../store/slice/user';
import { logOut } from '../../utils';

const InnerLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leftNavigate = useSelector(selectLeftNavigate);
  const showLeftNavigate = leftNavigate.toggleOpen;
  const serviceAreaElm = document.getElementById('service-area');
  serviceAreaElm.style['display'] = 'none';

  const [cookies] = useCookies(['token']);
  const { auth } = useSelector(selectPermissionSettings);

  const [token, setToken] = useState('');
  const [timerID, setTimerID] = useState('');

  useEffect(() => {
    if (cookies.token) {
      setToken(cookies.token);
    } else {
      navigate('/');
    }
  }, [cookies, dispatch, navigate]);

  useEffect(() => {
    if (auth?.roles.filter((item) => item.includes('workspace'))?.length <= 0) {
      navigate('/');
    }

    let array = [];
    if (auth) {
      auth?.workspace_permissions?.forEach((data) => {
        data.permissions?.forEach((item) => {
          array.push(item);
        });
      });
      dispatch(getPermissions(array));
    }
  }, [auth, navigate, dispatch]);

  useEffect(() => {
    const handleTimeoutAndLogout = () => {
      dispatch(logoutAndClearUser());
      clearTimeout(timerID);
      setTimerID('');
      setToken('');
      logOut();
    };
    if (!!cookies && timerID === '') {
      setTimerID(setTimeout(handleTimeoutAndLogout, 10000 * 1000));
    }
  }, [cookies, dispatch, timerID, token]);

  return (
    <>
      <div>
        <MediaQueryComponent />
      </div>

      <div className={styles[showLeftNavigate ? 'inner-layout' : 'inner-layout-show']}>{children}</div>
    </>
  );
};

export default InnerLayout;
