import { sendApiRequest, sendApiClientRequest } from "./api";

export async function sendPostLogin(url, payload, isImage) {
  try {
    const response = await sendApiRequest("post", url, payload, isImage);
    return response;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendGetRequest(url) {
  try {
    const response = await sendApiRequest("get", url);
    return response.data;
  } catch (err) {
    console.error("error when getting data:", err);
    throw err;
  }
}

export async function sendPostRequest(url, payload, isImage) {
  try {
    const response = await sendApiRequest("post", url, payload, isImage);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendPutRequest(url, payload, isImage) {
  try {
    const response = await sendApiRequest("put", url, payload, isImage);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendPatchRequest(url, payload, isImage) {
  try {
    const response = await sendApiRequest("patch", url, payload, isImage);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendDeleteRequest(url) {
  try {
    const response = await sendApiRequest("delete", url);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

// CLIENT API
export async function sendGetClientRequest(url) {
  try {
    const response = await sendApiClientRequest("get", url);
    return response.data;
  } catch (err) {
    console.error("error when getting data:", err);
    throw err;
  }
}

export async function sendPostClientRequest(url, payload, isImage) {
  try {
    const response = await sendApiClientRequest("post", url, payload, isImage);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendDeleteClientRequest(url) {
  try {
    const response = await sendApiClientRequest("delete", url);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendPutClientRequest(url, payload, isImage) {
  try {
    const response = await sendApiClientRequest("put", url, payload, isImage);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}

export async function sendPatchClientRequest(url, payload, isImage) {
  try {
    const response = await sendApiClientRequest("patch", url, payload, isImage);
    return response.data;
  } catch (err) {
    console.error("error when posting data:", err);
    throw err;
  }
}
