import styles from './style/ModalASK.module.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectASK, updateASK } from '../../../store/slice/tdp/ask';
import { deepClone } from '../../../utils';

import { MdEdit, MdEditNote } from 'react-icons/md';

import Modal from '../../Utilities/Modal';
import TextField from '../../Utilities/TextField';
import Button from '../../Utilities/Button';
import TextArea from '../../Utilities/TextArea';
import Table from '../../Utilities/Table';
import Toast from '../../Utilities/Toast';

const ModalASK = ({ status, setStatus, setIsOpen, isOpen }) => {
  const dispatch = useDispatch();
  const { ask_detail, updated } = useSelector(selectASK);

  const [item, setItem] = useState('');
  const [inertia, setInertia] = useState([]);
  const [content, setContent] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setItem(status.item);
    setInertia(status.ability_ask_item);
  }, [dispatch, status]);

  useEffect(() => {
    setInertia(ask_detail);
  }, [ask_detail]);

  const handleUpdate = () => {
    if (!handleValidate()) {
      const postObj = {
        askID: status.id,
        category: status.category,
        item: item,
        ability_ask_item: inertia,
      };
      dispatch(updateASK(postObj));

      setIsOpen(false);
      setInertia([]);
      setError(false);
      setTimeout(() => {
        setItem('');
      }, 1500);
    }
  };

  const handleCancel = () => {
    setStatus('');
    setIsOpen(false);
    setItem('');
    setInertia([]);
    setError(false);
  };

  const handlePopup = (data, index) => {
    let text = '';

    data.content.forEach((item) => {
      text = text + item;
    });

    setContent({
      id: index,
      content: text,
    });
  };

  const handleSave = (index) => {
    const array = deepClone(inertia);
    array.forEach((data, idx) => {
      if (index === idx) {
        let text = [`${content.content}`];
        data.content = text;
      }
    });
    setInertia(array);
    setContent('');
  };

  const handleValidate = () => {
    let error = false;
    let count = 0;

    if (!item || inertia?.length <= 0) {
      count++;
    }

    if (inertia?.length > 0) {
      inertia.forEach((data) => {
        if (!data.content || data.content.length <= 0) count++;
      });
    }

    if (count) {
      error = true;
      setError(true);
    } else {
      error = false;
      setError(false);
    }

    return error;
  };

  const headerData = [
    {
      title: '慣性行為層次',
      width: 30,
    },
    {
      title: '慣性行為描述',
      width: 70,
    },
  ];

  return (
    <>
      <Modal size='xl' isOpen={isOpen}>
        <div className={styles.modalHeader}>
          <div className={styles.modalSubHeader}>
            <div className={styles.modalTitle}>
              <MdEditNote className={styles.modalIcon} />
              查看職能慣性項目
            </div>
          </div>
        </div>

        <div className={styles.modalBody}>
          <div className={styles.colHalf}>
            <div className={styles.leftWrapper}>
              <label className={styles.labelTitle}>ASK 職能慣性類別</label>
              <span>{status.category?.substring(0, 1)}</span>
            </div>
            <div className={styles.rightWrapper}>
              <label className={styles.labelTitle}>職能項目</label>
              <TextField value={item} onChange={(e) => setItem(e)} error={error && !item} fullWidth />
              {error && !item && <span className={styles.messageError}>未輸入職能項目</span>}
            </div>
          </div>
          <div className={styles.title}>慣性行為列表</div>
          <Table header={headerData} type={'condensed'}>
            {inertia?.length > 0 && (
              <tbody>
                {inertia?.map((data, index) => (
                  <tr key={index}>
                    <td width={`${headerData[0].width}%`}>
                      <strong>L{data.level}</strong>
                    </td>
                    <td width={`${headerData[1].width}%`}>
                      <div className={styles.editWrapper}>
                        {data?.content?.length > 0 &&
                          data?.content?.map((item, idx) => (
                            <React.Fragment key={idx}>
                              <div className={styles.desc}>{item}</div>
                            </React.Fragment>
                          ))}

                        <span className={styles.btnEdit}>
                          <Button type='pure' icon={<MdEdit className={styles.icon} />} onClick={() => handlePopup(data, index)} />
                        </span>

                        {content.id === index && (
                          <div className={styles.modalEdit}>
                            <div className={styles.modalTitle}>慣性行為描述</div>
                            <TextArea
                              value={content?.content}
                              onChange={(e) =>
                                setContent({
                                  ...content,
                                  content: e.target.value,
                                })
                              }
                            />
                            <div className={styles.modalButton}>
                              <Button type='quaternary' title='取消' onClick={() => setContent('')} />
                              <div className={styles.modalBtn}>
                                <Button type='primary' title='儲存' onClick={() => handleSave(index)} disabled={!content?.content} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </div>

        <div className={`${styles.modalFooter} ${styles.right}`}>
          <span style={{ marginRight: '8px' }}>
            <Button type='quaternary' title='取消' onClick={handleCancel} style={{ marginRight: '8px' }} disabled={content} />
          </span>
          <Button type='primary' title='儲存' onClick={handleUpdate} disabled={content} />
        </div>
      </Modal>

      <Toast show={updated} title={'職能項目已更改'} subtitle={`${status.category} - ${item}`} />
    </>
  );
};

export default ModalASK;
