import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import InnerLayout from "../Layout/InnerLayout";
import styles from "./style/lifeCycle.module.scss";
import ScoreSettingModal from "./ScoreSettingModal";
import Button from "../Utilities/Button";
import Dropdown from "../Utilities/DropdownDynamic";
import Toast from "../Utilities/Toast";
import MenuTabs from "../Utilities/MenuTabs";

import QuestionPreviewTable from "./QuestionPreviewTable";

import { AiFillCheckCircle, AiOutlineFileSearch } from "react-icons/ai";

import { MdOutlineListAlt, MdErrorOutline } from "react-icons/md";

import {
  getStrategyScore,
  putStrategyScore,
  removeLoading,
  selectLifeCycle,
  getQuestions,
} from "../../store/slice/lifeCycle";

const QuestionPreview = () => {
  const dispatch = useDispatch();

  const { loading, scoreData, scoreLoading, testData } =
    useSelector(selectLifeCycle);

  const [scoreVal, setScoreVal] = useState({});
  const [warnings, setWarnings] = useState(false);
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  const [isToastShow, setIsToastShow] = useState({
    scoreToast: false,
  });
  const scoreArr = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

  const [testList, setTestList] = useState([]);
  const [listValue, setListValue] = useState("預覽全部");

  const [scoreBtnDisabled, setScoreBtnDisabled] = useState(true);

  const tabItems = [
    {
      title: "總覽",
      value: "/dashboard/tdp/life-cycle",
      icon: <MdOutlineListAlt />,
    },
    {
      title: "預覽試題",
      value: "/dashboard/tdp/life-cycle/question-preview",
      icon: <AiOutlineFileSearch />,
    },
  ];

  // Get分數設定的API
  useEffect(() => {
    dispatch(getStrategyScore());
  }, [dispatch]);

  useEffect(() => {
    if (
      scoreVal.very_not === scoreVal.meets_the ||
      scoreVal.very_not === scoreVal.very_much ||
      scoreVal.meets_the === scoreVal.very_much
    ) {
      setScoreBtnDisabled(true);
    } else if (
      scoreVal.very_much < scoreVal.meets_the ||
      scoreVal.very_much < scoreVal.very_not ||
      scoreVal.meets_the < scoreVal.very_not
    ) {
      setScoreBtnDisabled(true);
    } else {
      setScoreBtnDisabled(false);
    }
  }, [scoreVal, setScoreBtnDisabled]);

  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (testData.length > 0) {
      let arr = ["預覽全部"];
      let newArr = [...testData];
      newArr.forEach((item) => {
        arr.push(item.title);
      });
      setTestList(arr);
    }
  }, [testData, setTestList]);

  useEffect(() => {
    if (
      (scoreData?.meets_the !== 0 && !scoreData?.meets_the) ||
      (scoreData?.very_much !== 0 && !scoreData?.very_much) ||
      (scoreData?.very_not !== 0 && !scoreData?.very_not)
    ) {
      setWarnings(true);
    } else {
      setWarnings(false);
    }
  }, [scoreData, warnings]);

  // 分數設定 in newObj
  useEffect(() => {
    if (scoreModalOpen && !loading) {
      setScoreVal(scoreData);
    }
  }, [scoreModalOpen, loading, scoreData]);

  useEffect(() => {
    if (scoreModalOpen && scoreLoading === "done") {
      setScoreModalOpen(false);
      setIsToastShow({
        scoreToast: true,
        literatureToast: false,
        testToast: false,
      });
      dispatch(removeLoading());
      dispatch(getStrategyScore());
    }
    setTimeout(() => {
      setIsToastShow({
        scoreToast: false,
        literatureToast: false,
        testToast: false,
      });
    }, 2000);
  }, [scoreModalOpen, scoreLoading, dispatch]);

  return (
    <InnerLayout>
      <div className={styles.lifeCycleOuter}>
        <div className={styles.header}>
          <div className={styles.title}>企業生命週期</div>
          <div className={styles.scoreSetting}>
            <div className={styles.tick}>
              {warnings && (
                <>
                  <MdErrorOutline />
                  <span>編輯評測試題前請先設定全試題通用分數</span>
                </>
              )}
              {!warnings && (
                <>
                  <AiFillCheckCircle className={styles.checkCircle} />
                </>
              )}
            </div>
            <div className={styles.scoreSettingBtn}>
              <Button
                title='分數設定'
                type='tertiary'
                onClick={() => setScoreModalOpen(true)}
              />
              {warnings && <div className={styles.redDot}></div>}
            </div>
          </div>
        </div>

        <div className={styles.tabContainer}>
          <MenuTabs items={tabItems} type='link' active={1} />
          <div className={styles.tabLine}></div>
          <div className={styles.dropDowntab}>
            <Dropdown
              listItems={testList}
              onChange={(e) => setListValue(e)}
              value={listValue}
              placeholder={"請選擇"}
              noBg={true}
            />
          </div>
        </div>

        <QuestionPreviewTable listValue={listValue} />
      </div>
      {/* Score Modal */}
      <ScoreSettingModal isOpen={scoreModalOpen}>
        <div className={styles.scoreModal}>
          <div className={styles.header}>
            <div className={styles.title}>分數設定</div>
            <div className={styles.info}>
              <MdErrorOutline className={styles.icon} />
              分數請從低至高且不可重複
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.tableHead}>
              <div className={styles.title}>指標</div>
              <div className={styles.title}>分數 (0~10分)</div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.index}>非常符合</div>
              <div className={styles.dropdown}>
                <Dropdown
                  listItems={scoreArr}
                  placeholder='請選擇'
                  value={
                    scoreVal?.very_much === 0
                      ? "0"
                      : !scoreVal?.very_much && scoreVal?.very_much !== 0
                      ? null
                      : scoreVal?.very_much
                  }
                  onChange={(e) => {
                    let newObj = { ...scoreVal };
                    newObj.very_much = e;
                    setScoreVal(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.index}>部分符合</div>
              <div className={styles.dropdown}>
                <Dropdown
                  listItems={scoreArr}
                  placeholder='請選擇'
                  value={
                    scoreVal?.meets_the === 0
                      ? "0"
                      : !scoreVal?.meets_the && scoreVal?.meets_the !== 0
                      ? null
                      : scoreVal?.meets_the
                  }
                  onChange={(e) => {
                    let newObj = { ...scoreVal };
                    newObj.meets_the = e;
                    setScoreVal(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.tableBody}>
              <div className={styles.index}>非常不符合</div>
              <div className={styles.dropdown}>
                <Dropdown
                  listItems={scoreArr}
                  placeholder='請選擇'
                  value={
                    scoreVal?.very_not === 0
                      ? "0"
                      : !scoreVal?.very_not && scoreVal?.very_not !== 0
                      ? null
                      : scoreVal?.very_not
                  }
                  onChange={(e) => {
                    let newObj = { ...scoreVal };
                    newObj.very_not = e;
                    setScoreVal(newObj);
                  }}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                title={"取消"}
                type='quaternary'
                onClick={() => {
                  setScoreModalOpen(false);
                  // setIsAlertShow(true);
                }}
              />
              <Button
                title={"儲存"}
                type='primary'
                disabled={scoreBtnDisabled}
                onClick={() => {
                  dispatch(
                    putStrategyScore({
                      very_not: scoreVal.very_not,
                      meets_the: scoreVal.meets_the,
                      very_much: scoreVal.very_much,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
      </ScoreSettingModal>

      <Toast
        title={isToastShow.scoreToast && "分數設定完成"}
        show={isToastShow.scoreToast}
      />
    </InnerLayout>
  );
};

export default QuestionPreview;
